import { RootState } from "../../app/state";
import { createSelector } from "@reduxjs/toolkit";

export const selectDrawingSlice = (state: RootState) => state.drawingSlice;

export const selectDrawingStatus = createSelector(
  selectDrawingSlice,
  (state) => state.isDrawing
);

export const selectDrawingPoints = createSelector(
  selectDrawingSlice,
  (state) => state.points
);
