import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { pagePathGenerator } from "../../routes/pagePathGenerator";
import { selectCurrentFloor, selectCurrentFloorId } from "../../store/floorStore/floorSelectors";
import { useSnackbar } from "../../app/shared/snackbar-context/snackbar.context";
import { selectCurrentLocation, selectCurrentLocationId } from "../../store/locationStore/locationSelectors";
import { selectCurrentMapId } from "../../store/mapStore/mapSelectors";

const useDevicesPage = () => {
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const currentFloorId = useSelector(selectCurrentFloorId);
  const currentFloor = useSelector(selectCurrentFloor);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentLocation = useSelector(selectCurrentLocation);
  const currentMapId = useSelector(selectCurrentMapId);
  const currentModel = currentFloor || currentLocation;
  const mapId = currentModel?.mapId;
  const currentMap = currentModel?.map;
  const isLocationDevice = !currentFloor;

  const onDeviceNavigate = (deviceId: number | null, shouldRefresh = false) => {
    const url = pagePathGenerator.SINGLE_DEVICE_PAGE({
      mapId,
      deviceId
    });

    shouldRefresh ? history.replace(url) : history.push(url);
  };

  const onDeviceGoBack = (shouldReplace?: boolean) => {
    const url = isLocationDevice ?
      pagePathGenerator.LOCATION_PAGE({
        locationId: currentLocationId,
      }) :
      pagePathGenerator.BUILDING_PAGE({
        locationId: currentLocationId,
        buildingId: currentFloor?.buildingId,
        floorId: currentFloorId,
      });

    shouldReplace ? history.replace(url) : history.push(url);
  }

  const handleWorkflowErrors = useCallback((action: unknown, title: string) => {
    const { payload } = action as { payload: unknown };
    const { errorCode } = payload as { errorCode: number };
    const messages = [
      '',
      'No wireless interface is added to the device',
      'Channels are not set for all wireless interfaces',
      'There is no workflow for the current location. Please contact your administrator',
      'Device is not attached to the map',
      'You are not assigned to this device'
    ];

    errorCode && messages[errorCode] && openSnackbar({
      title: title,
      message: messages[errorCode],
      severity: 'error',
    });
  }, [])

  return {
    actions: {
      onDeviceNavigate,
      handleWorkflowErrors,
      onDeviceGoBack,
    },
    state: {
      currentLocationId,
      currentFloorId,
      currentFloor,
      currentMapId: mapId || currentMapId,
      currentMap,
      isLocationDevice,
    }
  }
}

export default useDevicesPage;