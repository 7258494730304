export enum DeviceTypeEnum {
  STATIONARY = '0',
  PORTABLE = '1',
  GROUP = '2',
}

export enum DeviceTypeToString {
  'stationary',
  'portable',
  'group'
}