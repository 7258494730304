import { useSelector } from "react-redux";
import { selectAllDevices } from "../../../store/deviceStore/deviceSelectors";
import { filterDeviceIdList } from "../../../helpers/urlChecker";
import HorizontalPanel from "../../../components/HorizontalPanel";
import classes from "../BulkSelectDevicesList/index.module.scss";
import { Toolbar } from "../../../app/components";
import { Grid } from "@material-ui/core";
import cn from "classnames";
import DeviceTable from "../../../components/DeviceTable";

const BulkConfirmation = ({ deviceIdList, actionName }: { deviceIdList: number[], actionName: string }) => {
  const deviceAllList = useSelector(selectAllDevices);
  const deviceList = filterDeviceIdList(deviceAllList, deviceIdList);

  // should not happen
  if (!deviceList) {
    return null;
  }

  const title = `Confirmation: ${deviceList.length} devices will be ${actionName}`;


  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} className={classes.container}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={title} />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.container}>
        <Grid container className={cn(classes.tableContainer, {
          [classes.width4K]: !!deviceList,
        })}>
          <DeviceTable
            showDeviceLink={false}
            deviceList={deviceList || []}
          />
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BulkConfirmation;