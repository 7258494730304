import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import React from "react";
import { TextField } from "@material-ui/core";

import classes from './index.module.scss';

const BulkOperationDetails = ({ onUpdate, operationText }: { onUpdate: (event) => void, operationText: string }) => {

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Operation details" />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.textContainer}>
        <TextField
          label="Message"
          fullWidth
          multiline
          maxRows={3}
          inputProps={{ maxLength: 500 }}
          variant='outlined'
          name='message'
          value={operationText}
          onChange={onUpdate}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BulkOperationDetails;