import { RootState } from "../../app/state";
import { deviceAdapter } from "./deviceState";
import { createSelector } from "@reduxjs/toolkit";
import { uniq } from "lodash/fp";
import { selectCurrentConfiguration } from "../deviceConfigurationStore/deviceConfigurationSelectors";
import DeviceModel from "models/DeviceModel";
import { DeviceStatus } from "../../models/enums/DeviceStatus.enum";

const filterStatus = (device:DeviceModel) => device?.configuration?.status && ![ DeviceStatus.REJECTED, DeviceStatus.RETIRED ].includes(device?.configuration?.status);

const selectRoot = (state: RootState) => state;
export const selectDeviceSlice = (state: RootState) => state.deviceList;

const deviceAdapterSelectors = deviceAdapter.getSelectors(selectDeviceSlice);

export const selectAllDevices = deviceAdapterSelectors.selectAll;
export const selectDeviceById = (deviceId: number | null) => createSelector(
  selectRoot,
  (root) => (deviceId && deviceAdapterSelectors.selectById(root, deviceId)) || null
)

export const selectDevicesByMapId = (mapId?: number | null) => createSelector(
  selectAllDevices,
  (devicesList) => devicesList
    .filter((device) => device.mapId === mapId)
    .filter(filterStatus)
    || ([] as DeviceModel[])
);

export const selectWirelessSystemListByMapId = (mapId?: number | null) => createSelector(
  selectDevicesByMapId(mapId),
  (deviceList) => {
    const wirelessSystemIdList: number[] = [];
    deviceList.forEach((device) => {
      if (device?.configuration?.wirelessSystemId && !(wirelessSystemIdList.includes(device?.configuration?.wirelessSystemId))) {
        wirelessSystemIdList.push(device?.configuration?.wirelessSystemId);
      }
    });

    return wirelessSystemIdList;
  }
);

export const selectTechnologyConfigurationListByMapId = (mapId?: number | null) => createSelector(
  selectDevicesByMapId(mapId),
  (deviceList) => {
    const technologyConfigurationList: number[] = [];
    deviceList.forEach((device) => {
      (device?.configuration?.wirelessInterfaces || []).forEach((wirelessInterface) => {
        if (wirelessInterface.technologyConfigurationId && !technologyConfigurationList.includes(wirelessInterface.technologyConfigurationId)) {
          technologyConfigurationList.push(wirelessInterface.technologyConfigurationId);
        }
      })
    });

    return technologyConfigurationList;
  }
)

export const selectWirelessSystemsByMapId = (mapId?: number | null) => createSelector(
  selectDevicesByMapId(mapId),
  (deviceList) => uniq(deviceList.map((device) => device?.configuration?.wirelessSystemId).filter(Boolean)) || ([] as number[])
);

export const selectCurrentDeviceId = createSelector(
  selectDeviceSlice,
  (state) => state.selectedDeviceId
);

export const selectCurrentDevice = createSelector(
  selectRoot,
  selectCurrentDeviceId,
  (root, deviceId) => (deviceId && deviceAdapterSelectors.selectById(root, deviceId)) || null
);

export const selectCurrentWirelessInterfaceId = createSelector(
  selectDeviceSlice,
  (state) => state.selectedWirelessInterfaceId
)

export const selectCurrentWirelessInterface = createSelector(
  selectCurrentDevice,
  selectCurrentConfiguration,
  selectCurrentWirelessInterfaceId,
  (selectCurrentDevice, selectedConfiguration, wirelessInterfaceId) => {
    const currentConfiguration = selectedConfiguration || selectCurrentDevice?.configuration;
    if (
      !selectCurrentDevice ||
      !currentConfiguration ||
      !currentConfiguration.wirelessInterfaces ||
      currentConfiguration.wirelessInterfaces.length === 0 ||
      !selectCurrentDevice?.configuration ||
      !selectCurrentDevice?.configuration?.wirelessInterfaces ||
      selectCurrentDevice?.configuration?.wirelessInterfaces.length === 0
    ) {
      return null
    }
    if (!wirelessInterfaceId) {
      return currentConfiguration.wirelessInterfaces[0];
    }

    return currentConfiguration.wirelessInterfaces.find((wirelessInterface) => wirelessInterface.id === wirelessInterfaceId)
  }
)

export const selectDeviceAllLoadingState = createSelector(
  selectDeviceSlice,
  (state) => state.loadAllStatus
);

export const selectDeviceOtherLoadingState = createSelector(
  selectDeviceSlice,
  (state) => state.otherLoadings
);

export const selectSearchDeviceIdList = createSelector(
  selectDeviceSlice,
  (state) => state.searchDeviceIdList
);

export const selectDevicesByArrayOfIds = (idList: number[]) => createSelector(
  selectRoot,
  (root) => {
    const deviceModelList: DeviceModel[] = [];
    idList.forEach((id: number) => {
      const device = deviceAdapterSelectors.selectById(root, id);
      if (device) {
        deviceModelList.push(device);
      }
    })

    return deviceModelList;
  }
);

export const selectSearchDeviceList = createSelector(
  selectRoot,
  selectSearchDeviceIdList,
  (root, searchIdList) => selectDevicesByArrayOfIds(searchIdList)(root)
);

export const selectMyRequestsIdList = createSelector(
  selectDeviceSlice,
  (state) => state.myRequestsDeviceIdList
);

export const selectMyRequestDeviceList = createSelector(
  selectRoot,
  selectMyRequestsIdList,
  (root, myRequestsIdList) => selectDevicesByArrayOfIds(myRequestsIdList)(root)
);

export const selectMyWorkflowIdList = createSelector(
  selectDeviceSlice,
  (state) => state.myWorkflowDeviceIdList
);

export const selectMyWorkflowDeviceList = createSelector(
  selectRoot,
  selectMyWorkflowIdList,
  (root, myWorkflowIdList) => selectDevicesByArrayOfIds(myWorkflowIdList)(root)
);