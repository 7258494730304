import polygonClipping, { Polygon, Pair, MultiPolygon } from 'polygon-clipping';

export type Vector = {
  x: number,
  y: number,
}

export type GeometryPoint = {
  x: number,
  y: number,
}

export type Line = {
  point1: GeometryPoint,
  point2: GeometryPoint,
}

export type RenderPolygon = GeometryPoint[];

export function createVectorFromPoints(point1: GeometryPoint, point2: GeometryPoint) {
  return {
    x: point2.x - point1.x,
    y: point2.y - point1.y,
  }
}

export function createUnitVectorFromPoints(point1: GeometryPoint, point2: GeometryPoint) {
  const vector = createVectorFromPoints(point1, point2);
  const length = getVectorLength(vector);

  return {
    x: vector.x / length,
    y: vector.y / length,
  }
}

export function crossProduct(vector1: Vector, vector2: Vector) {
  return vector1.x * vector2.y - vector1.y * vector2.x;
}

export function rotateVector(vector: Vector, angle: number): Vector {
  return {
    x: vector.x * Math.cos(angle) - vector.y * Math.sin(angle),
    y: vector.x * Math.sin(angle) + vector.y * Math.cos(angle),
  }
}

export function areLineSegmentsCrossing(AB: Line, CD: Line) {
  const vecAB = createVectorFromPoints(AB.point1, AB.point2);
  const vecAC = createVectorFromPoints(AB.point1, CD.point1);
  const vecAD = createVectorFromPoints(AB.point1, CD.point2);
  const vecCD = createVectorFromPoints(CD.point1, CD.point2);
  const vecCA = createVectorFromPoints(CD.point1, AB.point1);
  const vecCB = createVectorFromPoints(CD.point1, AB.point2);

  return Math.sign(crossProduct(vecAB, vecAC)) !== Math.sign(crossProduct(vecAB, vecAD)) &&
    Math.sign(crossProduct(vecCD, vecCA)) !== Math.sign(crossProduct(vecCD, vecCB));
}

export function checkFigureCrossing(pointList: GeometryPoint[]) {
  let areCrossing = false;

  if (pointList.length <= 3) {
    return false;
  }

  const lineList: Line[] = [];

  pointList.forEach((point, index) => {
    const prevPoint = index === 0 ? pointList[pointList.length - 1] : pointList[index - 1];

    lineList.push({
      point1: point,
      point2: prevPoint,
    })
  });

  lineList.forEach((line, index) => {
    if (index === lineList.length - 1) {
      return;
    }

    for (let i = index + 2; i < lineList.length; i++) {
      // skip first and last lines
      if (index === 0 && i === lineList.length - 1) {
        return;
      }
      const line2 = lineList[i];

      if (areLineSegmentsCrossing(line, line2)) {
        areCrossing = true;
      }
    }
  });

  return areCrossing;
}

export function getVectorLength(vector: Vector) {
  return Math.sqrt(vector.x * vector.x + vector.y * vector.y);
}

export function getMultiplePolygonSum(polygonList: RenderPolygon[]) {
  const polygonListLib = polygonList.map(
    (polygon) => convertPolygonToLibVersion(polygon)
  ) as Polygon[];

  return convertLibPolygonToRenderPolygon(
    polygonClipping.union.call(polygonClipping, polygonListLib)
  )
}

export function getPolygonIntersection(firstPolygon: RenderPolygon, secondPolygon: RenderPolygon) {
  return convertLibPolygonToRenderPolygon(polygonClipping.intersection(convertPolygonToLibVersion(firstPolygon), convertPolygonToLibVersion(secondPolygon)));
}

export function getPolygonDifference(firstPolygon: RenderPolygon, secondPolygon: RenderPolygon) {
  return convertLibPolygonToRenderPolygon(polygonClipping.difference(convertPolygonToLibVersion(firstPolygon), convertPolygonToLibVersion(secondPolygon)));
}

function convertPolygonToLibVersion(polygon: RenderPolygon): Polygon {
  return [ polygon.map((point) => [ Math.round(point.x * 1000) / 1000, Math.round(point.y * 1000) / 1000 ] as Pair) ];
}

function convertLibPolygonToRenderPolygon(polygon: MultiPolygon) {
  if (polygon.length === 0) {
    return [];
  }

  const firstGeom = polygon[0];

  if (firstGeom.length === 0) {
    return [];
  }

  const firstPoly = firstGeom[0];

  return firstPoly.map((point) => {
    return {
      x: point[0],
      y: point[1],
    }
  })
}

export function ifFiguresAreTheSame(firstFigure: RenderPolygon, secondFigure: RenderPolygon) {
  if (firstFigure.length === 0 || secondFigure.length === 0) {
    return false
  }
  const result = getPolygonDifference(firstFigure, secondFigure);

  return result.length === 0;
}

export function divideLineIntoPoints(line: Line, numberOfPoints = 10) {
  const pointList: GeometryPoint[] = [];
  const vector = createVectorFromPoints(line.point1, line.point2);
  const length = getVectorLength(vector);
  const points = length < numberOfPoints * 5 ? Math.floor(length / 5) : numberOfPoints;

  for (let i = 1; i < points; i++) {
    pointList.push({
      x: line.point1.x + (vector.x / points * i),
      y: line.point1.y + (vector.y / points * i),
    });
  }

  pointList.push(line.point2);

  return pointList;
}

export function createRhombusFromLine(line: Line, length: number) {
  const vector = createVectorFromPoints(line.point1, line.point2);
  const newVector = rotateVector(vector, Math.PI / 2);
  const vectorLength = getVectorLength(newVector);
  const result: RenderPolygon = [];

  result.push({
    x: line.point1.x + newVector.x * length / vectorLength,
    y: line.point1.y + newVector.y * length / vectorLength,
  });

  result.push({
    x: line.point2.x + newVector.x * length / vectorLength,
    y: line.point2.y + newVector.y * length / vectorLength,
  });

  result.push({
    x: line.point2.x - newVector.x * length / vectorLength,
    y: line.point2.y - newVector.y * length / vectorLength,
  });

  result.push({
    x: line.point1.x - newVector.x * length / vectorLength,
    y: line.point1.y - newVector.y * length / vectorLength,
  });

  return result;
}

export function createPolygonCircle(center: GeometryPoint, radius: number, precision = 360) {
  const result: RenderPolygon = [];
  const step = Math.PI / precision;
  let t = 0;

  while (t < Math.PI * 2) {
    result.push({
      x: center.x + radius * Math.cos(t),
      y: center.y + radius * Math.sin(t),
    });

    t += step;
  }

  return result;
}

export function optimizePolygon(figure: RenderPolygon, precision = 10) {
  const result: RenderPolygon = [];

  figure.forEach((point) => {
    if (result.length < 1) {
      result.push(point);
    }

    const lastPoint = result[result.length - 1];

    const vector1 = createVectorFromPoints(lastPoint, point);
    if (getVectorLength(vector1) > precision) {
      result.push(point);
    }
  })

  return result;
}