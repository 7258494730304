import cn from "classnames";
import ChildrenElement from "../../../../models/HelperModels/ChildrenElement";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import classes from "./index.module.scss";

type BulkMenuItemProps = {
  step: number,
  currentStep: number,
  text: string,
  secondaryText?: string,
  errorMessage?: ChildrenElement,
  isError?: boolean,
}

const BulkEditMenuGeneral = (props: BulkMenuItemProps) => {
  const { step, currentStep, text, secondaryText, errorMessage, isError } = props;
  const isSelected = step === currentStep;
  const isDone = currentStep > step;

  return (
    <>
      <ListItem selected={isSelected}>
        {
          isSelected ? (
            <ListItemIcon><ArrowRightAltIcon color='primary' /></ListItemIcon>
          ) : null
        }
        <ListItemText
          className={cn({
            [classes.listItem]: !isSelected,
          })}
          classes={{
            secondary: cn({
              [classes.done]: isDone,
              [classes.error]: isError,
            })
          }}
          primary={text}
          secondary={(isDone || isError) ? (secondaryText || 'Done') : ''}
        />
      </ListItem>
      {
        isError ? (
          errorMessage
        ) : null
      }
    </>
  );
}

export default BulkEditMenuGeneral;
