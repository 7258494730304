import { useSelector } from "react-redux";
import HorizontalPanel from "../../../components/HorizontalPanel";
import BreadcrumbsContainer from "../../Shared/BreadcrumbsContainer";
import FloorListPanel from "../FloorListPanel";
import MapPreview from "../../Shared/MapPreview";
import BuildingForm from "../BuildingForm";
import EditFloorPanel from "../EditFloorPanel";
import { selectCurrentBuildingId } from "../../../store/buildingStore/buildingsSelectors";

const BuildingCentralArea = () => {
  const currentBuildingId = useSelector(selectCurrentBuildingId);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <BreadcrumbsContainer items={[ 'locations', 'buildings' ]} />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <BuildingForm />
      </HorizontalPanel>
      {
        currentBuildingId === -1 ? null : (
          <>
            <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
              <FloorListPanel />
            </HorizontalPanel>
            <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
              <EditFloorPanel />
            </HorizontalPanel>
            <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
              <MapPreview />
            </HorizontalPanel>
          </>
        )
      }
    </HorizontalPanel>
  );
}

export default BuildingCentralArea;