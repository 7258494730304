import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel, Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";

import classes from './index.module.scss';

interface Props {
  login: (values: { login: string; password: string }) => void;
  status: boolean | null;
  onSSO?: () => any | null;
  loginLink?: string | null;
  loginLinkTitle?: string | null,
}

const validationSchema = yup.object({
  login: yup.string().required("Login is required"),
  password: yup.string().required("Password is required"),
});

export const LoginForm = (props: Props) => {
  const { onSSO, loginLink, loginLinkTitle } = props;
  const { t } = useTranslation();
  const loginForm = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    onSubmit: (values) => props.login(values),
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isInPasswordArea, setInPasswordArea] = React.useState<boolean>(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseEnter = () => {
    setInPasswordArea(true);
  };

  const handleMouseLeave = () => {
    setShowPassword(false);
    setInPasswordArea(false);
  };

  const handleButtonPress = (event: any) => {
    setShowPassword(true);
    handleMouseDownPassword(event);
  };

  const handleButtonRelease = (event: any) => {
    setShowPassword(false);
    handleMouseDownPassword(event);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={loginForm.handleSubmit}>
      <Grid container direction="column" alignItems="stretch" spacing={4}>
        <Grid item>
          <Typography variant="h6">
            <Trans i18nKey="title.login">
              Welcome <br></br> to Enterprise Frequency Manager!
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            id="login"
            label={t("label.login")}
            variant="outlined"
            name="login"
            fullWidth
            value={loginForm.values.login}
            onChange={loginForm.handleChange}
            helperText={loginForm.errors.login}
            error={Boolean(loginForm.errors.login)}
          />
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="password">{t("label.password")}</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword && isInPasswordArea ? "text" : "password"}
              label={t("label.password")}
              name="password"
              value={loginForm.values.password}
              onChange={loginForm.handleChange}
              error={Boolean(loginForm.errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t("label.toggle_password_visibility")}
                    onMouseDown={handleButtonPress}
                    onMouseUp={handleButtonRelease}
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={handleMouseEnter}
                    disableRipple
                    edge="end"
                    tabIndex={-1}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {Boolean(loginForm.errors.password) && (
              <FormHelperText error id="accountId-error">
                {loginForm.errors.password}
              </FormHelperText>
            )}
            {Boolean(props.status) && (
              <FormHelperText error id="accountId-error">
                Wrong Login or Password
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {
          loginLink && loginLinkTitle ? (
            <Grid container spacing={2} className={classes.linksContainer}>
              <Grid item xs={12} className={classes.linksText}>
                <Link target={"_blank"} href={loginLink}>{loginLinkTitle}</Link>
              </Grid>
            </Grid>
          ) : null
        }
        <Grid item container spacing={2} className={classes.buttonContainer}>
          <Grid item xs={onSSO ? 6 : 12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {t("button.login_now")}
            </Button>
          </Grid>
          {
            onSSO ? (
              <Grid item xs={6}>
                <Button variant="contained" color="default" fullWidth onClick={onSSO}>
                  Login with SSO
                </Button>
              </Grid>
            ) : null
          }
        </Grid>
      </Grid>
    </form>
  );
};
