import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { AddCircleOutline } from '@material-ui/icons';
import { Grid } from "@material-ui/core";
import { Toolbar } from 'app/components';
import HorizontalPanel from "../../../components/HorizontalPanel";
import {
  selectCurrentDevice,
  selectCurrentDeviceId,
  selectDevicesByMapId,
} from "../../../store/deviceStore/deviceSelectors";
import useDevicesPage from "../devicePageHooks";
import { isMapPointsSet } from "../../../helpers/mapHelpers";
import DeviceMenuList from "../../DeviceMenuList";
import useFilter from "../../../hooks/filter.hook";
import FilterPanel from "../../Shared/FilterPanel";
import FilterChipContainer from "../../Shared/FilterChipContainer";

import classes from './index.module.scss';

export const DeviceLeftPanel = () => {
  const { actions, state } = useDevicesPage();
  const { currentMapId, currentMap } = state;
  const filters = useFilter();
  const { isAnyFilterChosen, isFilterPanelVisible } = filters.state;
  const { filterDeviceList } = filters.actions;
  const currentPlaceDevices = useSelector(selectDevicesByMapId(currentMapId));
  const filteredList = filterDeviceList(currentPlaceDevices);
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const currentDevice = useSelector(selectCurrentDevice);
  const isCreateButtonAvailable = isMapPointsSet(currentMap);

  const updateUrl = (id: number | null) => {
    actions.onDeviceNavigate(id, true);
  }

  const checkAndSelectFirst = () => {
    if (!currentDevice && currentDeviceId !== -1) {
      const firstDevice = filteredList[0];
      const id = firstDevice?.id || -1;
      updateUrl(id);
    }
  }

  useEffect(
    checkAndSelectFirst,
    [ currentDeviceId, currentPlaceDevices, currentDevice ]
  );

  if (isFilterPanelVisible) {
    return (
      <FilterPanel
        onSelectedDeviceClear={() => updateUrl(null)}
      />
    )
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Controls" />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Grid container className={classes.filterContainer}>
          <FilterChipContainer
            positionAbsolute={false}
            chips={[
              FilterChipContainer.FILTER_CHIP_NAMES.MANUAL_FILTERS,
              FilterChipContainer.FILTER_CHIP_NAMES.SMART_FILTERS,
            ]}
          />
        </Grid>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={isAnyFilterChosen ? "Filtered Device List" : "Device list"}>
          <IconButton
            component="span"
            color="secondary"
            size="small"
            disabled={!isCreateButtonAvailable}
            onClick={() => actions.onDeviceNavigate(-1)}
          >
            <AddCircleOutline />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <DeviceMenuList onDeviceClick={actions.onDeviceNavigate} />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}