import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";
import DeviceTemplateModel from "../../models/DeviceTemplateModel";

const SLICE_NAME = "deviceTemplate";

export const requestTemplatesByCountryId = createAsyncThunk(
  `${SLICE_NAME}/byCountryId`,
  async (countryId: number | undefined | null, { rejectWithValue }) => {
    try {
      const data: { templates: DeviceTemplateModel[] } = await request.get(`/template`, { params: { countryId } });
      return data;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createTemplateFromDevice = createAsyncThunk(
  `${SLICE_NAME}/createFromDevice`,
  async (deviceId: number | undefined | null, { rejectWithValue }) => {
    try {
      const data: { template: DeviceTemplateModel } = await request.post(`/template/${deviceId}`);
      return data.template;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const upsertTemplate = createAsyncThunk(
  `${SLICE_NAME}/upsert`,
  async (body: unknown, { rejectWithValue }) => {
    try {
      const data: { template: DeviceTemplateModel } = await request.post(`/template`, body);
      return data.template;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const addWirelessInterfaceToTemplate = createAsyncThunk(
  `${SLICE_NAME}/wireless-interface`,
  async (body: { templateId: number, technologyConfigurationId: number, }, { rejectWithValue }) => {
    try {
      const data: { template: DeviceTemplateModel } = await request.post(`/template/wireless-interface`, body);
      return data.template;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number | undefined | null, { rejectWithValue }) => {
    try {
      await request.delete(`/template/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);