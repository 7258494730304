import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { Dropdown } from "../../../app/components/Dropdown";
import {
  selectAllRegions,
  selectCurrentRegion,
  selectCurrentRegionId
} from "../../../store/regionStore/regionSelectors";
import useNavigator from "../../../hooks/navigator.hook";

const RegionFilter = () => {
  const currentRegionId = useSelector(selectCurrentRegionId);
  const { navigateToLocationPage } = useNavigator();
  const regionList = useSelector(selectAllRegions) || [];
  const currentRegion = useSelector(selectCurrentRegion);

  useEffect(() => {
    if (currentRegionId && !currentRegion) {
      navigateToLocationPage({ regionId: null }, true);
    }
  }, [ currentRegionId, currentRegion ])

  const onChange = (e) => {
    const regionId = e.target.value || null;
    const paramsToChange = {
      regionId,
    };

    navigateToLocationPage(paramsToChange)
  }

  return (
    <Grid item>
      <Dropdown
        useDefaultItem
        selectedValue={currentRegionId || 0}
        values={regionList}
        label='Region'
        name='region-filter'
        fullWidth
        valueChange={onChange}
      />
    </Grid>
  );
}

export default RegionFilter;