import { useSelector } from "react-redux";
import { isUserLoggedIn } from "../store/userStore/userSelectors";
import ConditionalRoute from "./ConditionalRoute";
import { App } from "../app/App";
import { pagePathGenerator } from "./pagePathGenerator";

const InnerRoutes = () => {
  const loggedIn = useSelector(isUserLoggedIn);

  return (
    <ConditionalRoute
      condition={() => loggedIn}
      redirectTo={pagePathGenerator.LOGIN_PAGE()}
    >
      <App />
    </ConditionalRoute>
  )
}

export default InnerRoutes;