import React from "react";
import ModalWithToggle from "../../../components/ModalWithToggle";
import { Grid, IconButton, Typography } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

import classes from './index.module.scss';

const DrawingInfo = () => {
  return (
    <ModalWithToggle
      confirmButtonText="Got it"
      closeOnConfirm
      modalContent={(
        <>
          <Typography variant='h5' align="center" className={classes.header}>Controls:</Typography>
          <Grid container className={classes.content} direction="column">
            <Typography variant='body1'>&bull; <b>Right mouse click</b>: remove the last point</Typography>
            <Typography variant='body1'>&bull; Hold <b>SHIFT</b>: draw a horizontal line</Typography>
            <Typography variant='body1'>&bull; Hold <b>CTRL/CMD</b>: draw a vertical line</Typography>
            <Typography variant='body1'>&bull; Press <b>ENTER</b>: to finish</Typography>
            <Typography variant='body1'>&bull; Press <b>ESC</b>: to cancel</Typography>
          </Grid>
        </>
      )}
    >
      <IconButton
        aria-label="info"
        color='secondary'
        size='small'
      >
        <InfoIcon />
      </IconButton>
    </ModalWithToggle>
  )
}

export default DrawingInfo;