import { AppDispatch } from "app/state/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoginForm } from "./login-form/LoginForm";
import ModalPageLayout from "../../layouts/ModalPageLayout/ModalPageLayout";
import { loginWithPassword } from "../../store/userStore/userRequests";
import {
  isUserLoggedIn,
  selectLoginStatus,
} from "../../store/userStore/userSelectors";
import PAGES, { getQueryObject } from "../../pages";
import { selectGlobalSettings } from "../../store/settingsStore/settingsSelectors";

import styles from "./Login.module.scss";
import { useSnackbar } from "../shared/snackbar-context/snackbar.context";
import { stringToHtml } from "../../helpers/stringHelpers";
import { redirectToLogin } from "../../routes/pagePathGenerator";

const { REACT_APP_SSO_BASE_URL } = process.env;

export const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const query = getQueryObject();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const status = useSelector(selectLoginStatus);
  const loginStatus = useSelector(isUserLoggedIn);
  const settings = useSelector(selectGlobalSettings);
  const { loginLink, loginLinkTitle } = settings;

  useEffect(() => {
    if (loginStatus) {
      if (query.next) {
        history.push(decodeURIComponent(query.next as string));
      } else {
        history.push(PAGES.LOCATIONS_PAGE);
      }

      if (settings.systemMessage) {
        openSnackbar({
          html: stringToHtml(settings.systemMessage),
          timeout: 60000,
          title: "System message",
          severity: "warning",
        });
      }
      return;
    } else {
      redirectToLogin();
    }
  }, [loginStatus]);

  const loginCallback = (values: { login: string; password: string }) => {
    dispatch(loginWithPassword(values));
  }

  const onSSO = () => {
    history.replace(PAGES.SSO_LOGIN);
  }

  return (
    <ModalPageLayout classes={{
      container: styles.container,
    }}>
      <LoginForm
        status={Boolean(status?.error)}
        login={loginCallback}
        loginLink={loginLink}
        loginLinkTitle={loginLinkTitle}
        onSSO={REACT_APP_SSO_BASE_URL ? onSSO : undefined}
      />
    </ModalPageLayout>
  );
};
