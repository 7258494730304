import cn from "classnames";
import HorizontalPanel from "../../../components/HorizontalPanel";
import classes from "../BulkSelectDevicesList/index.module.scss";
import { Toolbar } from "../../../app/components";
import { Grid } from "@material-ui/core";
import DeviceTable from "../../../components/DeviceTable";
import { useSelector } from "react-redux";
import { selectAllDevices } from "../../../store/deviceStore/deviceSelectors";
import { filterDeviceIdList } from "../../../helpers/urlChecker";

type BulkInvalidDeviceListProps = {
  deviceIdList: number[];
  actionName: string;
}

const BulkInvalidDeviceList = (props: BulkInvalidDeviceListProps) => {
  const deviceAllList = useSelector(selectAllDevices);
  const deviceList = filterDeviceIdList(deviceAllList, props.deviceIdList);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} className={classes.container}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={`Not valid for ${props.actionName} (${deviceList?.length || 0})` } />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.container}>
        <Grid container className={cn(classes.tableContainer, {
          [classes.width4K]: !!deviceList,
        })}>
          <DeviceTable
            deviceList={deviceList || []}
          />
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default BulkInvalidDeviceList;