import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import { Delete, MyLocation } from "@material-ui/icons";
import { selectCurrentDevice } from "../../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import { useTypedDispatch } from "../../../../app/state";
import { setEditMode } from "../../../../store/generalStore/generalState";
import { deleteDevicePoint } from "../../../../store/deviceStore/deviceRequests";

import classes from "../index.module.scss";

const StationaryPoint = ({ formDisabled = false }) => {
  const dispatch = useTypedDispatch();
  const selectedDevice = useSelector(selectCurrentDevice);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || selectedDevice?.configuration;
  const isDeviceSetOnMap = () => Boolean(currentConfiguration?.mapPoint);
  const isChangePointButtonEnabled = () => !formDisabled && !!selectedDevice;

  const setDeviceOnMap = () => {
    dispatch(setEditMode(true));
  };

  const removeDeviceFromMap = () => {
    if (!selectedDevice) {
      return;
    }
    dispatch(setEditMode(false));
    dispatch(deleteDevicePoint(selectedDevice.id));
  };

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid item><Typography variant='subtitle1' className={classes.line}>Point on map</Typography></Grid>
      <Grid item>
        {
          isDeviceSetOnMap() ?
            (
              <IconButton
                aria-label='remove device from map'
                color='secondary'
                size='small'
                disabled={!isChangePointButtonEnabled()}
                onClick={removeDeviceFromMap}
              >
                <Delete />
              </IconButton>
            )
            :
            (
              <IconButton
                aria-label='set device from map'
                color='secondary'
                size='small'
                disabled={!isChangePointButtonEnabled()}
                onClick={setDeviceOnMap}
              >
                <MyLocation />
              </IconButton>
            )
        }
      </Grid>
    </Grid>
  )
}

export default StationaryPoint;