import { useState } from "react";
import AddPropsToChildren from "../AddPropsToChildren";
import ChildrenElement from "../../models/HelperModels/ChildrenElement";
import { GenericModal, ModalProps } from "../../app/components/ui/generic-modal/GenericModal";

type ModalWithToggleProps = {
  children: ChildrenElement,
  modalContent: ChildrenElement,
} & Partial<ModalProps>

const ModalWithToggle = ({ children, modalContent, ...props }: ModalWithToggleProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const onClick = () => {
    setIsOpened(true);
  }

  const onClose = () => {
    setIsOpened(false)
  }


  return (
    <>
      <AddPropsToChildren
        extraProps={{
          onClick,
        }}
      >
        {children}
      </AddPropsToChildren>
      <GenericModal
        {...props}
        isOpen={isOpened}
        closeModal={onClose}
      >
        {modalContent}
      </GenericModal>
    </>
  )
}

export default ModalWithToggle;