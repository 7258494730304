import PageUpdater from "../../containers/PageUpdater";
import useRoutes from "../../hooks/routeHooks";
import { setCurrentTemplateId } from "../../store/templatesStore/templateState";
import { requestTemplatesByCountryId } from "../../store/templatesStore/templatesRequest";
import { getQueryObject } from "../index";
import { GeneralRequest } from "../../requests/GeneralRequest";
import FetchDeviceRequirementsWrapper from "../../containers/DevicesPage/FetchDeviceRequirementsWrapper";
import TemplatesGeneralContainer from "../../containers/TemplatesPage/TemplatesGeneralContainer";
import { setCurrentCountryId } from "../../store/countryStore/countryState";

const TemplatePage = () => {
  const urlProps = getQueryObject();
  const countryId = parseInt(urlProps.countryId as string, 10);
  const { actions } = useRoutes();

  if (isNaN(countryId)) {
    actions.redirectWrongId();
    return null;
  }

  return (
    <>
      <PageUpdater
        watchItemList={[
          {
            title: 'templateId',
            action: setCurrentTemplateId,
          },
          {
            title: 'countryId',
            action: setCurrentCountryId,
          },
        ]}
      />
      <GeneralRequest
        requestAction={requestTemplatesByCountryId}
        requestArguments={[ countryId ]}
      >
        <FetchDeviceRequirementsWrapper>
          <TemplatesGeneralContainer />
        </FetchDeviceRequirementsWrapper>
      </GeneralRequest>
    </>

  );
}

export default TemplatePage;