import { useSelector } from "react-redux";
import { selectSearchDeviceList } from "../../../store/deviceStore/deviceSelectors";
import DeviceTable from "../../../components/DeviceTable";

const SearchTable = () => {
  const deviceList = useSelector(selectSearchDeviceList);

  return (
    <DeviceTable deviceList={deviceList} />
  )
}

export default SearchTable;