import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { Cancel, Delete, Save } from "@material-ui/icons";
import { Grid, IconButton, TextField } from "@material-ui/core";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import {
  selectCurrentTemplate,
  selectCurrentTemplateId,
} from "../../../store/templatesStore/templatesSelectors";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import WirelessSystemSelect from "../../DevicesPage/WirelessSystemSelect";
import { Dropdown } from "../../../app/components/Dropdown";
import { selectCurrentUser } from "../../../store/userStore/userSelectors";
import { selectAllCountriesSorted, selectCurrentCountryId } from "../../../store/countryStore/countrySelectors";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { deleteTemplate, upsertTemplate } from "../../../store/templatesStore/templatesRequest";
import { useTypedDispatch } from "../../../app/state";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import DeviceTemplateModel from "../../../models/DeviceTemplateModel";

import classes from "./index.module.scss";

const TemplateCentralPanel = () => {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const currentCountryId = useSelector(selectCurrentCountryId);
  const { showModal } = useShowModal();
  const currentTemplateId = useSelector(selectCurrentTemplateId);
  const currentTemplate = useSelector(selectCurrentTemplate);
  const currentUser = useSelector(selectCurrentUser);
  const allCountries = useSelector(selectAllCountriesSorted);
  const toolBarTitle = currentTemplateId === -1 ? 'Create template' : 'Template details';
  const formDisabled = (currentTemplateId !== -1) && (!currentTemplateId || currentUser?.id !== currentTemplate?.ownerId);
  const canDelete = currentTemplate?.ownerId === currentUser.id;

  const initialValues = {
    name: currentTemplate?.name || '',
    wirelessSystemId: currentTemplate?.wirelessSystemId || 1,
    type: currentTemplate?.type || 0,
    owner: ((currentTemplate?.owner || currentUser)?.profile?.fullName) || '',
    affectedUnits: currentTemplate?.affectedUnits || '',
    description: currentTemplate?.description || '',
    modelName: currentTemplate?.modelName || '',
    manufacturer: currentTemplate?.manufacturer || '',
    operator: currentTemplate?.operator || '',
    operatorInfo: currentTemplate?.operatorInfo || '',
    countryId: currentTemplate?.countryId || currentCountryId,
  }

  const navigateToTemplate = (id: number | null) => {
    const url = pagePathGenerator.DEVICE_TEMPLATE_PAGE({
      countryId: currentCountryId,
      templateId: id,
    });

    history.replace(url);
  }

  const templateFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema: yup.object().shape({
      name: yup.string().max(50).required('Template name is required'),
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  })

  const onSaveButtonClick = () => {
    dispatch(upsertTemplate({
        ...templateFormik.values,
        id: currentTemplateId === -1 ? undefined : currentTemplateId,
      })
    ).then((action) => {
      const template = action.payload as DeviceTemplateModel;
      navigateToTemplate(template.id);
    })
  };

  const deleteHandler = () => {
    dispatch(deleteTemplate(currentTemplateId))
      .then(() => {
        navigateToTemplate(null);
      })
  }

  const onDeleteButtonClick = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Delete selected template',
      text: 'Do you want to delete this template?',
      confirmButtonHandler: deleteHandler,
      confirmButtonText: 'Delete',
    });
  }

  const onCancelChangesClick = () => {
    templateFormik.resetForm({ values: initialValues });
  }

  const onSubmit = (e) => e.preventDefault();

  const { values, errors, handleChange } = templateFormik;

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={toolBarTitle}>
          <CheckPermission permissionName={PERMISSIONS.TEMPLATE_ACTIONS}>
            <IconButton
              disabled={formDisabled || !templateFormik.dirty}
              aria-label='Save template'
              color='secondary'
              size='small'
              onClick={onSaveButtonClick}
            >
              <Save />
            </IconButton>
            <CheckPermission
              permissionName={PERMISSIONS.TEMPLATE_DELETE}
              noPermissionComponent={(
                <IconButton
                  disabled={formDisabled || currentTemplateId === -1 || !canDelete}
                  aria-label='Delete template'
                  color='secondary'
                  size='small'
                  onClick={onDeleteButtonClick}
                >
                  <Delete />
                </IconButton>
              )}
            >
              <IconButton
                disabled={!currentTemplateId || currentTemplateId === -1}
                aria-label='Delete template'
                color='secondary'
                size='small'
                onClick={onDeleteButtonClick}
              >
                <Delete />
              </IconButton>
            </CheckPermission>
            <IconButton
              disabled={formDisabled || !templateFormik.dirty}
              aria-label='cancel changes'
              color='secondary'
              size='small'
              onClick={onCancelChangesClick}
            >
              <Cancel />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
        <Grid
          container
          component='form'
          spacing={2}
          onSubmit={onSubmit}
          className={classes.form}
          wrap='wrap'
        >
          <Grid item xs={3}>
            <TextField
              label='Template name'
              required
              variant='outlined'
              name='name'
              fullWidth
              disabled={formDisabled}
              value={values.name ?? ''}
              onChange={handleChange}
              helperText={errors.name}
              error={Boolean(errors.name)}
            />
          </Grid>
          <Grid item xs={3}>
            <WirelessSystemSelect
              disabled={formDisabled}
              wirelessSystemId={values.wirelessSystemId}
              setFieldValue={templateFormik.setFieldValue}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              values={allCountries}
              label="Country"
              valueChange={handleChange}
              disabled
              fullWidth
              selectedValue={values.countryId}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              disabled={formDisabled}
              values={[
                {
                  id: 0,
                  name: 'Stationary',
                },
                {
                  id: 1,
                  name: 'Portable',
                },
                {
                  id: 2,
                  name: 'Group'
                },
              ]}
              label='Type'
              name='type'
              fullWidth
              valueChange={handleChange}
              selectedValue={+values.type}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label='Template owner'
              variant='outlined'
              name='owner'
              fullWidth
              value={values.owner}
              disabled={true}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label='Affected unit'
              variant='outlined'
              name='affectedUnits'
              fullWidth
              value={values.affectedUnits}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={formDisabled}
              name='description'
              label='Template description'
              multiline
              maxRows={3}
              onChange={handleChange}
              value={values.description}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Model name'
              variant='outlined'
              name='modelName'
              fullWidth
              value={values.modelName}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Manufacturer'
              variant='outlined'
              name='manufacturer'
              fullWidth
              value={values.manufacturer}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Operator'
              variant='outlined'
              name='operator'
              fullWidth
              value={values.operator}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Operator contact information'
              variant='outlined'
              name='operatorInfo'
              fullWidth
              value={values.operatorInfo}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default TemplateCentralPanel;