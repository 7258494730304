import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTypedDispatch } from "../../app/state";
import { setCurrentFloorId } from "../../store/floorStore/floorState";
import { setCurrentRegionId } from "../../store/regionStore/regionState";
import { setCurrentLocationId } from "../../store/locationStore/locationState";
import { setCurrentBuildingId } from "../../store/buildingStore/buildingsState";
import { setCurrentConfigurationId } from "../../store/deviceConfigurationStore/deviceConfigurationState";
import { setCurrentDeviceId, setCurrentWirelessInterfaceId } from "../../store/deviceStore/deviceState";
import { setCurrentWirelessSystemId } from "../../store/wirelessSystemStore/wirelessSystemState";
import {
  setFilterPanelVisibility,
} from "../../store/filterStore/filterState";
import usePrevious from "../../hooks/usePrevious";

const ClearCurrentStateContainer = () => {
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const prevPath = usePrevious(location.pathname);

  useEffect(() => {
    if (prevPath !== location.pathname) {
      // location changed and not query or hash
      dispatch(setCurrentRegionId(null));
      dispatch(setCurrentLocationId(null));
      dispatch(setCurrentBuildingId(null));
      dispatch(setCurrentFloorId(null));

      // device
      dispatch(setCurrentConfigurationId(null));
      dispatch(setCurrentWirelessInterfaceId(null));
      dispatch(setCurrentWirelessSystemId(null));
      dispatch(setCurrentDeviceId(null));

      // clear filter
      dispatch(setFilterPanelVisibility(false));
    }
  }, [location, prevPath]);

  // maybe change of mapId for filters clearance handle here

  return null;
}

export default ClearCurrentStateContainer;