import { createSlice } from "@reduxjs/toolkit";
import { KEY_MAP } from "./keyMap";

type KeyboardState = {
  enter: boolean,
  shift: boolean,
  control: boolean,
  alt: boolean,
  esc: boolean,
}

const initialState: KeyboardState = {
  enter: false,
  shift: false,
  control: false,
  alt: false,
  esc: false,
}

export const keyboardSlice = createSlice({
  name: 'keyboardState',
  initialState,
  reducers: {
    onKeyDown: (state, action) => {
      const key = action.payload;
      const keyName = KEY_MAP[key];

      if (keyName) {
        state[keyName] = true;
      }
    },
    onKeyUp: (state, action) => {
      const key = action.payload;
      const keyName = KEY_MAP[key];

      if (keyName) {
        state[keyName] = false;
      }
    },
  },
});

export const { onKeyDown, onKeyUp } = keyboardSlice.actions