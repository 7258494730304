export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

type Duration = {
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function getDuration(duration = 0): Duration {
  let durationLeft = duration;
  const weeks = Math.floor(durationLeft / WEEK);
  durationLeft = durationLeft - WEEK * weeks;
  const days = Math.floor(durationLeft / DAY);
  durationLeft = durationLeft - DAY * days;
  const hours = Math.floor(durationLeft / HOUR);
  durationLeft = durationLeft - HOUR * hours;
  const minutes = Math.floor(durationLeft / MINUTE);
  durationLeft = durationLeft - MINUTE * minutes;
  const seconds = Math.floor(durationLeft / SECOND);

  return {
    weeks,
    days,
    hours,
    minutes,
    seconds,
  }
}

export function dateFormatted(date, format: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}) {
  return new Date(date).toLocaleString('en-US', format);
}