import LocationModel from "../models/LocationModel";
import CountryModel from "../models/CountryModel";
import RegionModel from "../models/RegionModel";
import BuildingModel from "../models/BuildingModel";
import FloorModel from "../models/FloorModel";
import TechnologyConfigurationModel from "../models/TechnologyConfigurationModel";
import WirelessSystemModel from "../models/WirelessSystemModel";
import { DeviceStatus } from "../models/enums/DeviceStatus.enum";
import UserModel from "../models/UserModel";
import DeviceTagModel from "../models/DeviceTagModel";
import DeviceModel from "../models/DeviceModel";

export function isTheSameUrl(url: string) {
  return url === window.location.pathname + window.location.search;
}

export function filterCountry(countryModelList: CountryModel[], countryIdList: unknown, regionList?: RegionModel[] | null): CountryModel[] | null {
  const foundCountries: CountryModel[] = [];

  if (countryIdList && Array.isArray(countryIdList)) {
    countryIdList.forEach((countryIdString) => {
      const foundCountry = countryModelList.find((country) => country.id === parseInt(countryIdString, 10) && (!regionList ||
        regionList.map((region) => region.id).includes(country.regionId)));

      if (foundCountry) {
        foundCountries.push(foundCountry);
      }
    })
  }

  return foundCountries.length > 0 ? foundCountries : null;
}

export function filterLocation(locationModelList: LocationModel[], locationIdList: unknown, countryList?: CountryModel[] | null): LocationModel[] | null {
  const foundLocationList: LocationModel[] = [];

  if (locationIdList && Array.isArray(locationIdList)) {
    locationIdList.forEach((locationIdString) => {
      const foundLocation = locationModelList.find((location) => location.id === parseInt(locationIdString, 10) && (!countryList ||
        countryList.map((country) => country.id).includes(location.countryId)));

      if (foundLocation) {
        foundLocationList.push(foundLocation);
      }
    })
  }

  return foundLocationList.length > 0 ? foundLocationList : null;
}

export function filterBuilding(buildingModelList: BuildingModel[], buildingIdList: unknown, locationList?: LocationModel[] | null): BuildingModel[] | null {
  const foundBuildingList: BuildingModel[] = [];

  if (buildingIdList && Array.isArray(buildingIdList)) {
    buildingIdList.forEach((buildingIdString) => {
      const foundBuilding = buildingModelList.find((building) => building.id === parseInt(buildingIdString, 10) && (!locationList ||
        locationList.map((location) => location.id).includes(building.locationId)));

      if (foundBuilding) {
        foundBuildingList.push(foundBuilding);
      }
    })
  }

  return foundBuildingList.length > 0 ? foundBuildingList : null;
}

export function filterFloor(floorModelList: FloorModel[], floorIdList: unknown, buildingList?: BuildingModel[] | null): FloorModel[] | null {
  const foundFloorList: FloorModel[] = [];

  if (floorIdList && Array.isArray(floorIdList)) {
    floorIdList.forEach((floorIdString) => {
      const foundFloor = floorModelList.find((floor) => floor.id === parseInt(floorIdString, 10) && (!buildingList ||
        buildingList.map((building) => building.id).includes(floor.buildingId)));

      if (foundFloor) {
        foundFloorList.push(foundFloor);
      }
    })
  }

  return foundFloorList.length > 0 ? foundFloorList : null;
}

export function filterTechnologies(technologyList: TechnologyConfigurationModel[], technologyIdList: unknown, countryList?: CountryModel[] | null): TechnologyConfigurationModel[] | null {
  const foundTechnologies: TechnologyConfigurationModel[] = [];

  if (technologyIdList && Array.isArray(technologyIdList)) {
    technologyIdList.forEach((technologyIdString) => {
      const foundTechnology = technologyList.find((technology) => technology.id === parseInt(technologyIdString, 10) && (!countryList ||
        countryList.some((country) =>
          technology.countries.find((techCountry) => techCountry.id === country.id)
        )));

      if (foundTechnology) {
        foundTechnologies.push(foundTechnology);
      }
    })
  }

  return foundTechnologies.length > 0 ? foundTechnologies : null;
}

export function filterWirelessSystems(wirelessSystemModelList: WirelessSystemModel[], wirelessSystemIdList: unknown) : WirelessSystemModel[] | null {
  const foundWirelessSystems: WirelessSystemModel[] = [];

  if (wirelessSystemIdList && Array.isArray(wirelessSystemIdList)) {
    wirelessSystemIdList.forEach((wirelessSystemsIdString) => {
      const foundWirelessSystem = wirelessSystemModelList.find((wirelessSystem) => wirelessSystem.id === parseInt(wirelessSystemsIdString, 10));

      if (foundWirelessSystem) {
        foundWirelessSystems.push(foundWirelessSystem);
      }
    })
  }

  return foundWirelessSystems.length > 0 ? foundWirelessSystems : null;
}

export function filterDeviceStatus(statusArray: unknown): DeviceStatus[] | null {
  const validStatuses: DeviceStatus[] = [];

  if (statusArray && Array.isArray(statusArray)) {
    statusArray.forEach((status) => {
      Object.keys(DeviceStatus).forEach((key) => {
        if (status === DeviceStatus[key]) {
          validStatuses.push(DeviceStatus[key]);
        }
      })
    })
  }

  if (validStatuses.length > 0) {
    return validStatuses;
  }

  return null;
}

export function filterRequesters(userModelList: UserModel[], requesterArray: unknown): UserModel[] | null {
  const foundRequesters: UserModel[] = [];

  if (requesterArray && Array.isArray(requesterArray)) {
    requesterArray.forEach((requesterIdString) => {
      const foundUser = userModelList.find((user) => user.id === parseInt(requesterIdString, 10));

      if (foundUser) {
        foundRequesters.push(foundUser);
      }
    })
  }

  if (foundRequesters.length > 0) {
    return foundRequesters;
  }

  return null;
}

export function filterDeviceTags(deviceTagModelList: DeviceTagModel[], deviceTagList: unknown): DeviceTagModel[] | null {
  const foundTagList: DeviceTagModel[] = [];

  if (deviceTagList && Array.isArray(deviceTagList)) {
    deviceTagList.forEach((deviceTagIdString) => {
      const foundTag = deviceTagModelList.find((user) => user.id === parseInt(deviceTagIdString, 10));

      if (foundTag) {
        foundTagList.push(foundTag);
      }
    })
  }

  if (foundTagList.length > 0) {
    return foundTagList;
  }

  return null;
}

export function filterDeviceIdList(deviceModelList: DeviceModel[], deviceIdList: unknown): DeviceModel[] | null {
  const deviceFoundList: DeviceModel[] = [];

  if (deviceIdList && Array.isArray(deviceIdList)) {
    deviceIdList.forEach((deviceId) => {
      const foundDevice = deviceModelList.find((deviceModel) => deviceModel.id === parseInt(deviceId, 10));

      if (foundDevice) {
        deviceFoundList.push(foundDevice);
      }
    })
  }

  if (deviceFoundList.length > 0) {
    return deviceFoundList;
  }

  return null;
}