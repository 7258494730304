import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import DeviceTagModel from "../../models/DeviceTagModel";
import { LoadingStatus, status } from "../../app/shared";
import { getAllDeviceTags } from "./deviceTagsRequests";

export const deviceTagsAdapter = createEntityAdapter<DeviceTagModel>({
  selectId: (deviceTag) => deviceTag.id,
  sortComparer: (a, b) => (a.name || '').localeCompare(b.name || ''),
});

type DeviceTagsState = {
  loading: LoadingStatus,
} & EntityState<DeviceTagModel>

const initialState: DeviceTagsState = {
  loading: status.default,
  ...deviceTagsAdapter.getInitialState(),
}

export const deviceTagSlice = createSlice({
  name: 'deviceTagList',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addCase(getAllDeviceTags.fulfilled, (state, action) => {
      deviceTagsAdapter.setMany(state, action.payload)
    })
})