export type PathPoint = {
  x: number,
  y: number,
}

export function createPathFromPoints(pointList: PathPoint[], isLastLineVertical = false, isLastLineHorizontal = false, deviation = {x: 0, y: 0}) {
  if (pointList.length === 0) {
    return '';
  }

  const deviationX = deviation.x;
  const deviationY = deviation.y;

  let result = pointList.map((point, index) => {
    if (index === 0) {
      return `M${point.x + deviationX} ${point.y + deviationY}`
    }

    if (index === pointList.length - 1) {
      const prevPoint = pointList[pointList.length - 2]
      if (prevPoint && isLastLineVertical) {
        return `L${point.x + deviationX} ${prevPoint.y + deviationY}`;
      }

      if (prevPoint && isLastLineHorizontal) {
        return `L${prevPoint.x + deviationX} ${point.y + deviationY}`;
      }
    }
    return `L${point.x + deviationX} ${point.y + deviationY}`
  }).join(' ');

  if (pointList.length > 2) {
    result = result + 'Z';
  }

  return result;
}