import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash/fp";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import { selectCurrentBuildingId } from "../../../store/buildingStore/buildingsSelectors";
import { selectFloorsByBuildingId } from "../../../store/floorStore/floorSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { CheckPermission } from "../../../requests/CheckPermission";
import { FloorForm, floorValidationSchema } from "../../../components/FloorForm";
import useFloor from "../../../hooks/floorHooks";
import useNavigator from "../../../hooks/navigator.hook";

import classes from './index.module.scss';

const EditFloorPanel = () => {
  const inputRef = useRef<HTMLDivElement>();
  const { t } = useTranslation();
  const { navigateToBuildingPage } = useNavigator();
  const { state, actions } = useFloor();
  const buildingId = useSelector(selectCurrentBuildingId);
  const { currentFloorId, currentFloor } = state;
  const allFloors = useSelector(selectFloorsByBuildingId(buildingId));
  const isCreateMode = currentFloorId === -1;

  const updateBuildingUrl = (floorId?: number, shouldReplace = false) => {
    navigateToBuildingPage({ floorId }, shouldReplace);
  }

  const validationSchema = floorValidationSchema(t, allFloors, currentFloorId);

  const floorFormik = useFormik({
    initialValues: {
      ...(currentFloor || {}),
      buildingId: buildingId || undefined,
    },
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSave = async () => {
    const valid = await floorFormik.validateForm();
    if (isEmpty(valid)) {
      await actions.onSaveFloor(floorFormik.values);
    }
  }

  const onCancel = () => {
    floorFormik.resetForm(floorFormik.initialValues as any);
  }

  const onDeleteFloor = () => {
    actions
      .onDeleteFloor()
      .then(() => updateBuildingUrl(undefined, true))
      .catch((e) => {
        // nothing
      })
  }

  const isDeleteDisabled = isCreateMode || floorFormik.dirty;
  const toolbarTitle = isCreateMode ? t('title_new_floor') : t('title_floor_details');

  useEffect(() => {
    if (isCreateMode) {
      inputRef?.current?.focus();
    }
  }, [isCreateMode]);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={toolbarTitle}>
          <CheckPermission permissionName={PERMISSIONS.FLOOR_ACTIONS} noPermissionComponent={null}>
            <IconButton
              disabled={!floorFormik.dirty}
              aria-label='save changes'
              color='secondary'
              size='small'
              onClick={onSave}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              disabled={isDeleteDisabled}
              aria-label='delete'
              color='secondary'
              size='small'
              onClick={onDeleteFloor}
            >
              <Delete />
            </IconButton>
            <IconButton
              disabled={!floorFormik.dirty}
              aria-label='cancel changes'
              color='secondary'
              size='small'
              onClick={onCancel}
            >
              <CancelIcon />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.form}>
        <FloorForm
          values={floorFormik.values}
          errors={floorFormik.errors}
          handleChange={floorFormik.handleChange}
          inputRef={inputRef}
          horizontalView
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default EditFloorPanel;