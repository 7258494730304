import cn from 'classnames';
import DeviceConfigurationModel from "../../models/DeviceConfigurationModel";
import { Avatar, Chip } from "@material-ui/core";
import useNavigator from "../../hooks/navigator.hook";

import classes from './index.module.scss';

type DeviceTagProps = {
  configuration: DeviceConfigurationModel,
}

const DeviceTag = ({ configuration }: DeviceTagProps) => {
  const navigator = useNavigator();
  const tagName = configuration?.deviceTag?.name || '';
  const tagAvatar = tagName.slice(0, 1);
  const operationTag = tagName.split(':')[0];

  const navigateToSearch = (e) => {
    e.stopPropagation();
    if (configuration && configuration.deviceTag) {
      navigator.navigateToSearchPage({ tags: [ configuration?.deviceTag?.id ] });
    }
  }

  return tagName ? (
    <Chip
      avatar={(
        <Avatar className={cn({
          [classes.submit]: operationTag.toLowerCase() === 'request',
          [classes.approve]: operationTag.toLowerCase() === 'approve',
          [classes.reject]: operationTag.toLowerCase() === 'reject',
        })}
        >
          {tagAvatar}
        </Avatar>
      )}
      className={cn({
        [classes.submitChip]: operationTag.toLowerCase() === 'request',
        [classes.approveChip]: operationTag.toLowerCase() === 'approve',
        [classes.rejectChip]: operationTag.toLowerCase() === 'reject',
      })}
      size="small"
      label={tagName}
      onClick={navigateToSearch}
    />
  ) : null;
}

export default DeviceTag