import React from "react";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import SearchCheckUrl from "../SearchCheckUrl";

const SearchLeftPanel = () => {
  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Search' />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <SearchCheckUrl />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default SearchLeftPanel;