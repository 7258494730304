import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentLocationId } from "../store/locationStore/locationSelectors";
import { pagePathGenerator } from "../routes/pagePathGenerator";
import { selectCurrentRegionId } from "../store/regionStore/regionSelectors";
import { selectCurrentCountryId } from "../store/countryStore/countrySelectors";
import IdModel from "../models/HelperModels/IdModel";
import { selectCurrentBuildingId } from "../store/buildingStore/buildingsSelectors";
import SearchQueryParams from "../routes/SearchQueryParams";

const useNavigator = () => {
  const history = useHistory();
  const currentRegionId = useSelector(selectCurrentRegionId);
  const currentCountryId = useSelector(selectCurrentCountryId);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const currentBuildingId = useSelector(selectCurrentBuildingId);

  const navigate = (url: string, shouldReplace = false) => {
    shouldReplace ? history.replace(url) : history.push(url);
  }

  const goBack = () => {
    history.goBack();
  }

  const navigateToBuildingPage = ({
    locationId = currentLocationId,
    buildingId = currentBuildingId,
    floorId,
  }: { locationId?: IdModel, buildingId?: IdModel, floorId?: IdModel }, shouldReplace = false) => {
    const url = pagePathGenerator.BUILDING_PAGE({
      buildingId,
      locationId,
      floorId,
    });

    navigate(url, shouldReplace);
  }

  const navigateToLocationPage = ({
    regionId = currentRegionId,
    countryId = currentCountryId,
    locationId = currentLocationId,
  }: { regionId?: IdModel, countryId?: IdModel, locationId?: IdModel }, shouldReplace = false) => {
    const url = pagePathGenerator.LOCATION_PAGE({
      regionId,
      countryId,
      locationId,
    });

    navigate(url, shouldReplace);
  }

  const navigateToTemplatesPage = ({
    countryId = currentCountryId,
    templateId,
  }: { countryId?: IdModel, templateId: IdModel }, shouldReplace = false) => {
    const url = pagePathGenerator.DEVICE_TEMPLATE_PAGE({
      countryId,
      templateId,
    });

    navigate(url, shouldReplace);
  }

  const navigateToSearchPage = (props: SearchQueryParams, shouldReplace = false) => {
    const url = pagePathGenerator.SEARCH_PAGE(props);

    navigate(url, shouldReplace);
  }

  const navigateToBulkEditPage = (props: { deviceIdList?: number[] | null }, shouldReplace = false) => {
    const url = pagePathGenerator.BULK_EDIT_PAGE(props);

    navigate(url, shouldReplace);
  }

  return {
    navigate,
    goBack,
    navigateToLocationPage,
    navigateToBuildingPage,
    navigateToTemplatesPage,
    navigateToSearchPage,
    navigateToBulkEditPage,
  }
}

export default useNavigator;