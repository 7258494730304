import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import IconButton from "@material-ui/core/IconButton";
import { AddCircleOutline } from "@material-ui/icons";
import DeviceMenuList from "../../DeviceMenuList";
import React from "react";
import useDevicesPage from "../../DevicesPage/devicePageHooks";
import { isMapPointsSet } from "../../../helpers/mapHelpers";

// todo: after filters use this on device left panel
const DeviceList = (props) => {
  const { actions, state } = useDevicesPage();
  const { currentMap } = state;
  const isCreateButtonAvailable = isMapPointsSet(currentMap);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={"Device list"}>
          <IconButton
            component="span"
            color="secondary"
            size="small"
            disabled={!isCreateButtonAvailable}
            onClick={() => actions.onDeviceNavigate(-1)}
          >
            <AddCircleOutline />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <DeviceMenuList
          {...props}
          onDeviceClick={actions.onDeviceNavigate}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default DeviceList;