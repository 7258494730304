import { Fragment, ReactElement, useEffect, useState } from "react";
import cn from 'classnames';
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  InputAdornment, Grid,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from "@material-ui/core/Tooltip";

import styles from './index.module.scss';
import ChildrenElement from "../../models/HelperModels/ChildrenElement";

export type MenuListItem = {
  id: number;
  name?: string;
  secondary?: string;
  icon?: ReactElement;
  children?: ChildrenElement;
}

export type MenuListProps = {
  items?: MenuListItem[];
  size?: 'small' | 'big'
  selectedItemId?: number | null;
  selectItem: (id: number) => void;
  disableSearch?: boolean;
  searchText?: string;
  classes?: {
    paper?: string,
    menuItem?: string,
  }
}

export function updateTextWithSearch(search: string, text?: string) {
  if (!search) {
    return text;
  }

  const searchLowerCase = search.toLowerCase();

  let result;

  let inputText = text || '';

  while (inputText.toLowerCase().includes(searchLowerCase)) {
    const index = inputText.toLowerCase().indexOf(searchLowerCase);
    const substring = inputText.substr(0, index);
    const bold = inputText.substr(index, search.length);

    result = <>{result}{substring}<b>{bold}</b></>;
    inputText = inputText.substr(index + search.length);
  }

  result = <>{result}{inputText}</>

  return result;
}

const MenuList = (props: MenuListProps) => {
  const [ search, setSearch ] = useState(props.searchText || '');
  const { items = [], selectedItemId, selectItem = () => {}, classes, size = 'small', disableSearch = false } = props;

  const filteredItems = search ? items.filter((item) => (item.name || '').toLowerCase().includes(search.toLowerCase())
    || (item.secondary || '').toLowerCase().includes(search.toLowerCase())) : items;

  useEffect(() => {
    setSearch(props.searchText || '');
  }, [ props.searchText ]);

  return (
    <Paper
      elevation={0}
      className={cn(styles.paper, classes?.paper)}
    >
      <List component='nav' className={styles.menuList}>
        {
          (items.length > 25 && !disableSearch) ? (
            <ListItem>
              <OutlinedInput
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                startAdornment={(
                  <InputAdornment position={"start"}>
                    <SearchIcon />
                  </InputAdornment>
                )}
              />
            </ListItem>
          ) : null
        }
        {
          filteredItems.map((menuItem) => (
              <Fragment
                key={menuItem.id}
              >
                <ListItem
                  className={cn(
                    styles.menuItem,
                    classes?.menuItem,
                    {
                      [styles.bigMenuItem]: size === 'big',
                      [styles.smallMenuItem]: size === 'small',
                    },
                  )}
                  button
                  selected={selectedItemId === menuItem.id}
                  onClick={() => {
                    selectItem(menuItem.id);
                  }}
                >
                  {
                    menuItem.icon && (
                      <ListItemIcon>
                        {
                          menuItem.icon
                        }
                      </ListItemIcon>
                    )
                  }
                  <Tooltip title={(menuItem.name || '') + ' ' + (menuItem.secondary || '')} enterDelay={1000}>
                    <ListItemText
                      primaryTypographyProps={{
                        className: styles.menuItemText,
                      }}
                      secondaryTypographyProps={{
                        className: styles.menuItemText,
                      }}
                      primary={updateTextWithSearch(search, menuItem.name)}
                      secondary={updateTextWithSearch(search, menuItem.secondary)}
                      classes={
                        {
                          root: styles.menuItemRoot,
                        }
                      }
                    >

                    </ListItemText>
                  </Tooltip>
                  <Grid item>
                    {
                      menuItem.children || null
                    }
                  </Grid>
                </ListItem>
              </Fragment>
            )
          )
        }
      </List>
    </Paper>
  )
}

export default MenuList;