import { useTypedDispatch } from "../../../app/state";
import { useSelector } from "react-redux";
import {
  selectCurrentTemplate,
  selectCurrentTemplateId,
  selectCurrentTemplateWirelessInterface,
} from "../../../store/templatesStore/templatesSelectors";
import { selectCurrentUser } from "../../../store/userStore/userSelectors";
import { selectCurrentBuildingId } from "../../../store/buildingStore/buildingsSelectors";
import {
  requestAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import HorizontalPanel from "../../../components/HorizontalPanel";
import WirelessInterfaceList from "../../DevicesPage/WirelessInterfaceList";
import { setCurrentTemplateWirelessInterfaceId } from "../../../store/templatesStore/templateState";
import WirelessInterfaceForm from "../../DevicesPage/WirelessInterfaceForm";
import { addWirelessInterfaceToTemplate } from "../../../store/templatesStore/templatesRequest";
import DeviceTemplateModel from "../../../models/DeviceTemplateModel";

const TemplatesRightPanel = () => {
  const dispatch = useTypedDispatch();
  const currentTemplateId = useSelector(selectCurrentTemplateId);
  const currentTemplate = useSelector(selectCurrentTemplate);
  const currentUser = useSelector(selectCurrentUser);
  const currentBuildingId = useSelector(selectCurrentBuildingId);
  const currentWirelessInterface = useSelector(selectCurrentTemplateWirelessInterface)

  const formDisabled = !currentTemplateId || currentTemplateId === -1 || currentUser?.id !== currentTemplate?.ownerId;

  const onWirelessInterfaceSelect = (id: number) => {
    dispatch(setCurrentTemplateWirelessInterfaceId(id));
  }

  const onAddWirelessInterface = (technologyConfigurationId: number) => {
    if (!currentTemplateId || !technologyConfigurationId) {
      return;
    }

    dispatch(addWirelessInterfaceToTemplate({ templateId: currentTemplateId, technologyConfigurationId }))
      .then((action) => {
        const template = action.payload as DeviceTemplateModel;

        const wirelessInterfaceList = (template.wirelessInterfaces || []).slice();
        const last = wirelessInterfaceList.pop();
        dispatch(setCurrentTemplateWirelessInterfaceId(last?.id));
      })
  }

  const onAfterDelete = () => {
    dispatch(setCurrentTemplateWirelessInterfaceId(null));
  }

  return (
    <GeneralRequest
      requestAction={requestAllTechnologyConfigurations}
      requestArguments={[ { buildingId: currentBuildingId } ]}
      loadingElement={null}
    >
      <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
        <WirelessInterfaceList
          wirelessInterfaceList={currentTemplate?.wirelessInterfaces || []}
          currentWirelessInterfaceId={currentWirelessInterface?.id}
          onWirelessInterfaceSelect={onWirelessInterfaceSelect}
          onAddWirelessInterface={onAddWirelessInterface}
          onAfterDelete={onAfterDelete}
          formDisabled={formDisabled}
        />
        {
          !!currentWirelessInterface && (
            <>
              <WirelessInterfaceForm
                currentWirelessInterface={currentWirelessInterface}
                formDisabled={formDisabled}
              />
            </>
          )
        }
      </HorizontalPanel>
    </GeneralRequest>
  );
}

export default TemplatesRightPanel;