import React, { useMemo } from "react";
import HorizontalPanel from "../../../components/HorizontalPanel";
import {
  useTypedDispatch
} from "../../../app/state";
import WirelessInterfaceBestPracticeBadge from "../../Shared/WirelessInterfaceBestPracticeBadge";
import { TableList } from "../../../app/components/TableList";
import { Toolbar } from "../../../app/components";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { AddWirelessInterfaceDialog } from "../DevicesMainPanel/dialogs/AddWirelessInterfaceDialog";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { deleteWirelessInterface } from "../../../store/deviceStore/deviceRequests";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import WirelessInterfaceModel from "../../../models/WirelessInterfaceModel";

interface WirelessInterfaceListProps {
  wirelessInterfaceList: WirelessInterfaceModel[],
  currentWirelessInterfaceId?: number | null,
  formDisabled?: boolean;
  showButtons?: boolean;
  onWirelessInterfaceSelect?: (id: number) => any,
  onAddWirelessInterface?: (id: number) => any,
  onAfterDelete?: () => any,
}

const WirelessInterfaceList = ({
  wirelessInterfaceList,
  currentWirelessInterfaceId,
  formDisabled,
  showButtons = true,
  onWirelessInterfaceSelect = () => {},
  onAddWirelessInterface = () => {},
  onAfterDelete = () => {},
}: WirelessInterfaceListProps) => {
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();

  const columns = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name' },
      { field: 'bestPractice', headerName: 'BP' },
    ];
  }, []);

  const rows = useMemo(() => {
    return wirelessInterfaceList.map((wirelessInterface) => {
      return {
        id: wirelessInterface.id,
        name: wirelessInterface?.technologyConfiguration?.name,
        bestPractice: <WirelessInterfaceBestPracticeBadge wirelessInterface={wirelessInterface} />
      }
    })
  }, [ wirelessInterfaceList ]);

  const onDelete = () => {
    if (!currentWirelessInterfaceId) {
      return;
    }

    dispatch(deleteWirelessInterface(currentWirelessInterfaceId))
      .then(() => {
        onAfterDelete();
      })
  }

  const onDeleteWirelessInterface = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Delete wireless interface',
      text: 'You are about to delete the selected wireless interface. Are you sure?',
      confirmButtonHandler: onDelete,
      confirmButtonText: "Delete",
    });
  }

  return (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Interfaces'>
          {
            showButtons ? (
              <>
                <IconButton
                  disabled={!!formDisabled || !currentWirelessInterfaceId}
                  aria-label='Delete wireless interface'
                  color='secondary'
                  size='small'
                  onClick={onDeleteWirelessInterface}
                >
                  <Delete />
                </IconButton>
                <AddWirelessInterfaceDialog
                  disabled={!!formDisabled}
                  onAdd={onAddWirelessInterface}
                  wirelessInterfaces={wirelessInterfaceList}
                />
              </>
            ) : null
          }
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <TableList
          columns={columns}
          rows={rows}
          selectRow={onWirelessInterfaceSelect}
          selectedId={currentWirelessInterfaceId}
          hideHeader={true}
        />
      </HorizontalPanel>
    </>
  )
}

export default WirelessInterfaceList;