import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getQueryObject } from "../index";
import { useTypedDispatch } from "../../app/state";
import { loginWithSSO } from "../../store/userStore/userRequests";
import { isUserLoggedIn } from "../../store/userStore/userSelectors";
import { stringToHtml } from "../../helpers/stringHelpers";
import { useSnackbar } from "../../app/shared/snackbar-context/snackbar.context";
import { selectGlobalSettings } from "../../store/settingsStore/settingsSelectors";

const SSOSuccessPage = () => {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const settings = useSelector(selectGlobalSettings);
  const loginStatus = useSelector(isUserLoggedIn);
  const urlProps = getQueryObject(window.location.hash.substr(1));
  const code = urlProps['access_token'] as string;

  useEffect(() => {
    if (loginStatus) {
      history.push("/locations");
      if (settings.systemMessage) {
        openSnackbar({
          html: stringToHtml(settings.systemMessage),
          timeout: 60000,
          title: "System message",
          severity: "warning",
        });
      }
    }
  }, [loginStatus]);

  useEffect(() => {
    if (code) {
      dispatch(loginWithSSO({ code }));
    }
  }, []);

  return null;
}

export default SSOSuccessPage;
