import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { useTypedDispatch } from "../../../app/state";
import { setEditMode } from "../../../store/generalStore/generalState";

import classes from './index.module.scss';
import { DeviceTypeEnum } from "../../../models/enums/DeviceType.enum";
import { setDrawingStatus } from "../../../store/drawingStore/drawingState";
import StationaryPoint from "../../../containers/DeviceMap/MapPoint/StationaryPoint";
import PortablePoint from "../../../containers/DeviceMap/MapPoint/PortablePoint";

export const DevicePosition = (props) => {
  const dispatch = useTypedDispatch();

  const { formDisabled } = props;
  const selectedDevice = useSelector(selectCurrentDevice);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || selectedDevice?.configuration;
  const isDeviceStationary = currentConfiguration?.type === DeviceTypeEnum.STATIONARY;

  const latitude = currentConfiguration?.mapPoint?.geoPoint?.latitude;
  const longitude = currentConfiguration?.mapPoint?.geoPoint?.longitude;
  const geoPointExists = !!latitude && !!longitude;

  useEffect(() => {
    dispatch(setEditMode(false));
    dispatch(setDrawingStatus(false));
  }, [])

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Device Position' />
      </HorizontalPanel>
      <HorizontalPanel
        size={HorizontalPanel.SIZES.REST_SPACE}
        className={classes.container}
      >
        {
          geoPointExists && isDeviceStationary && (
            <Grid container spacing={2} className={classes.coordinatesBlock}>
              <Grid item xs={12}>
                <TextField
                  label='Latitude'
                  required
                  variant='outlined'
                  name='latitude'
                  fullWidth
                  disabled
                  value={latitude}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Longitude'
                  required
                  variant='outlined'
                  name='longitude'
                  fullWidth
                  disabled
                  value={longitude}
                />
              </Grid>
            </Grid>
          )
        }
        {
          isDeviceStationary ? (
            <StationaryPoint formDisabled={formDisabled} />
          ) : (
            <PortablePoint formDisabled={formDisabled} />
          )
        }
      </HorizontalPanel>
    </HorizontalPanel>
  );
}
