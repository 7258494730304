import {
  FilledInput,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _, { get } from 'lodash';
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";

import styles from './index.module.scss';
import { updateTextWithSearch } from "../../components/MenuList";

type ColumnProps = {
  maxLength?: number;
} & GridColDef;

interface TableListProps {
  columns: ColumnProps[];
  newItem?: boolean;
  rows: any[];
  selectedId?: number | null;
  hideHeader?: boolean | undefined;
  selectRow?: (id: number) => any;
  disableSearch?: boolean;
  classes?: {
    row: string;
  }
}

export const TableList = ({
  columns,
  newItem,
  rows,
  selectedId = null,
  hideHeader,
  selectRow = () => {},
  classes,
  disableSearch = false,
}: TableListProps) => {
  const [ search, setSearch ] = useState('');
  const { t } = useTranslation();
  const classNameMap = useMemo(() => ({ root: classes?.row }), [ classes ]);
  const newItemRow = useMemo(() => {
    if (newItem) {
      return (
        <TableRow classes={classNameMap} tabIndex={-1} selected>
          {columns.map((column, i) => (
            <TableCell key={column.field}>
              {i === 0 && t("table_new_item")}
            </TableCell>
          ))}
        </TableRow>
      );
    }
  }, [ newItem, columns ]);

  const onRowSelected = useCallback(
    (id?: number) => {
      if (id) {
        selectRow(id);
      }
    },
    [ selectRow ]
  );

  const [ sortingKey, setSortingKey ] = useState();
  const [ sortingDirection, setSortingDirection ] = useState<'asc' | 'desc'>('asc');

  const setSorting = useCallback(
    (column) => {
      if (column.sortable) {
        setSortingKey(column.field);
        setSortingDirection(sortingDirection === 'asc' ? 'desc' : 'asc');
      }
    },
    [ columns, sortingDirection ]
  );

  const searchedRows = search ? rows.filter((row) => {
    return columns.reduce((previousValue, currentValue) => {
      const value = row[currentValue.field];
      if (typeof value === 'string') {
        return previousValue || value.toLowerCase().includes(search.toLowerCase());
      }

      if (typeof value === 'number') {
        return previousValue || value.toString().includes(search.toLowerCase());
      }

      return previousValue;
    }, false)
  }) : rows;

  return (
    <Table size="small">
      {
        !hideHeader &&
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              const { field, sortable, headerName, width } = column;
              return (
                <TableCell
                  key={field}
                  onClick={() => setSorting(column)}
                  style={{
                    cursor: sortable ? 'pointer' : 'unset'
                  }}
                  width={width}
                >
                  {headerName}
                  {
                    sortingKey === field &&
                    <span
                      style={{
                        margin: '0 5px',
                        fontWeight: 700
                      }}
                    >
                    {
                      sortingDirection === 'asc' ? '↑' : '↓'
                    }
                  </span>
                  }
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
      }
      <TableBody>
        {
          rows.length > 25 && !disableSearch ? (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className={styles.searchTableRow}
              >
                <FilledInput
                  fullWidth
                  color="primary"
                  value={search}
                  inputProps={{
                    className: styles.searchInput,
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                  startAdornment={(
                    <InputAdornment position={"start"}>
                      <SearchIcon />
                    </InputAdornment>
                  )}
                />
              </TableCell>
            </TableRow>
          ) : null
        }
        {newItemRow}
        {
          _.orderBy(searchedRows, [ sortingKey ], [ sortingDirection ]).map((row) => {
            return (
              <TableRow
                classes={classNameMap}
                tabIndex={-1}
                key={row.id}
                selected={selectedId === row.id}
                onClick={() => {
                  onRowSelected(row.id);
                }}
              >
                {columns.map((column) => {
                  const { align, maxLength } = column;
                  const cellValue = get(row, column.field);
                  const value = typeof cellValue === 'function' ? cellValue() : cellValue;

                  if (![ 'string', 'number' ].includes(typeof value)) {
                    return (
                      <TableCell key={column.field} align={align}>
                        {value}
                      </TableCell>
                    )
                  }

                  if (maxLength && value.length > maxLength) {
                    return (
                      <Tooltip title={value} key={column.field}>
                        <TableCell align={align}>
                          {updateTextWithSearch(search, value.toString().slice(0, maxLength - 3))}...
                        </TableCell>
                      </Tooltip>
                    )
                  } else {
                    return (
                      <TableCell key={column.field} align={align}>
                        {updateTextWithSearch(search, value.toString())}
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            );
          })
        }
      </TableBody>
    </Table>
  );
};
