import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import DrawingButton from "../../../Drawing/DrawingButton";
import DrawingInfo from "../../../Drawing/DrawingInfo";
import { useTypedDispatch } from "../../../../app/state";
import { useSelector } from "react-redux";
import { selectCurrentDevice } from "../../../../store/deviceStore/deviceSelectors";
import { selectCurrentConfiguration } from "../../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import { clearAllPoints, setDrawingStatus } from "../../../../store/drawingStore/drawingState";
import { selectDrawingPoints, selectDrawingStatus } from "../../../../store/drawingStore/drawingSelectors";
import { deleteDevicePath } from "../../../../store/deviceStore/deviceRequests";
import { DeviceTypeEnum } from "../../../../models/enums/DeviceType.enum";

import classes from "../index.module.scss";

const PortablePoint = ({ formDisabled }) => {
  const dispatch = useTypedDispatch();
  const isDrawing = useSelector(selectDrawingStatus);
  const pointList = useSelector(selectDrawingPoints);
  const selectedDevice = useSelector(selectCurrentDevice);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || selectedDevice?.configuration;
  const isDeviceSetOnMap = () => Boolean(currentConfiguration?.mapPathId);
  const isChangePointButtonEnabled = () => !formDisabled && !!selectedDevice;
  const isAnythingDrawn = pointList.length > 0;

  const removeDeviceFromMap = () => {
    if (selectedDevice) {
      dispatch(setDrawingStatus(false));
      dispatch(clearAllPoints());
      dispatch(deleteDevicePath(selectedDevice.id))
    }
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
      >
        {
          isDeviceSetOnMap() ?
            (
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <Typography
                    variant='subtitle1'
                    className={classes.line}
                  >
                    {selectedDevice?.configuration.type === DeviceTypeEnum.PORTABLE ? 'Portable devices on map' : 'Grouped devices on map'}
                  </Typography>
                </Grid>
                <IconButton
                  aria-label='remove device from map'
                  color='secondary'
                  size='small'
                  disabled={!isChangePointButtonEnabled()}
                  onClick={removeDeviceFromMap}
                >
                  <Delete />
                </IconButton>
              </Grid>
            )
            :
            (
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item><Typography variant='subtitle1' className={classes.line}>Start drawing</Typography></Grid>
                <DrawingButton
                  disabled={!isChangePointButtonEnabled()}
                  title="set device on map"
                />
              </Grid>
            )
        }
      </Grid>
      {
        (isDrawing || isAnythingDrawn) ? (
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='subtitle1' className={classes.line}>Drawing info</Typography>
            </Grid>
            <DrawingInfo />
          </Grid>
        ) : null
      }
    </>
  )
}

export default PortablePoint;