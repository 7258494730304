import { uniq } from "lodash/fp";
import { Wifi } from "@material-ui/icons";
import DeviceIcon from "../../components/DeviceIcon/DeviceIcon";
import useDevicesPage from "../DevicesPage/devicePageHooks";
import { useSelector } from "react-redux";
import {
  selectCurrentDeviceId,
  selectDevicesByMapId,
} from "../../store/deviceStore/deviceSelectors";
import { selectWirelessSystemsByIdList } from "../../store/wirelessSystemStore/wirelessSystemSelectors";
import useFilter from "../../hooks/filter.hook";
import CollapsibleMenuList from "../../components/CollapsibleMenuList";

interface DeviceMenuListProps {
  onDeviceClick: (deviceId: number) => any,
  isOpened?: boolean,
  showFiltered?: boolean,
}

const DeviceMenuList = ({ onDeviceClick, isOpened = true, showFiltered = true }: DeviceMenuListProps) => {
  const { state } = useDevicesPage();
  const { currentMapId } = state;
  const filter = useFilter();
  const currentPlaceDevices = useSelector(selectDevicesByMapId(currentMapId));
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const deviceListToShow = showFiltered ? filter.actions.filterDeviceList(currentPlaceDevices) : currentPlaceDevices;
  const availableWirelessSystemIdList = uniq(deviceListToShow.map((device) => device.configuration.wirelessSystemId));
  const availableWirelessSystems = useSelector(selectWirelessSystemsByIdList(availableWirelessSystemIdList));

  return (
    <CollapsibleMenuList
      isOpened={isOpened}
      selectItem={onDeviceClick}
      selectedItemId={currentDeviceId}
      groups={availableWirelessSystems
        .map((wirelessSystem) => {
          return {
            icon: <Wifi />,
            listTitle: wirelessSystem.name,
            items: deviceListToShow.filter((device) => {
              return wirelessSystem.id === device?.configuration?.wirelessSystemId
            }).map((device) => {
              return {
                secondary: device.name,
                id: device.id,
                icon: <DeviceIcon deviceConfiguration={device?.configuration} />,
              }
            })
          }
        })}
    />
  );
}

export default DeviceMenuList;