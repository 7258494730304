import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { Dropdown } from "../../../app/components/Dropdown";
import { selectCountriesByRegionId, selectCurrentCountryId } from "../../../store/countryStore/countrySelectors";
import { selectCurrentRegionId } from "../../../store/regionStore/regionSelectors";
import useNavigator from "../../../hooks/navigator.hook";

const CountryFilter = () => {
  const { navigateToLocationPage } = useNavigator();
  const currentRegionId = useSelector(selectCurrentRegionId);
  const currentCountryId = useSelector(selectCurrentCountryId);
  const countryList = useSelector(selectCountriesByRegionId(currentRegionId)) || [];
  const selectedCountryInRegion = currentCountryId && countryList.find((country) => country.id === currentCountryId);
  const selectedValue = currentCountryId && selectedCountryInRegion ? currentCountryId : 0;

  const onChange = (e) => {
    const countryId = e.target.value || null;
    navigateToLocationPage({ countryId }, true);
  }

  useEffect(() => {
    const isThereSelectedCountry = currentCountryId && selectedCountryInRegion;

    if (currentCountryId && !isThereSelectedCountry) {
      navigateToLocationPage({ countryId: null }, true);
    }
  }, [currentRegionId, selectedCountryInRegion, currentCountryId])

  return (
    <Grid item>
      <Dropdown
        useDefaultItem
        selectedValue={selectedValue}
        values={countryList}
        label='Country'
        name='country-filter'
        fullWidth
        valueChange={onChange}
      />
    </Grid>
  );
}

export default CountryFilter;