import { Grid, IconButton } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import RegionFilter from "../RegionFilter";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import CountryFilter from "../CountryFilter";
import useNavigator from "../../../hooks/navigator.hook";
import { useSelector } from "react-redux";
import { selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";

import classes from './index.module.scss';

const RegionAndLocationFilters = () => {
  const { navigateToLocationPage } = useNavigator();
  const currentLocationId = useSelector(selectCurrentLocationId);
  const onCreateNewLocation = () => {
    navigateToLocationPage({ locationId: -1 })
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='List of locations'>
          <CheckPermission permissionName={PERMISSIONS.LOCATION_ACTIONS} noPermissionComponent={null}>
            <IconButton
              color='secondary'
              size='small'
              onClick={onCreateNewLocation}
              disabled={currentLocationId === -1}
            >
              <AddCircleOutline />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <Grid
          container
          direction="column"
          component="form"
          spacing={2}
          className={classes.form}
        >
          <RegionFilter />
          <CountryFilter />
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default RegionAndLocationFilters;