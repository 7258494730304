import { useState } from "react";
import BulkSelectDevicesList from "../BulkSelectDevicesList";
import { ThreeColumnLayout } from "../../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import BulkOperations from "../BulkOperations";
import { useTypedDispatch } from "../../../app/state";
import {
  bulkApprove,
  bulkApproveValidation, bulkReject,
  bulkRejectValidation,
  bulkSubmit,
  bulkSubmitValidation
} from "../../../store/deviceStore/deviceRequests";
import BulkInvalidDeviceList from "../BulkInvalidDeviceList";
import BulkOperationDetails from "../BulkOperationDetails";
import BulkConfirmation from "../BulkConfirmation";
import useNavigator from "../../../hooks/navigator.hook";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { ModalType } from "../../../app/shared/modal-context/constants";
import BulkLeftMenuSubmit from "../BulkLeftMenu/BulkLeftMenuSubmit";
import BulkLeftMenuApprove from "../BulkLeftMenu/BulkLeftMenuApprove";
import BulkLeftMenuReject from "../BulkLeftMenu/BulkLeftMenuReject";

const BulkEditSteps = () => {
  const dispatch = useTypedDispatch();
  const { showModal } = useShowModal();
  const { navigateToSearchPage } = useNavigator();
  const [ currentStep, setStep ] = useState(0);
  const [ selectedDeviceIdList, setSelectedDevices ] = useState([] as number[]);
  const [ invalidDeviceIdList, setInvalidIdList ] = useState([] as number[]);
  const [ currentOperation, setOperation ] = useState('submit');
  const [ operationText, setOperationText ] = useState('');
  const { openSnackbar } = useSnackbar();

  const stepBack = () => {
    if (currentStep > 0) {
      if (currentStep === 3) {
        setStep(currentStep - 2);
      } else {
        setStep(currentStep - 1);
      }
    }
  }

  const validateSubmit = async () => {
    const action = await dispatch(bulkSubmitValidation({ deviceIdList: selectedDeviceIdList }))
    const response = action.payload as { deviceIdList: number[] };

    return response?.deviceIdList || [];
  }

  const validateApprove = async () => {
    const action = await dispatch(bulkApproveValidation({ deviceIdList: selectedDeviceIdList }))
    const response = action.payload as { deviceIdList: number[] };

    return response?.deviceIdList || [];
  }

  const validateReject = async () => {
    const action = await dispatch(bulkRejectValidation({ deviceIdList: selectedDeviceIdList }))
    const response = action.payload as { deviceIdList: number[] };

    return response?.deviceIdList || [];
  }

  const validate = async () => {
    let invalidDeviceIdList: number[] = [];

    if (currentOperation === 'submit') {
      invalidDeviceIdList = await validateSubmit();
    }

    if (currentOperation === 'approve') {
      invalidDeviceIdList = await validateApprove();
    }

    if (currentOperation === 'reject') {
      invalidDeviceIdList = await validateReject();
    }

    setInvalidIdList(invalidDeviceIdList);
    return invalidDeviceIdList.length > 0;
  }

  const removeInvalidDevices = () => {
    return selectedDeviceIdList.filter((deviceId) => !invalidDeviceIdList.includes(deviceId));
  }

  const onSubmit = async () => {
    const action = await dispatch(bulkSubmit({ deviceIdList: selectedDeviceIdList, operationText }));
    const payload = action.payload as { invalidDeviceIdList: number[], tagModelId?: number };
    const { invalidDeviceIdList, tagModelId } = payload;

    if (invalidDeviceIdList.length) {
      // failed to submit
      openSnackbar({
        title: 'Validation error',
        message: 'Failed to submit due to re-validation. Please check invalid devices',
        severity: 'error',
      });

      setInvalidIdList(invalidDeviceIdList);
      setStep(2);

      return;
    }

    openSnackbar({
      title: 'Operation was successful',
      message: `${selectedDeviceIdList.length} devices were successfully submitted`,
      severity: 'success',
    });

    return tagModelId;
  }

  const onApprove = async () => {
    const action = await dispatch(bulkApprove({ deviceIdList: selectedDeviceIdList, operationText }));
    const payload = action.payload as { invalidDeviceIdList: number[], tagModelId?: number };
    const { invalidDeviceIdList, tagModelId } = payload;

    if (invalidDeviceIdList.length) {
      // failed to submit
      openSnackbar({
        title: 'Validation error',
        message: 'Failed to approve due to re-validation. Please check invalid devices',
        severity: 'error',
      });

      setInvalidIdList(invalidDeviceIdList);
      setStep(2);

      return;
    }

    openSnackbar({
      title: 'Operation was successful',
      message: `${selectedDeviceIdList.length} devices were successfully approved`,
      severity: 'success',
    });

    return tagModelId;
  }

  const onReject = async () => {
    const action = await dispatch(bulkReject({ deviceIdList: selectedDeviceIdList, operationText }));
    const payload = action.payload as { invalidDeviceIdList: number[], tagModelId?: number };
    const { invalidDeviceIdList, tagModelId } = payload;

    if (invalidDeviceIdList.length) {
      // failed to submit
      openSnackbar({
        title: 'Validation error',
        message: 'Failed to reject due to re-validation. Please check invalid devices',
        severity: 'error',
      });

      setInvalidIdList(invalidDeviceIdList);
      setStep(2);

      return;
    }

    openSnackbar({
      title: 'Operation was successful',
      message: `${selectedDeviceIdList.length} devices were successfully rejected`,
      severity: 'success',
    });

    return tagModelId;
  }

  const onFinish = async () => {
    let tagModelId: number | undefined;

    if (currentOperation === 'submit') {
      tagModelId = await onSubmit();
    }

    if (currentOperation === 'approve') {
      tagModelId = await onApprove();
    }

    if (currentOperation === 'reject') {
      tagModelId = await onReject();
    }

    if (tagModelId) {
      navigateToSearchPage({ tags: [ tagModelId ] });
    }
  }

  const onNextStep = async () => {
    if (currentStep === 0) {
      if (selectedDeviceIdList.length === 0) {
        openSnackbar({
          title: 'Validation error',
          message: 'At least one device should be selected',
          severity: 'error',
        });

        return;
      }

      setStep(currentStep + 1);
    }

    if (currentStep === 1) {
      const validationResult = await validate();
      if (!validationResult) {
        setStep(currentStep + 2);
      } else {
        setStep(currentStep + 1);
      }
    }

    if (currentStep === 2) {
      const validationResult = await validate();

      if (!validationResult) {
        setStep(currentStep + 1);
        return;
      }

      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Invalid devices',
        text: `${invalidDeviceIdList.length} Devices that are not valid for the operation will be removed from the list. Do you want to proceed?`,
        confirmButtonHandler: () => {
          const newDevicesList = removeInvalidDevices();

          if (newDevicesList.length === 0) {
            openSnackbar({
              title: 'Validation error',
              message: 'No devices will be left in the list after validation, should be at least one valid device',
              severity: 'error',
            });
          } else {
            setSelectedDevices(newDevicesList);
            setStep(currentStep + 1);
          }
        },
        confirmButtonText: 'Proceed',
      });

      return;
    }

    if (currentStep === 3) {
      setStep(currentStep + 1);
    }

    if (currentStep === 4) {
      await onFinish();
    }
  }

  const onOperationSet = (event) => {
    setOperation(event.target.value);
  }

  const onOperationTextUpdate = (event) => {
    setOperationText(event.target.value);
  }

  let centralPanelContent = (
    <BulkSelectDevicesList
      onSelect={setSelectedDevices}
      selectedDeviceIdList={selectedDeviceIdList}
    />
  );

  let leftMenu = (
    <BulkLeftMenuSubmit step={currentStep} onNextStep={onNextStep} onPreviousStep={stepBack} />
  );

  if (currentOperation === 'approve') {
    leftMenu = <BulkLeftMenuApprove step={currentStep} onNextStep={onNextStep} onPreviousStep={stepBack} />
  }

  if (currentOperation === 'reject') {
    leftMenu = <BulkLeftMenuReject step={currentStep} onNextStep={onNextStep} onPreviousStep={stepBack} />
  }

  if (currentStep === 1) {
    centralPanelContent = (
      <BulkOperations selectedOperation={currentOperation} onOperationSet={onOperationSet} />
    )
  }

  if (currentStep === 2) {
    centralPanelContent = (
      <BulkInvalidDeviceList
        deviceIdList={invalidDeviceIdList}
        actionName={currentOperation}
      />
    )
  }

  if (currentStep === 3) {
    centralPanelContent = <BulkOperationDetails onUpdate={onOperationTextUpdate} operationText={operationText} />
  }

  if (currentStep === 4) {
    const action = currentOperation === 'submit' ? 'submitted' : currentOperation === 'approve' ? 'approved' : 'rejected';
    centralPanelContent = (
      <BulkConfirmation
        deviceIdList={selectedDeviceIdList}
        actionName={action}
      />
    )
  }

  return (
    <ThreeColumnLayout
      leftSideBar={leftMenu}
      centerMainPanel={centralPanelContent}
    />
  )
}

export default BulkEditSteps;