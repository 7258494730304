import React from 'react';
import cn from 'classnames';
import { ListItem, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuList, { MenuListItem } from "../MenuList";

import styles from './CollapsableListItem.module.scss';

export interface CollapsableListItemProps {
  listTitle: string;
  items?: MenuListItem[];
  selectItem: (id: number) => void;
  selectedItemId?: number | null;
  icon?: React.ReactElement | null;
  noBorderRadius?: boolean;
  searchText?: string;
  isOpened?: boolean;
}

export const CollapsableListItem = (props: CollapsableListItemProps) => {
  const { listTitle, items, selectItem, selectedItemId, icon, isOpened = true, noBorderRadius = false, searchText } = props;
  const [ open, setOpen ] = React.useState(isOpened);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        className={styles.title}
      >
        {
          icon && (
            <ListItemIcon>
              {
                icon
              }
            </ListItemIcon>
          )
        }
        <ListItemText
          primary={listTitle}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={
          cn(styles.menu, {
            [styles.noBorderRadius]: noBorderRadius,
            [styles.borderRadius]: !noBorderRadius,
          })
        }
      >
        <MenuList
          items={items}
          selectItem={selectItem}
          selectedItemId={selectedItemId}
          searchText={searchText}
          disableSearch
          classes={{
            menuItem: styles.listItem,
          }}
        />
      </Collapse>
    </>
  )
}