import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import { selectCurrentDeviceId } from "../../../store/deviceStore/deviceSelectors";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { CheckPermission } from "../../../requests/CheckPermission";
import { useTypedDispatch } from "../../../app/state";
import { createTemplateFromDevice } from "../../../store/templatesStore/templatesRequest";
import useNavigator from "../../../hooks/navigator.hook";
import DeviceTemplateModel from "../../../models/DeviceTemplateModel";

export const CreateTemplateButton = () => {
  const dispatch = useTypedDispatch();
  const navigator = useNavigator();
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const { showModal } = useShowModal();

  const onCreateTemplate = () => {
    dispatch(createTemplateFromDevice(currentDeviceId))
      .then((action) => {
        const template = action.payload as DeviceTemplateModel

        if (template) {
          navigator.navigateToTemplatesPage({ templateId: template.id })
        }
      })
  }

  const onClick = () => {
    showModal(
      ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Create template',
        text: 'You are about to create a template from this device. Are use sure?',
        confirmButtonHandler: onCreateTemplate,
        confirmButtonText: 'Create',
      }
    )
  };

  return (
    <CheckPermission permissionName={PERMISSIONS.TEMPLATE_ACTIONS} noPermissionComponent={null}>
      <IconButton
        disabled={!currentDeviceId || currentDeviceId === -1}
        aria-label='Create template from device'
        color='secondary'
        size='small'
        onClick={onClick}
        title='Create template from device'
      >
        <NoteAddIcon />
      </IconButton>
    </CheckPermission>
  )
}

