import { useEffect } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { selectAllDevices } from "../../../store/deviceStore/deviceSelectors";
import { getQueryObject } from "../../../pages";
import { filterDeviceIdList, isTheSameUrl } from "../../../helpers/urlChecker";
import DeviceTable from "../../../components/DeviceTable";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import classes from "./index.module.scss";
import useNavigator from "../../../hooks/navigator.hook";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";

const BulkDevicesList = ({
  selectedDeviceIdList,
  onSelect
}: { selectedDeviceIdList: number[], onSelect: (idList: number[]) => void }) => {
  const { navigate, goBack } = useNavigator();
  const searchObject = getQueryObject();
  const { deviceIdList } = searchObject;
  const deviceAllList = useSelector(selectAllDevices);
  const deviceList = filterDeviceIdList(deviceAllList, deviceIdList);
  const title = selectedDeviceIdList.length === 0 ? `Select devices` : `${selectedDeviceIdList.length} of ${deviceList?.length} devices selected`;

  useEffect(() => {
    if (selectedDeviceIdList.length === 0) {
      const deviceIdList = (deviceList || []).map((device) => device.id);
      onSelect(deviceIdList);
      const url = pagePathGenerator.BULK_EDIT_PAGE({ deviceIdList });

      if (deviceIdList.length > 0 && !isTheSameUrl(url)) {
        navigate(url, true);
      }

      if (deviceIdList.length === 0) {
        goBack();
      }
    }
  }, []);

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} className={classes.container}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={title} />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.container}>
        <Grid container className={cn(classes.tableContainer, {
          [classes.width4K]: !!deviceList,
        })}>
          <DeviceTable
            showCheckboxes
            onSelect={onSelect}
            selectedDeviceIdList={selectedDeviceIdList}
            deviceList={deviceList || []}
          />
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BulkDevicesList;