import { createSlice } from "@reduxjs/toolkit";

type DrawingPoint = {
  x: number,
  y: number,
}

type DrawingState = {
  isDrawing: boolean,
  points: DrawingPoint[],
}

const initialState: DrawingState = {
  isDrawing: false,
  points: [],
}

export const drawingSlice = createSlice({
  name: 'drawingSlice',
  initialState,
  reducers: {
    addDrawingPoint: (state, action) => {
      if (state.isDrawing) {
        state.points = [ ...state.points, action.payload ];
      }
    },
    removeLastDrawingPoint: (state) => {
      state.points = state.points.slice(0, -1);
    },
    clearAllPoints: (state) => {
      state.points = [];
    },
    setDrawingStatus: (state, action) => {
      state.isDrawing = action.payload;
    }
  }
});

export const { addDrawingPoint, removeLastDrawingPoint, clearAllPoints, setDrawingStatus } = drawingSlice.actions;