import { serializeError } from 'app/shared';
import axios, { AxiosResponse } from 'axios';
import { getOr } from 'lodash/fp';
import { redirectToLogin } from "../../routes/pagePathGenerator";

const { REACT_APP_API_URL, REACT_APP_API_PORT } = process.env;

const apiUrl = REACT_APP_API_PORT ? `${REACT_APP_API_URL}:${REACT_APP_API_PORT}` : `${REACT_APP_API_URL}`;
const baseURL = apiUrl + '/api';

export const request = axios.create({
  baseURL,
});

request.interceptors.response.use(
  (response: AxiosResponse) => getOr({}, 'data.data', response),
  (error) => {
    if (error.response.data.message === 'Token expired') {
      localStorage.removeItem('token');
      redirectToLogin();
    }
    return Promise.reject(error)
  }
);

export const editWirelessInterface = async (wirelessInterface) => {
  try {
    await request.put('/wireless-interface', wirelessInterface);
  } catch (error) {
    throw serializeError(error);
  }
};

export const deleteWirelessInterface = async (id: number) => {
  try {
    await request.delete('/wireless-interface', { data: { id } });
  } catch (error) {
    throw serializeError(error);
  }
};
