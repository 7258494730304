import { useState } from "react";
import { useSelector } from "react-redux";
import { selectDrawingPoints, selectDrawingStatus } from "../../../store/drawingStore/drawingSelectors";
import { store, useTypedDispatch } from "../../../app/state";
import AddPropsToChildren from "../../../components/AddPropsToChildren";
import ChildrenElement from "../../../models/HelperModels/ChildrenElement";
import {
  addDrawingPoint,
  drawingSlice,
  removeLastDrawingPoint,
} from "../../../store/drawingStore/drawingState";
import { keyboardSlice } from "../../../store/keyboardStore/keyboardState";
import {
  selectIsCTRLDown,
  selectIsShiftDown,
} from "../../../store/keyboardStore/keyboardSelectors";
import { checkFigureCrossing } from "../../../helpers/geometry";
import { createPathFromPoints } from "../../../helpers/svg";

type MapDrawerProps = {
  children: ChildrenElement,
  mapChildren?: ChildrenElement
}

const MapDrawer = ({ children, mapChildren, ...props }: MapDrawerProps) => {
  const [ movedPoint, setMouseMovePoint ] = useState({ x: 0, y: 0 });
  const [ scale, setScale ] = useState(1);
  const dispatch = useTypedDispatch();
  const pointList = useSelector(selectDrawingPoints);
  const isShiftDown = useSelector(selectIsShiftDown);
  const isCTRLDown = useSelector(selectIsCTRLDown);
  const isDrawing = useSelector(selectDrawingStatus);
  const pointsDrawingStated = pointList.length > 0;

  const polygon = isDrawing ? createPathFromPoints([ ...pointList, movedPoint ], isShiftDown, isCTRLDown) : createPathFromPoints([ ...pointList ]);
  const areInvalid = isDrawing ? checkFigureCrossing([ ...pointList, {
    x: isCTRLDown && pointsDrawingStated ? pointList[pointList.length - 1].x : movedPoint.x,
    y: isShiftDown && pointsDrawingStated ? pointList[pointList.length - 1].y : movedPoint.y,
  } ]) : checkFigureCrossing([ ...pointList ]);

  const onClickHandler = (x, y) => {
    const root = store.getState();
    const keyboardState = root[keyboardSlice.name];
    const drawingState = root[drawingSlice.name];
    const pointList = drawingState.points;
    const lastPoint = pointList[pointList.length - 1];
    const isShiftDown = keyboardState.shift;
    const isControlDown = keyboardState.control;

    if (lastPoint && isShiftDown) {
      dispatch(addDrawingPoint({ x, y: lastPoint.y }));
      return;
    }
    if (lastPoint && isControlDown) {
      dispatch(addDrawingPoint({ x: lastPoint.x, y }));
      return;
    }
    dispatch(addDrawingPoint({ x, y }));
  }

  const onMouseMove = (x, y) => {
    setMouseMovePoint({ x, y });
  }

  const onRemovePoint = () => {
    dispatch(removeLastDrawingPoint());
  }

  const onZoom = (newScale) => {
    setScale(newScale);
  }

  const extraProps = {
    onContextMenu: [ onRemovePoint ],
    onClick: [ onClickHandler ],
    onMouseMove: onMouseMove,
    onZoomHandlers: [ onZoom ],
    cursor: isDrawing ? 'crosshair' : undefined,
    mapChildren: (
      <>
        {mapChildren}
        {
          <path d={polygon} fill="transparent" stroke={areInvalid ? "#B00020" : "#991BFA"}
                strokeWidth={(isDrawing ? 2 : 1) / scale} />
        }
      </>
    )
  }

  return (
    <AddPropsToChildren
      extraProps={extraProps}
      {...props}
    >
      {children}
    </AddPropsToChildren>
  )
}

export default MapDrawer;