import { createAsyncThunk } from "@reduxjs/toolkit";
import DeviceTagModel from "../../models/DeviceTagModel";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";

const SLICE_NAME = 'device-tags';

export const getAllDeviceTags = createAsyncThunk(
  `${SLICE_NAME}/all`,
  async (_, { rejectWithValue }) => {
    try {
      const data: { deviceTags: DeviceTagModel[] }  = await request.get('/device/tags/all');
      return data.deviceTags;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);