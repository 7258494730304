import { ThreeColumnLayout } from "../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import LocationLeftPanel from "../../containers/LocationPage/LocationLeftPanel";
import { setCurrentLocationId } from "../../store/locationStore/locationState";
import { setCurrentRegionId } from "../../store/regionStore/regionState";
import { GeneralRequest } from "../../requests/GeneralRequest";
import { getAllRegions } from "../../store/regionStore/regionRequests";
import { setCurrentCountryId } from "../../store/countryStore/countryState";
import PageUpdater from "../../containers/PageUpdater";
import LocationCentralPanel from "../../containers/LocationPage/LocationCentralPanel";
import { getAllFloors } from "../../store/floorStore/floorRequests";
import LocationRightPanel from "../../containers/LocationPage/LocationRightPanel";
import { requestLocationByQuery } from "../../store/locationStore/locationRequests";

const LocationPage = () => {
  return (
    <>
      <PageUpdater watchItemList={[
        {
          title: 'regionId',
          action: setCurrentRegionId,
        },
        {
          title: 'countryId',
          action: setCurrentCountryId,
        },
        {
          title: 'locationId',
          action: setCurrentLocationId,
        },
      ]} />
      <GeneralRequest requestAction={getAllRegions}>
        <GeneralRequest requestAction={getAllFloors}>
          <GeneralRequest requestAction={requestLocationByQuery}>
            <ThreeColumnLayout
              leftSideBar={<LocationLeftPanel />}
              centerMainPanel={<LocationCentralPanel />}
              rightSideBar={<LocationRightPanel />}
            />
          </GeneralRequest>
        </GeneralRequest>
      </GeneralRequest>
    </>
  );
}

export default LocationPage;