import { RootState } from "../../app/state";
import { createSelector } from "@reduxjs/toolkit";
import { CTRL_KEYCODE, ENTER_KEYCODE, ESC_KEYCODE, KEY_MAP, SHIFT_KEYCODE } from "./keyMap";

export const selectKeyboardSlice = (state: RootState) => state.keyboardState;

export const selectIsEscDown = createSelector(
  selectKeyboardSlice,
  (state) => state[KEY_MAP[ESC_KEYCODE]]
);

export const selectIsEnterDown = createSelector(
  selectKeyboardSlice,
  (state) => state[KEY_MAP[ENTER_KEYCODE]]
);

export const selectIsShiftDown = createSelector(
  selectKeyboardSlice,
  (state) => state[KEY_MAP[SHIFT_KEYCODE]]
);

export const selectIsCTRLDown = createSelector(
  selectKeyboardSlice,
  (state) => state[KEY_MAP[CTRL_KEYCODE]]
);