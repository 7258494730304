import { useEffect } from "react";
import { useSelector } from "react-redux";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import MenuListWithImage from "../../../components/MenuListWithImage";
import {
  selectBuildingsByLocationId,
  selectCurrentBuilding,
  selectCurrentBuildingId,
} from "../../../store/buildingStore/buildingsSelectors";
import { selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";
import useNavigator from "../../../hooks/navigator.hook";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { IconButton } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { CheckPermission } from "../../../requests/CheckPermission";

const BuildingsList = () => {
  const { navigateToLocationPage, navigateToBuildingPage } = useNavigator();
  const currentLocationId = useSelector(selectCurrentLocationId)
  const selectedBuildingId = useSelector(selectCurrentBuildingId);
  const selectedBuilding = useSelector(selectCurrentBuilding);
  const allBuildings = useSelector(selectBuildingsByLocationId(currentLocationId));

  const selectBuildingHandler = (buildingId?: number, shouldReplace = false) => {
    navigateToBuildingPage({ buildingId }, shouldReplace);
  }

  useEffect(() => {
    if (selectedBuildingId === -1) {
      return;
    }
    // if wrong building id is in url
    if (!selectedBuildingId || (selectedBuildingId && !selectedBuilding)) {
      const first = allBuildings[0];
      if (first) {
        selectBuildingHandler(first.id, true);
      } else {
        navigateToLocationPage({}, true)
      }
    }
  }, [ selectedBuildingId, selectedBuilding, allBuildings ]);

  const onCreateBuilding = () => {
    selectBuildingHandler(-1);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Building list">
          <CheckPermission permissionName={PERMISSIONS.BUILDING_ACTIONS} noPermissionComponent={null}>
            <IconButton
              disabled={currentLocationId === -1}
              aria-label='add building'
              color='secondary'
              size='small'
              onClick={onCreateBuilding}
            >
              <AddCircleOutline />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <MenuListWithImage
          items={allBuildings}
          selectItem={selectBuildingHandler}
          selectedItemId={selectedBuildingId}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BuildingsList;