import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTypedDispatch } from "../../../app/state";
import { selectIsEnterDown, selectIsEscDown } from "../../../store/keyboardStore/keyboardSelectors";
import { clearAllPoints, setDrawingStatus } from "../../../store/drawingStore/drawingState";
import { MyLocation } from "@material-ui/icons";
import StopIcon from '@material-ui/icons/Stop';
import { IconButton } from "@material-ui/core";
import { selectDrawingPoints, selectDrawingStatus } from "../../../store/drawingStore/drawingSelectors";
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import { checkFigureCrossing } from "../../../helpers/geometry";
import { saveDevicePath } from "../../../store/deviceStore/deviceRequests";

const DrawingButton = ({ disabled = false, title = '' }) => {
  const dispatch = useTypedDispatch();
  const { openSnackbar } = useSnackbar();
  const selectedDevice = useSelector(selectCurrentDevice);
  const pointList = useSelector(selectDrawingPoints);
  const isDrawing = useSelector(selectDrawingStatus);
  const isEscDown = useSelector(selectIsEscDown);
  const isEnterDown = useSelector(selectIsEnterDown);

  useEffect(() => {
    if (!isEnterDown && isDrawing) {
      // enter pressed
      if (!selectedDevice) {
        return;
      }
      if (pointList.length < 3) {
        openSnackbar({
          title: "Cannot save device figure",
          message: "Figure should have at least 3 points",
          severity: "error",
        });
        return;
      }

      if (checkFigureCrossing([ ...pointList ])) {
        openSnackbar({
          title: "Cannot save device figure",
          message: "Lines should not cross",
          severity: "error",
        });
        return;
      }

      // success validation
      dispatch(setDrawingStatus(false));
      dispatch(
        saveDevicePath({
          deviceId: selectedDevice.id,
          path: pointList,
        })
      )
        .then(() => {
          openSnackbar({
            title: "Success",
            message: "Saved successfully",
            severity: 'success',
          });
        })
      dispatch(clearAllPoints());
    }
  }, [ isEnterDown ]);

  useEffect(() => {
    if (!isEscDown && isDrawing) {
      // esc pressed
      dispatch(setDrawingStatus(false));
      dispatch(clearAllPoints());
    }
  }, [ isEscDown ]);

  const toggleDrawing = () => {
    dispatch(setDrawingStatus(!isDrawing));
    dispatch(clearAllPoints());
  }

  return (
    <IconButton
      aria-label={title}
      color='secondary'
      size='small'
      disabled={disabled}
      onClick={toggleDrawing}
    >
      {
        isDrawing ? (
          <StopIcon />
        ) : (
          <MyLocation />
        )
      }
    </IconButton>
  )
}

export default DrawingButton;