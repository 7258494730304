import { createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import TechnologyConfigurationModel from "../../models/TechnologyConfigurationModel";
import { LoadingStatus, status } from "../../app/shared";
import {
  createTechnologyConfiguration,
  createTechnologyConfigurationChannel,
  deleteTechnologyConfiguration,
  deleteTechnologyConfigurationChannel,
  requestAllTechnologyConfigurations,
  updateTechnologyConfiguration,
  updateTechnologyConfigurationChannel
} from "./technologyConfigurationRequests";

export const technologyConfigurationAdapter = createEntityAdapter<TechnologyConfigurationModel>({
  selectId: (technologyConfiguration) => technologyConfiguration.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type TechnologyConfigurationState = {
  selectedTechnologyConfigurationId: number | null;
  selectedChannelId: number | null;
  loadAllStatus: LoadingStatus,
} & EntityState<TechnologyConfigurationModel>

const initialState: TechnologyConfigurationState = {
  selectedTechnologyConfigurationId: null,
  selectedChannelId: null,
  loadAllStatus: status.default,
  ...technologyConfigurationAdapter.getInitialState(),
}

export const technologyConfigurationSlice = createSlice({
  name: 'technologyConfigurationList',
  initialState,
  reducers: {
    setCurrentTechnologyConfigurationId: (state, action) => {
      state.selectedTechnologyConfigurationId = action.payload;
      state.selectedChannelId = null;
    },
    setCurrentChannelId: (state, action) => {
      state.selectedChannelId = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(requestAllTechnologyConfigurations.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(requestAllTechnologyConfigurations.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(requestAllTechnologyConfigurations.fulfilled, (state, action) => {
        state.loadAllStatus = status.loaded;
        technologyConfigurationAdapter.upsertMany(state, action.payload);
      })
      .addCase(createTechnologyConfiguration.fulfilled, (state, action) => {
        technologyConfigurationAdapter.addOne(state, action.payload);
      })
      .addCase(deleteTechnologyConfiguration.fulfilled, (state, action) => {
        if (action.payload) {
          technologyConfigurationAdapter.removeOne(state, action.payload);
        }
      })
      .addMatcher(isAnyOf(
        updateTechnologyConfiguration.fulfilled, deleteTechnologyConfigurationChannel.fulfilled,
        updateTechnologyConfigurationChannel.fulfilled, createTechnologyConfigurationChannel.fulfilled
      ), (state, action) => {
        technologyConfigurationAdapter.upsertOne(state, action.payload);
      })
});

export const { setCurrentTechnologyConfigurationId, setCurrentChannelId } = technologyConfigurationSlice.actions;