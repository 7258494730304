import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCountriesByRegionId, selectCurrentCountryId } from "../../../store/countryStore/countrySelectors";
import {
  selectCurrentLocation,
  selectCurrentLocationId,
  selectLocationsByCountryIdList
} from "../../../store/locationStore/locationSelectors";
import { selectCurrentRegionId } from "../../../store/regionStore/regionSelectors";
import useNavigator from "../../../hooks/navigator.hook";
import MenuList from "../../../components/MenuList";

import classes from './index.module.scss';

const LocationsList = () => {
  const currentRegionId = useSelector(selectCurrentRegionId);
  const currentCountryId = useSelector(selectCurrentCountryId);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const { navigateToLocationPage } = useNavigator();
  const currentLocation = useSelector(selectCurrentLocation);
  const countryList = useSelector(selectCountriesByRegionId(currentRegionId)) || [];
  const countryIdList = currentCountryId ? [ currentCountryId ] : countryList.map((country) => country.id);
  const locationList = useSelector(selectLocationsByCountryIdList(countryIdList));

  useEffect(() => {
    if (currentLocationId === -1) {
      return;
    }

    if (!currentLocationId || !currentLocation) {
      const firstLocation = locationList[0];
      navigateToLocationPage({ locationId: firstLocation ? firstLocation.id : -1 })
    }
  }, [ currentLocationId, currentLocation, locationList ])

  const onSelect = (locationId: number) => {
    navigateToLocationPage({ locationId })
  }

  return (
    <MenuList
      selectItem={onSelect}
      selectedItemId={currentLocationId}
      items={locationList}
      classes={
        {
          menuItem: classes.menuItem,
        }
      }
    />
  );
}

export default LocationsList;