import React, { useState } from "react";
import _ from 'lodash';
import { DataGrid, DataGridProps } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const StretchedDataGrid = ({ columns, ...props }: DataGridProps) => {
  const ref = React.createRef() as any;
  const [ state, setState ] = useState({ mappedColumns: _.clone(columns), resized: false });

  const { mappedColumns, resized } = state;

  const autoSizeColumns = () => {
    const domRows = [ ...(ref.current?.querySelectorAll('.MuiDataGrid-row') || []) ];
    const domReady = (props.rows?.length === 0) || domRows.length;

    if (!domReady) {
      setTimeout(autoSizeColumns, 15);
      return;
    }

    if (props.rows.length === 0) {
      setState({
        mappedColumns,
        resized: true,
      });

      return;
    }

    setState(previousState => {
      const mappedColumns = _.clone(previousState.mappedColumns);

      mappedColumns
        .forEach((col, idx) => {
          const maxContentWidth = domRows
            .reduce((previousMax, dR) => Math.max(previousMax, dR.childNodes[props.checkboxSelection ? idx + 1 : idx].scrollWidth), 0);
          const originalCol = columns[idx];
          if (!originalCol.width) {
            col.width = maxContentWidth;
            delete col.flex;
          }
        });

      return { mappedColumns, resized: true };
    });
  }

  return (
    <>
      {!resized &&
        <Grid container alignItems="center" justifyContent="center" style={{
          height: '100%',
          position: 'absolute',
          opacity: 0.8,
          backgroundColor: 'white',
          textAlign: 'center',
          zIndex: 1
        }}>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      }
      <DataGrid
        ref={ref}
        onResize={autoSizeColumns}
        columns={mappedColumns}
        {...props /* eslint-disable-line react/jsx-props-no-spreading */}
      />
    </>
  );
}

export default StretchedDataGrid;