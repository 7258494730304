import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { throttle } from "lodash";
import AddPropsToChildren from "../../../components/AddPropsToChildren";
import { useTypedDispatch } from "../../../app/state";
import { getPointOffset } from "../renderMapPoint";
import { getMapRelativePointsFromMap } from "../../../helpers/mapHelpers";
import { createMapPoint } from "store/mapPointsStore/mapPointsState";
import { selectEditedPoints } from "../../../store/mapPointsStore/mapPointSelectors";
import { ICONS, ICONS_DIMENSIONS } from "../constants";

const icon = ICONS['scale'];
const iconDimensions = ICONS_DIMENSIONS['scale'];
const offset = getPointOffset('scale');

type ScalePointModel = {
  x: number,
  y: number,
}

// todo: make it a dumb component
const PointsRenderer = ({ children, mapModel, ...props }) => {
  const [scale, setScale] = useState(1);
  const dispatch = useTypedDispatch();
  const mapRelativePoints = getMapRelativePointsFromMap(mapModel || {});
  const currentlyEditedPoints = useSelector(selectEditedPoints);
  const pointsList: ScalePointModel[] = [];

  const delayedSetScale = throttle((newScale) => {
    setScale(newScale);
  }, 100);

  const onZoom = (newScale) => {
    delayedSetScale(newScale);
  }

  currentlyEditedPoints.forEach((_, index) => {
    const result = {
      ...mapRelativePoints[index],
      ...currentlyEditedPoints[index],
    }

    // point removed
    if (currentlyEditedPoints[index].removed) {
      return;
    }

    if (result.x && result.y) {
      pointsList.push({
        x: result.x,
        y: result.y,
      })
    }
  });

  const onClick = useCallback((x, y) => {
    dispatch(createMapPoint({ x, y }));
  }, []);

  const extraProps = {
    onZoomHandlers: [onZoom],
    mapChildren: (
      <>
        {props.mapChildren}
        {
          pointsList.map((point) => {
            const x = (point.x - offset.width / scale);
            const y = (point.y - offset.height / scale);
            return (
              <image
                key={point.x + point.y}
                xlinkHref={icon}
                width={iconDimensions.width / scale}
                height={iconDimensions.height / scale}
                x={x}
                y={y}
              />
            )
          })
        }
      </>
    ),
    onClick: [onClick],
  }

  return (
    <AddPropsToChildren
      extraProps={extraProps}
      {...props}
    >
      {children}
    </AddPropsToChildren>
  );
}

export default PointsRenderer;