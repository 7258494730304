import React, { useEffect } from "react";
import TabsContent from "../../../components/TabsContent";
import { useSelector } from "react-redux";
import { selectCurrentCountryId } from "../../../store/countryStore/countrySelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { selectCurrentLocation } from "../../../store/locationStore/locationSelectors";
import { selectCurrentFloor } from "../../../store/floorStore/floorSelectors";
import { requestAllDevicesByQuery } from "../../../store/deviceStore/deviceRequests";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import { getAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemRequests";
import { requestTemplatesByCountryId } from "../../../store/templatesStore/templatesRequest";
import TemplatesList from "../../Shared/TemplatesList";
import DeviceList from "../../Shared/DeviceList";
import { useTypedDispatch } from "../../../app/state";
import { clearDeviceList } from "../../../store/deviceStore/deviceState";
import { clearTemplateList } from "../../../store/templatesStore/templateState";

export enum DEVICE_TEMPLATE_ACTIVE_TABS {
  DEVICE,
  TEMPLATES,
}

export enum TAB_TYPE {
  LOCATION,
  FLOOR,
}

const DeviceTemplatesTab = ({
  activeTab = DEVICE_TEMPLATE_ACTIVE_TABS.DEVICE,
  tabType = TAB_TYPE.LOCATION,
}) => {
  const dispatch = useTypedDispatch();
  const currentLocation = useSelector(selectCurrentLocation);
  const currentFloor = useSelector(selectCurrentFloor);
  const mapId = tabType === TAB_TYPE.LOCATION ? currentLocation?.mapId : currentFloor?.mapId;
  const currentCountryId = useSelector(selectCurrentCountryId);
  const countryId = currentCountryId || currentLocation?.countryId;

  useEffect(() => {
    dispatch(clearDeviceList());
  }, [ mapId ]);

  useEffect(() => {
    dispatch(clearTemplateList());
  }, [ countryId ]);

  return (
    <GeneralRequest requestAction={getAllWirelessSystems}>
      <TabsContent
        selectedId={activeTab}
        items={[
          {
            label: 'Devices',
            id: DEVICE_TEMPLATE_ACTIVE_TABS.DEVICE,
            children: (
              mapId ? (
                <GeneralRequest
                  key={mapId}
                  requestAction={requestAllDevicesByQuery}
                  requestArguments={[ { mapId } ]}
                >
                  <DeviceList
                    showFiltered={false}
                  />
                </GeneralRequest>
              ) : (
                <DeviceList />
              )
            )
          },
          {
            label: 'Templates',
            id: DEVICE_TEMPLATE_ACTIVE_TABS.TEMPLATES,
            children: (
              <GeneralRequest
                requestAction={requestTemplatesByCountryId}
                requestArguments={[ countryId ]}
              >
                <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
                  <TemplatesList
                    countryId={countryId}
                  />
                </HorizontalPanel>
              </GeneralRequest>
            ),
          }
        ]}
      />
    </GeneralRequest>
  )
}

export default DeviceTemplatesTab;
