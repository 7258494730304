import React from "react";
import { FormControl, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";

import classes from './index.module.scss';
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";

const BulkOperations = ({
  selectedOperation,
  onOperationSet,
}: { selectedOperation: string, onOperationSet: (operationId) => void }) => {
  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Choose operation" />
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <FormControl component="fieldset" className={classes.container}>
          <RadioGroup aria-label="operation" name="operation" value={selectedOperation} onChange={onOperationSet}>
            <FormControlLabel value="submit" control={<Radio />} label="Submit" />
            <CheckPermission
              permissionName={PERMISSIONS.DEVICE_WORKFLOW_UPDATE}
              noPermissionComponent={(
                <>
                  <FormControlLabel value="approve" disabled control={<Radio />} label="Approve" />
                  <FormControlLabel value="reject" disabled control={<Radio />} label="Reject" />
                </>
              )}
            >
              <FormControlLabel value="approve" control={<Radio />} label="Approve" />
              <FormControlLabel value="reject" control={<Radio />} label="Reject" />
            </CheckPermission>
          </RadioGroup>
        </FormControl>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BulkOperations;