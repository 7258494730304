import { useState } from "react";
import { CollapsableListItem, CollapsableListItemProps } from "../CollapsableListItem/CollapsableListItem";
import { InputAdornment, List, ListItem, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

interface CollapsibleMenuListProps {
  groups: Partial<CollapsableListItemProps>[];
  selectItem?: (id: number) => void;
  selectedItemId?: number | null;
  disableSearch?: boolean;
  isOpened?: boolean;
}

const CollapsibleMenuList = (props: CollapsibleMenuListProps) => {
  const [ search, setSearch ] = useState('');
  const { groups = [], disableSearch = false, isOpened = true, selectItem, selectedItemId } = props;
  const itemsCount = groups.reduce((previousValue, currentValue) => {
    return (currentValue.items || []).length + previousValue;
  }, 0);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {
        itemsCount > 25 && !disableSearch ? (
          <ListItem>
            <OutlinedInput
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              startAdornment={(
                <InputAdornment position={"start"}>
                  <SearchIcon />
                </InputAdornment>
              )}
            />

          </ListItem>
        ) : null
      }
      {
        groups.map((group, index) => {
          return (
            <CollapsableListItem
              key={index}
              selectedItemId={selectedItemId}
              {...group}
              selectItem={selectItem || group.selectItem || (() => {})}
              listTitle={group.listTitle || ''}
              searchText={search}
              isOpened={isOpened}
            />
          )
        })
      }
    </List>
  )
}

export default CollapsibleMenuList;