import { InfluenceAreaData } from "../index";
import GeneralFigureInfluence from "../GeneralFigureInfluence";

export type CircleInfluenceProps = {
  influenceArea: InfluenceAreaData,
  index: number,
  scale: number,
  visible?: boolean;
}

const CircleInfluence = (props: CircleInfluenceProps) => {
  const { influenceArea, scale, index = 0, visible = false } = props;

  return (
    <GeneralFigureInfluence
      elementProps={
        {
          cx: influenceArea.x,
          cy: influenceArea.y,
          r: influenceArea.radiusInPx,
          strokeWidth: Math.min(2 / scale, 2),
        }
      }
      transitionDelay={index * 100}
      visible={visible}
      selected={influenceArea.isSelected}
    />
  )
}

export default CircleInfluence;