import React from "react";
import { FormikProps } from "formik";
import { Grid, TextField, Typography } from "@material-ui/core";
import { DeviceFormikInterface } from "./formikInterface";
import WirelessSystemSelect from "../../containers/DevicesPage/WirelessSystemSelect";
import { DeviceStatus } from "../../models/enums/DeviceStatus.enum";
import DeviceAssignee from "../../containers/DevicesPage/DeviceAssignee";
import DatePicker from "../DatePicker";
import UnitsInput from "../TextFields/UnitsInput";
import IndoorOutdoorField from "../../containers/DevicesPage/IndoorOutdoorField";
import DeviceTypeInput from "../DeviceTypeInput";

import classes from './index.module.scss';

export type DeviceFormProps = {
  deviceFormik: FormikProps<DeviceFormikInterface>
  deviceExtraData?: {
    status?: DeviceStatus,
    requester?: string,
    hasMapPoints?: boolean,
  },
  formDisabled?: boolean;
  onUpdate: () => any;
  isAssignDisabled: boolean;
}

const DeviceForm = ({
  deviceFormik,
  formDisabled = false,
  deviceExtraData,
  onUpdate,
  isAssignDisabled,
}: DeviceFormProps) => {
  const { values, errors, handleChange, setFieldValue } = deviceFormik;
  const { requester, status } = deviceExtraData || { requester: '', status: DeviceStatus.NEW };

  const onSubmit = (e) => e.preventDefault();

  return (
    <Grid
      container
      component='form'
      spacing={2}
      onSubmit={onSubmit}
      className={classes.deviceForm}
      wrap='wrap'
    >
      <Grid item xs={3}>
        <TextField
          label='Device name'
          required
          variant='outlined'
          name='name'
          fullWidth
          disabled={formDisabled}
          value={values.name ?? ''}
          onChange={handleChange}
          helperText={errors.name}
          error={Boolean(errors.name)}
        />
      </Grid>
      <Grid item xs={3}>
        <WirelessSystemSelect
          disabled={formDisabled}
          wirelessSystemId={values.wirelessSystemId}
          setFieldValue={deviceFormik.setFieldValue}
        />
      </Grid>
      <DeviceTypeInput
        hasMapPoint={deviceExtraData?.hasMapPoints}
        formDisabled={formDisabled}
        deviceFormik={deviceFormik}
      />
      {/* Second line */}

      <Grid item xs={3}>
        <TextField
          label='Requester name'
          variant='outlined'
          name='requester'
          fullWidth
          value={requester}
          disabled={true}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label='Affected unit'
          required
          variant='outlined'
          name='affectedUnits'
          fullWidth
          value={values?.affectedUnits}
          onChange={handleChange}
          disabled={formDisabled}
          error={Boolean(errors?.affectedUnits)}
          helperText={errors?.affectedUnits}
        />
      </Grid>
      <Grid item xs={3}>
        <DeviceAssignee
          disabled={isAssignDisabled}
          onUpdate={onUpdate}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label='Status'
          variant='outlined'
          name='status'
          fullWidth
          value={status}
          disabled={true}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={formDisabled}
          name='description'
          label='Device description'
          multiline
          maxRows={3}
          onChange={handleChange}
          value={values?.description}
          variant='outlined'
          error={Boolean(errors.description)}
          helperText={errors.description}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Model name'
          variant='outlined'
          name='modelName'
          fullWidth
          value={values?.modelName}
          onChange={handleChange}
          disabled={formDisabled}
          error={Boolean(errors.modelName)}
          helperText={errors.modelName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Manufacturer'
          variant='outlined'
          name='manufacturer'
          fullWidth
          value={values?.manufacturer}
          onChange={handleChange}
          disabled={formDisabled}
          error={Boolean(errors.manufacturer)}
          helperText={errors.manufacturer}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Operator'
          variant='outlined'
          name='operator'
          fullWidth
          value={values?.operator}
          onChange={handleChange}
          disabled={formDisabled}
          error={Boolean(errors.operator)}
          helperText={errors.operator}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Operator contact information'
          variant='outlined'
          name='operatorInfo'
          fullWidth
          value={values?.operatorInfo}
          onChange={handleChange}
          disabled={formDisabled}
          error={Boolean(errors.operatorInfo)}
          helperText={errors.operatorInfo}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h4'>Positioning info</Typography>
      </Grid>
      <Grid item xs={3}>
        <IndoorOutdoorField />
      </Grid>
      <Grid item xs={3}>
        <UnitsInput
          unitList={UnitsInput.UNIT_TEMPLATE.SHORT_DISTANCE}
          disabled={formDisabled}
          fullWidth
          label="Height"
          variant="outlined"
          name="height"
          defaultUnit='m'
          value={values.height ?? ""}
          onChange={handleChange}
          error={Boolean(errors.height)}
          helperText={errors.height}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label='Axis'
          variant='outlined'
          name='axis'
          fullWidth
          disabled={formDisabled}
          value={values.axis ?? ''}
          onChange={handleChange}
          helperText={errors.axis}
          error={Boolean(errors.axis)}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label='Room'
          variant='outlined'
          name='room'
          fullWidth
          disabled={formDisabled}
          value={values.room ?? ''}
          onChange={handleChange}
          helperText={errors.room}
          error={Boolean(errors.room)}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h4'>Service terms</Typography>
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          disabled={formDisabled}
          variant='inline'
          inputVariant='outlined'
          label='Implementation start date'
          name='implementationStart'
          fullWidth
          value={values?.implementationStart}
          onChange={(value) => setFieldValue('implementationStart', value)}
          error={Boolean(errors.implementationStart)}
          helperText={errors.implementationStart}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          disabled={formDisabled}
          variant='inline'
          inputVariant='outlined'
          label='Implementation end date'
          name='implementationEnd'
          fullWidth
          value={values?.implementationEnd}
          onChange={(value) => setFieldValue('implementationEnd', value)}
          error={Boolean(errors.implementationEnd)}
          helperText={errors.implementationEnd}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          disabled={formDisabled}
          variant='inline'
          inputVariant='outlined'
          label='Expiration date'
          name='expirationDate'
          fullWidth
          value={values?.expirationDate}
          onChange={(value) => setFieldValue('expirationDate', value)}
          error={Boolean(errors.expirationDate)}
          helperText={errors.expirationDate}
        />
      </Grid>
    </Grid>
  );
}

export default DeviceForm;