import { useSelector } from "react-redux";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { selectCurrentFloorId } from "../../../store/floorStore/floorSelectors";
import DeviceTemplatesTab, { TAB_TYPE } from "../../DevicesPage/DeviceTemplatesTab";

const BuildingRightPanel = () => {
  const currentFloorId = useSelector(selectCurrentFloorId);
  const isCreateMode = currentFloorId === -1 || !currentFloorId;

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        {
          isCreateMode ? null : (
            <DeviceTemplatesTab tabType={TAB_TYPE.FLOOR} />
          )
        }
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BuildingRightPanel;