import React from "react";
import { InfluenceAreaData } from "../index";
import { getInfluenceAreaFromIrregularFigure } from "../../zoomMapHelpers";
import { createPathFromPoints } from "../../../../helpers/svg";
import GeneralFigureInfluence, { INFLUENCE_COLOR, INFLUENCE_FIGURE } from "../GeneralFigureInfluence";

interface PortableDeviceInfluenceProps {
  influenceArea: InfluenceAreaData,
  index: number,
  visible?: boolean;
}

function getPoly(pointList, influenceArea) {
  const poly = getInfluenceAreaFromIrregularFigure(pointList, influenceArea.radiusInPx);

  return createPathFromPoints(poly);
}

const PortableDeviceInfluence = (props: PortableDeviceInfluenceProps) => {
  const { influenceArea, index, visible = false } = props;
  const pointList = JSON.parse(influenceArea.path || '[]');
  const path = getPoly(pointList, influenceArea)

  return (
    <GeneralFigureInfluence
      visible={visible}
      selected={influenceArea.isSelected}
      transitionDelay={index * 100}
      figure={INFLUENCE_FIGURE.PATH}
      color={INFLUENCE_COLOR.PORTABLE}
      elementProps={
        {
          d: path,
        }
      }
    />
  )
}

export default PortableDeviceInfluence;