import { useSelector } from "react-redux";
import {
  selectCurrentDevice, selectCurrentDeviceId,
  selectCurrentWirelessInterface
} from "../../../store/deviceStore/deviceSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import WirelessInterfaceList from "../WirelessInterfaceList";
import WirelessInterfaceForm from "../WirelessInterfaceForm";
import {
  requestAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationRequests";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import { selectCurrentBuildingId } from "../../../store/buildingStore/buildingsSelectors";
import { useTypedDispatch } from "../../../app/state";
import { setCurrentWirelessInterfaceId } from "../../../store/deviceStore/deviceState";
import { addWirelessInterfaceToDevice } from "../../../store/deviceStore/deviceRequests";
import DeviceModel from "../../../models/DeviceModel";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";

interface WirelessFormAggregatorProps {
  formDisabled?: boolean;
  onUpdate: () => any;
}

const WirelessFormAggregator = (props: WirelessFormAggregatorProps) => {
  const dispatch = useTypedDispatch();
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const currentDevice = useSelector(selectCurrentDevice);
  const selectedConfiguration = useSelector(selectCurrentConfiguration);
  const currentConfiguration = selectedConfiguration || currentDevice?.configuration;
  const wirelessInterfaceList = currentConfiguration?.wirelessInterfaces || [];
  const currentBuildingId = useSelector(selectCurrentBuildingId);
  const currentWirelessInterface = useSelector(selectCurrentWirelessInterface);
  const isCreateMode = currentDeviceId === -1;

  const onWirelessInterfaceSelect = (id) => {
    dispatch(setCurrentWirelessInterfaceId(id));
  }

  const onAddWirelessInterface = (technologyConfigurationId: number) => {
    if (!currentDevice || !technologyConfigurationId) {
      return;
    }
    dispatch(
      addWirelessInterfaceToDevice({
        deviceId: currentDevice.id,
        technologyConfigurationId
      })
    )
      .then((action) => {
        const device = action.payload as DeviceModel;
        const wirelessInterfaceList = (device.configuration.wirelessInterfaces || []).slice();
        const last = wirelessInterfaceList.pop();
        dispatch(setCurrentWirelessInterfaceId(last?.id));
        props.onUpdate && props.onUpdate()
      })
  }

  const onAfterDelete = () => {
    dispatch(setCurrentWirelessInterfaceId(null));
    props.onUpdate && props.onUpdate();
  }

  return (
    <GeneralRequest
      requestAction={requestAllTechnologyConfigurations}
      requestArguments={[{ buildingId: currentBuildingId }]}
      loadingElement={null}
    >
      <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
        <WirelessInterfaceList
          wirelessInterfaceList={wirelessInterfaceList}
          currentWirelessInterfaceId={currentWirelessInterface?.id}
          onWirelessInterfaceSelect={onWirelessInterfaceSelect}
          onAddWirelessInterface={onAddWirelessInterface}
          onAfterDelete={onAfterDelete}
          formDisabled={props.formDisabled || isCreateMode}
        />
        {
          !!currentWirelessInterface && (
            <>
              <WirelessInterfaceForm
                currentWirelessInterface={currentWirelessInterface}
                {...props}
              />
            </>
          )
        }
      </HorizontalPanel>
    </GeneralRequest>
  )
}

export default WirelessFormAggregator;