import { RootState } from "../../app/state";
import { createSelector } from "@reduxjs/toolkit";
import { templatesAdapter } from "./templateState";

const selectRoot = (state: RootState) => state;

export const selectTemplatesSlice = (state: RootState) => state.deviceTemplates;

const templatesAdapterSelector = templatesAdapter.getSelectors(selectTemplatesSlice);

export const selectAllTemplates = templatesAdapterSelector.selectAll;

export const selectTemplateById = (templateId: number | null) => createSelector(
  selectRoot,
  (root) => (templateId && templatesAdapterSelector.selectById(root, templateId)) || null
);

export const selectCurrentTemplateId = createSelector(
  selectTemplatesSlice,
  (state) => state.selectedTemplateId
);

export const selectCurrentTemplate = createSelector(
  selectRoot,
  selectCurrentTemplateId,
  (root, templateId) => (templateId && templatesAdapterSelector.selectById(root, templateId)) || null
);

export const selectCurrentWirelessInterfaceId = createSelector(
  selectTemplatesSlice,
  (state) => state.selectedWirelessInterfaceId
);

export const selectCurrentTemplateWirelessInterface = createSelector(
  selectRoot,
  selectCurrentWirelessInterfaceId,
  selectCurrentTemplate,
  (root, currentWirelessInterfaceId, currentTemplate) => {
    const wirelessInterfacesList = currentTemplate?.wirelessInterfaces || [];

    if (!currentWirelessInterfaceId) {
      return wirelessInterfacesList[0];
    }

    return wirelessInterfacesList.find((wirelessInterface) => wirelessInterface.id === currentWirelessInterfaceId);
  }
);