import React, { useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import ModalWithToggle from "../../../components/ModalWithToggle";
import ChannelsTable from "../../../components/ChannelsTable";
import {
  selectTechnologyConfigurationById,
} from "../../../store/technologyConfigurationStore/technologyConfigurationSelectors";

const ChannelListDialog = ({ formDisabled, onSave, currentWirelessInterface }) => {
  const selectedTechnologyConfiguration = useSelector(selectTechnologyConfigurationById(currentWirelessInterface?.technologyConfigurationId));
  const currentChannels = currentWirelessInterface?.channels || [];
  const channelIdList = currentChannels.map((channel) => channel.id);
  const [selectedChannels, setSelectedChannels] = useState<number[]>(channelIdList);
  const availableChannels = selectedTechnologyConfiguration?.channels || [];
  const zeroChannelsToChooseFrom = availableChannels.length === 0;

  const onChannelsSet = () => {
    if (!currentWirelessInterface) {
      return;
    }

    onSave && onSave(selectedChannels);
  }

  return (
    <ModalWithToggle
      title="Channels configuration"
      confirmButtonText="Set"
      confirmButtonHandler={onChannelsSet}
      modalContent={(
        <ChannelsTable
          availableChannels={availableChannels}
          selectedChannelIdList={selectedChannels}
          onSelect={setSelectedChannels}
        />
      )}
    >
      <IconButton
        aria-label='Add channels'
        color='secondary'
        size='small'
        disabled={formDisabled || zeroChannelsToChooseFrom}
      >
        <TuneRoundedIcon />
      </IconButton>
    </ModalWithToggle>
  )
}

export default ChannelListDialog;