import React from "react";
import { GridColDef } from "@material-ui/data-grid";
import { Link, Typography } from "@material-ui/core";
import { capitalize } from "../../helpers/stringHelpers";
import { dateFormatted } from "../../helpers/timeHelpers";
import DeviceIcon from "../DeviceIcon/DeviceIcon";

const deviceNameClick = (e) => {
  e.stopPropagation();
}

const devicesColumns: GridColDef[] = [
  {
    field: 'device',
    headerName: ' ',
    width: 25,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      const device = params.formattedValue;
      return <DeviceIcon deviceConfiguration={device?.configuration} />
    }
  },
  {
    field: 'name',
    headerName: 'Name',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return params.value?.showDeviceLink ? (
        <Link href={params.value?.link} target="_blank" onClick={deviceNameClick}>
          <Typography variant='body1'>
            {params.value?.text}
          </Typography>
        </Link>
      ) : (
        <Typography variant='body1'>
          {params.value?.text}
        </Typography>
      )
    },
  },
  {
    field: 'origin',
    headerName: 'Origin',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'wirelessSystem',
    headerName: 'Wireless System',
    editable: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 160,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'requester',
    headerName: 'Requester',
    editable: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 160,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value || 'User unspecified'}
        </Typography>
      );
    },
  },
  {
    field: 'manager',
    headerName: 'Frequency manager',
    editable: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 160,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'wirelessInterfaceList',
    headerName: 'Wireless interface',
    editable: false,
    sortable: false,
    minWidth: 240,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {capitalize(params.value || 'No wireless interfaces')}
        </Typography>
      );
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Last update',
    editable: false,
    sortable: false,
    minWidth: 200,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {dateFormatted(params.value)}
        </Typography>
      );
    },
  },
]

export default devicesColumns;