import { useEffect, useState } from "react";
import cn from 'classnames';

import classes from './index.module.scss';

export enum INFLUENCE_FIGURE {
  CIRCLE,
  PATH,
}

export enum INFLUENCE_COLOR {
  STATIONARY,
  PORTABLE,
}

const INFLUENCE_COLOR_MAP: { fill?: string, stroke?: string, strokeSelected?: string }[] = [
  {
    fill: 'rgba(0, 125, 255, 0.4)',
    stroke: 'rgba(0, 125, 255, 0.2)',
    strokeSelected: 'rgba(0, 125, 255, 1)'
  },
  {
    fill: 'rgba(0, 125, 255, 0.4)',
    stroke: 'transparent',
  }
]

export type GeneralFigureInfluenceProps = {
  figure?: INFLUENCE_FIGURE,
  color?: INFLUENCE_COLOR,
  visible?: boolean;
  selected?: boolean;
  transitionDelay?: number;
  elementProps?: Object;
}

const GeneralFigureInfluence = (props: GeneralFigureInfluenceProps) => {
  const {
    figure = INFLUENCE_FIGURE.CIRCLE,
    color = INFLUENCE_COLOR.STATIONARY,
    visible = false,
    selected = false,
    transitionDelay = 0,
    elementProps = {}
  } = props;

  const [ currentVisibility, setVisibility ] = useState(false);
  const [ currentDelay, setDelay ] = useState(transitionDelay);
  const colorSchema = INFLUENCE_COLOR_MAP[color];
  const { fill, stroke, strokeSelected } = colorSchema;

  const className = cn(classes.area, {
    [classes.invisible]: !currentVisibility,
    [classes.selected]: selected,
    [classes.notSelected]: !selected,
  });

  // to make animation take effect
  useEffect(() => {
    setVisibility(visible);
  }, [ visible ]);

  useEffect(() => {
    setTimeout(() => {
      setDelay(0);
    }, transitionDelay + 1000)
  }, [])

  switch (figure) {
    case INFLUENCE_FIGURE.PATH:
      return (
        <path
          style={{ transitionDelay: currentDelay + 'ms' }}
          className={className}
          fill={fill}
          stroke={selected ? (strokeSelected || stroke) : stroke}
          {...elementProps}
        />
      )
    default:
      return (
        <circle
          style={{ transitionDelay: currentDelay + 'ms' }}
          className={className}
          fill={fill}
          stroke={selected ? (strokeSelected || stroke) : stroke}
          {...elementProps}
        />
      )
  }
}

export default GeneralFigureInfluence;