import { useSelector } from "react-redux";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";
import DeviceTemplatesTab from "../../DevicesPage/DeviceTemplatesTab";

const LocationRightPanel = () => {
  const currentLocationId = useSelector(selectCurrentLocationId);
  const isLocationEditMode = currentLocationId === -1;

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        {
          isLocationEditMode ? null : (
            <DeviceTemplatesTab />
          )
        }
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default LocationRightPanel;