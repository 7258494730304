import { useSelector } from "react-redux";
import DeviceCurrentIdsSetter from "../../../containers/DevicesPage/DeviceCurrentIdsSetter";
import { useTypedDispatch } from "../../../app/state";
import {
  selectAllDevices,
  selectCurrentDevice,
  selectMyRequestDeviceList, selectMyWorkflowDeviceList
} from "../../../store/deviceStore/deviceSelectors";
import { requestMyRequests } from "../../../store/deviceStore/deviceRequests";
import { useEffect } from "react";
import DeviceGeneralContainer from "../../../containers/DevicesPage/DeviceGeneralContainer";
import MyRequestsMenuPanel from "../../../containers/MyRequests/MyRequestsMenuPanel";
import { clearIdList, setCurrentDeviceId } from "../../../store/deviceStore/deviceState";
import DeviceModel from "../../../models/DeviceModel";
import NoItems from "../../../components/NoItems";
import { ThreeColumnLayout } from "../../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import FetchDeviceRequirementsWrapper from "../../../containers/DevicesPage/FetchDeviceRequirementsWrapper";
import useFilter from "../../../hooks/filter.hook";

const RequestsPage = () => {
  const dispatch = useTypedDispatch();
  const { actions } = useFilter();
  const allDevices = useSelector(selectAllDevices);
  const myRequests = useSelector(selectMyRequestDeviceList);
  const myWorkflow = useSelector(selectMyWorkflowDeviceList);
  const currentDevice = useSelector(selectCurrentDevice);

  useEffect(() => {
    actions.initializeFiltersForPage({ mapId: null });
  }, []);

  const hasMyRequests = myRequests.length > 0;
  const hasMyWorkflow = myWorkflow.length > 0;
  const noItems = !hasMyRequests && !hasMyWorkflow;

  const update = () => {
    dispatch(requestMyRequests());
  }

  useEffect(() => {
    update();

    return () => {
      dispatch(clearIdList());
    }
  }, []);

  const selectDevice = (device?: DeviceModel) => {
    if (!device) {
      dispatch(setCurrentDeviceId(null));
      return;
    }

    if (currentDevice?.id === device?.id) {
      return;
    }

    dispatch(setCurrentDeviceId(device?.id));
  }

  const onDeviceSelected = (deviceId: number) => {
    const device = allDevices.find((device) => device.id === deviceId);

    if (device) {
      selectDevice(device);
    }
  }

  useEffect(() => {
    if (!currentDevice) {
      const first = myRequests[0] || myWorkflow[0];
      if (first) {
        selectDevice(first);
      } else {
        selectDevice();
      }
    }
  }, [currentDevice, myRequests, myWorkflow]);

  const leftPanel = <MyRequestsMenuPanel onDeviceSelected={onDeviceSelected} />;

  if (noItems && !currentDevice) {
    return (
      <ThreeColumnLayout
        leftSideBar={null}
        centerMainPanel={<NoItems title='You have no requests' />}
      />
    )
  }

  return (
    <>
      <FetchDeviceRequirementsWrapper>
        <DeviceGeneralContainer
          onUpdate={update}
          leftPanel={leftPanel}
          onLastDeviceDeleted={() => {}}
        />
      </FetchDeviceRequirementsWrapper>
      <DeviceCurrentIdsSetter />
    </>
  );
}

export default RequestsPage;