import { useSelector } from "react-redux";
import { Wifi } from "@material-ui/icons";
import {
  selectAllTemplates,
  selectCurrentTemplateId
} from "../../../store/templatesStore/templatesSelectors";
import WirelessSystemModel from "../../../models/WirelessSystemModel";
import CollapsibleMenuList from "../../../components/CollapsibleMenuList";

const TemplatesMenuList = ({
  onChoose,
  isOpened = true,
  noBorderRadius = false,
  selectedTemplateId,
}: { onChoose: (number) => any, isOpened?: boolean, selectedTemplateId?: number, noBorderRadius?: boolean, }) => {
  const currentTemplateId = useSelector(selectCurrentTemplateId);
  const allTemplates = useSelector(selectAllTemplates);
  const wirelessSystemsList: WirelessSystemModel[] = [];

  allTemplates.forEach((template) => {
    if (!wirelessSystemsList.find((wirelessSystem) => wirelessSystem.id === template.wirelessSystemId)) {
      wirelessSystemsList.push(template.wirelessSystem);
    }
  });

  return (
    <CollapsibleMenuList
      isOpened={isOpened}
      selectItem={onChoose}
      selectedItemId={selectedTemplateId || currentTemplateId}
      groups={wirelessSystemsList
        .map((wirelessSystem) => {
          return {
            icon: <Wifi />,
            listTitle: wirelessSystem.name,
            noBorderRadius: noBorderRadius,
            items: allTemplates.filter((template) => {
              return wirelessSystem.id === template?.wirelessSystemId
            }).map((device) => {
              return {
                secondary: device.name,
                id: device.id,
              }
            })
          }
        })}
    />
  );
}

export default TemplatesMenuList;