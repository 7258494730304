export const truncateString = (str: string, lengthLimit: number = 100) => {
  return str.length > lengthLimit ?
    str.slice(0, lengthLimit) + '...' :
    str;
}

export const truncateStringArray = (strList: string[], lengthLimit?: number) => {
  return (strList || []).map((str) => truncateString(str, lengthLimit));
}

export function stringToHtml(string) {
  return string.replaceAll('\n', '<br />');
}

export function capitalize(str: string) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}