export const ENTER_KEYCODE = 13;
export const SHIFT_KEYCODE = 16;
export const CTRL_KEYCODE = 17;
export const ALT_KEYCODE = 18;
export const ESC_KEYCODE = 27;
export const COMMAND_LEFT_KEYCODE = 91;
export const COMMAND_OLD_FIREFOX_KEYCODE = 224;

export const KEY_MAP = {
  [ENTER_KEYCODE]: 'enter',
  [SHIFT_KEYCODE]: 'shift',
  [CTRL_KEYCODE]: 'control',
  [COMMAND_LEFT_KEYCODE]: 'control',
  [COMMAND_OLD_FIREFOX_KEYCODE]: 'control',
  [ALT_KEYCODE]: 'alt',
  [ESC_KEYCODE]: 'esc',

}