import React, { useState } from "react";
import cn from "classnames";
import { throttle } from "lodash";
import { getPointOffset } from "../renderMapPoint";
import AddPropsToChildren from "../../../components/AddPropsToChildren";
import { ICONS, ICONS_DIMENSIONS } from "../constants";
import {
  filterDevicesWithMapPoint,
  filterDevicesWithPath,
  filterPortableDevices, filterStationaryDevices,
  getDeviceCenter
} from "../zoomMapHelpers";
import { createPathFromPoints } from "../../../helpers/svg";

import classes from './index.module.scss';

const DEVICE_POINT_TYPE = 'device';
const PORTABLE_DEVICE = 'portableDevice'

const _stationaryDeviceIconOffset = getPointOffset(DEVICE_POINT_TYPE);
const _portableDeviceIconOffset = getPointOffset(PORTABLE_DEVICE);

const DeviceRenderer = ({ children, deviceList, currentDeviceId, onClick = (id: number) => {}, ...props }) => {
  const [ scale, setScale ] = useState(1);
  const devicesWithPoints = deviceList.filter(filterStationaryDevices).filter(filterDevicesWithMapPoint);
  const devicesWithPath = deviceList.filter(filterPortableDevices).filter(filterDevicesWithPath);

  const delayedSetScale = throttle((newScale) => {
    setScale(newScale);
  }, 100);

  const onZoom = (newScale) => {
    delayedSetScale(newScale);
  }

  const extraProps = {
    onZoomHandlers: [ onZoom ],
    mapChildren: (
      <>
        {props.mapChildren || null}
        {
          devicesWithPath.map((device) => {
            const pointList = JSON.parse(device.configuration.mapPath.path);
            const polygon = createPathFromPoints([ ...pointList ]);

            return (
              <path
                key={device.id}
                d={polygon}
                fill={device.id === currentDeviceId ? 'rgba(153, 27, 250, 0.16)' : 'rgba(153, 27, 250, 0.08)'}
                stroke={"#991BFA"}
                strokeWidth={1 / scale}
              />
            )
          })
        }
        {
          devicesWithPoints.map((device) => {
            const deviceIconDimensions = ICONS_DIMENSIONS[DEVICE_POINT_TYPE];
            const point = device.configuration.mapPoint.mapPoint;

            const x = (point.x - _stationaryDeviceIconOffset.width / scale);
            const y = (point.y - _stationaryDeviceIconOffset.height / scale);

            return (
              <image
                key={device.id}
                xlinkHref={ICONS[DEVICE_POINT_TYPE]}
                width={deviceIconDimensions.width / scale}
                height={deviceIconDimensions.height / scale}
                x={x}
                y={y}
                onClick={() => onClick(device.id)}
                className={cn(
                  classes.device,
                  {
                    [classes.deviceNotSelected]: device.id !== currentDeviceId,
                    [classes.deviceIconSelected]: device.id === currentDeviceId,
                  }
                )}
              />
            )
          })
        }
        {
          devicesWithPath.map((device) => {
            const pointList = JSON.parse(device.configuration.mapPath.path);
            const deviceIconDimensions = ICONS_DIMENSIONS[PORTABLE_DEVICE];
            const point = getDeviceCenter(pointList);

            const x = (point.x - _portableDeviceIconOffset.width / scale);
            const y = (point.y - _portableDeviceIconOffset.height / scale);

            return (
              <image
                key={device.id}
                xlinkHref={ICONS[PORTABLE_DEVICE]}
                width={deviceIconDimensions.width / scale}
                height={deviceIconDimensions.height / scale}
                x={x}
                y={y}
                onClick={() => onClick(device.id)}
                className={cn(
                  classes.device,
                  {
                    [classes.deviceNotSelected]: device.id !== currentDeviceId,
                    [classes.deviceIconSelected]: device.id === currentDeviceId,
                  }
                )}
              />
            )
          })
        }
      </>
    )
  }

  return (
    <AddPropsToChildren
      extraProps={extraProps}
      {...props}
    >
      {children}
    </AddPropsToChildren>
  )
}

export default DeviceRenderer;