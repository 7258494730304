import React, { useMemo } from "react";
import { FormikProps } from "formik";
import { Typography } from "@material-ui/core";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { TableList } from "../../../app/components/TableList";
import UnitsInput from "../../../components/TextFields/UnitsInput";

import classes from './index.module.scss';
import WirelessInterfaceModel from "../../../models/WirelessInterfaceModel";

interface ChannelForm {
  [key: string]: string;
}

export interface ChannelDetailsProps {
  channelDetailsFormik: FormikProps<ChannelForm>;
  formDisabled?: boolean;
  currentWirelessInterface: WirelessInterfaceModel | null,
}

const ChannelDetails = ({
  formDisabled = false,
  channelDetailsFormik,
  currentWirelessInterface,
}: ChannelDetailsProps) => {
  const channels = currentWirelessInterface?.channels || [];
  const values = channelDetailsFormik.values;
  const channelsFormDisabled = formDisabled;

  const columns = useMemo(() => {
    return [
      { flex: 1, field: "channelNumber", headerName: "Number" },
      { flex: 1, field: "downlinkCF", headerName: "Frequency" },
      { flex: 1, field: "EIRP", headerName: "EIRP" },
    ];
  }, []);

  const rows = channels.map((channel) => {
    const name = 'EIRP' + channel.id;
    return {
      id: channel.channelNumber,
      channelNumber: channel.channelNumber,
      downlinkCF: channel.downlinkCF,
      EIRP: (
        <UnitsInput
          unitList={UnitsInput.UNIT_TEMPLATE.EXTENDED_POWER}
          disabled={channelsFormDisabled}
          fullWidth
          size='small'
          variant='outlined'
          name={name}
          onChange={channelDetailsFormik.handleChange}
          value={values[name] || ''}
        />
      )
    }
  })

  return (
    <>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL} className={classes.channelsTitle}>
        <Typography variant="h4">Channels</Typography>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <TableList
          columns={columns}
          rows={rows}
          disableSearch
        />
      </HorizontalPanel>
    </>
  )
}

export default ChannelDetails;