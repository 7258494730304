import { getQueryObject } from "../index";
import useRoutes from "../../hooks/routeHooks";
import useFilter from "../../hooks/filter.hook";
import { useEffect } from "react";
import { GeneralRequest } from "../../requests/GeneralRequest";
import { requestAllDevicesByQuery } from "../../store/deviceStore/deviceRequests";
import FetchDeviceRequirementsWrapper from "../../containers/DevicesPage/FetchDeviceRequirementsWrapper";
import PageUpdater from "../../containers/PageUpdater";
import { setCurrentDeviceId } from "../../store/deviceStore/deviceState";
import { setCurrentMapId } from "../../store/mapStore/mapState";
import { setCurrentLocationIdByMapId } from "../../store/locationStore/locationState";
import { setCurrentFloorByMapId } from "../../store/floorStore/floorState";
import DeviceGeneralContainer from "../../containers/DevicesPage/DeviceGeneralContainer";
import DeviceCurrentIdsSetter from "../../containers/DevicesPage/DeviceCurrentIdsSetter";

const SingleDevicePage = () => {
  const urlProps = getQueryObject();
  // const deviceId = parseInt(urlProps.deviceId as string, 10);
  const mapId = parseInt(urlProps.mapId as string, 10);
  const { actions } = useRoutes();
  const filter = useFilter();

  useEffect(() => {
    if (!isNaN(mapId)) {
      filter.actions.initializeFiltersForPage({ mapId });
    }
  }, []);

  if (isNaN(mapId)) {
    actions.redirectWrongId();
    return null;
  }

  return (
    <>
      <PageUpdater
        watchItemList={[
          {
            title: 'deviceId',
            action: setCurrentDeviceId,
          },
          {
            title: 'mapId',
            action: setCurrentMapId,
          },
        ]}
      />
      <GeneralRequest
        requestAction={requestAllDevicesByQuery}
        requestArguments={[ { mapId } ]}
      >
        <FetchDeviceRequirementsWrapper>
          <PageUpdater
            watchItemList={[
              {
                title: 'mapId',
                action: setCurrentLocationIdByMapId,
              },
              {
                title: 'mapId',
                action: setCurrentFloorByMapId,
              },
            ]}
          />
          <DeviceGeneralContainer
            showBreadCrumbs={true}
            showSmartFilterButton={true}
          />
        </FetchDeviceRequirementsWrapper>
      </GeneralRequest>
      <DeviceCurrentIdsSetter />
    </>
  )
}

export default SingleDevicePage;