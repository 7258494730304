import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash/fp";
import { AppDispatch } from "../../../app/state";
import { selectGlobalSettings } from "../../../store/settingsStore/settingsSelectors";
import { editGlobalSettings } from "../../../store/settingsStore/settingsRequests";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { IconButton, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";

import classes from './index.module.scss';

const LoginLinks = () => {
  const dispatch = useDispatch<AppDispatch>();
  const settings = useSelector(selectGlobalSettings);
  const { loginLink, loginLinkTitle } = settings;

  const validationSchema = yup.object({
    loginLinkTitle: yup.string().max(255, 'Length exceeded'),
    loginLink: yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    ).max(255, 'Length exceeded'),
  });

  const initialValues = {
    loginLink: loginLink || '',
    loginLinkTitle: loginLinkTitle || '',
  }

  const loginLinksFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSave = async () => {
    const errors = await loginLinksFormik.validateForm();
    if (isEmpty(errors)) {
      dispatch(editGlobalSettings(loginLinksFormik.values));
    }
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Login link settings'>
          <IconButton
            disabled={!loginLinksFormik.dirty}
            aria-label='save changes'
            color='secondary'
            size='small'
            onClick={onSave}
          >
            <SaveIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>

      <HorizontalPanel
        size={HorizontalPanel.SIZES.REST_SPACE}
        className={classes.formPanel}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label='Login link'
              variant='outlined'
              name='loginLink'
              fullWidth
              value={loginLinksFormik.values.loginLink}
              onChange={loginLinksFormik.handleChange}
              helperText={loginLinksFormik.errors.loginLink}
              error={Boolean(loginLinksFormik.errors.loginLink)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Login link title'
              variant='outlined'
              name='loginLinkTitle'
              fullWidth
              value={loginLinksFormik.values.loginLinkTitle}
              onChange={loginLinksFormik.handleChange}
              helperText={loginLinksFormik.errors.loginLinkTitle}
              error={Boolean(loginLinksFormik.errors.loginLinkTitle)}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default LoginLinks;