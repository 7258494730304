import React from "react";
import { useSelector } from "react-redux";
import { Chip, Grid, TextField } from "@material-ui/core";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { selectWirelessSystemById } from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import WirelessInterfaceList from "../../DevicesPage/WirelessInterfaceList";
import CanEditDevice from "../../DevicesPage/CanEditDevice";
import { DevicePosition } from "../../../pages/Old/DevicesMap/DevicePosition";
import useFilter from "../../../hooks/filter.hook";

import classes from './index.module.scss';

const noop = () => {};

const DeviceSummary = () => {
  const currentDevice = useSelector(selectCurrentDevice);
  const { state, actions } = useFilter();
  const { smartFilterDeviceId } = state;
  const deviceName = currentDevice?.name || '';
  const affectedUnit = currentDevice?.configuration?.affectedUnits || '';
  const wirelessSystemId = currentDevice?.configuration?.wirelessSystemId || 1;
  const wirelessSystem = useSelector(selectWirelessSystemById(wirelessSystemId));
  const wirelessSystemName = wirelessSystem?.name || '';
  const status = currentDevice?.configuration?.status || '';
  const description = currentDevice?.configuration?.description || '';
  const wirelessInterfaces = currentDevice?.configuration?.wirelessInterfaces || [];
  const hasNoChannels = wirelessInterfaces.every((wirelessInterface) => (wirelessInterface.channels || []).length === 0)
  const smartFilterButtonIsShown = smartFilterDeviceId !== currentDevice?.id && wirelessInterfaces.length > 0 && !hasNoChannels;

  return (
    <>
      <HorizontalPanel
        size={HorizontalPanel.SIZES.SMALL}
        className={classes.deviceSummaryContainer}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label='Device name'
              variant='outlined'
              name='name'
              fullWidth
              value={deviceName}
              onChange={noop}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Affected unit'
              variant='outlined'
              name='affectedUnits'
              fullWidth
              value={affectedUnit}
              onChange={noop}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Wireless system'
              variant='outlined'
              name='wirelessSystem'
              fullWidth
              value={wirelessSystemName}
              onChange={noop}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Status'
              variant='outlined'
              name='status'
              fullWidth
              value={status}
              onChange={noop}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name='description'
              label='Device description'
              multiline
              maxRows={3}
              onChange={noop}
              value={description}
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className={classes.smartButton}>
          <Grid item xs={6}>
            <Chip
              label="Apply smart filter"
              color="primary"
              disabled={!smartFilterButtonIsShown}
              onClick={() => actions.updateSmartFilterDeviceId(currentDevice?.id || null)}
            />
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Chip
              label="Clear smart filter"
              color="default"
              disabled={!smartFilterDeviceId}
              onClick={() => actions.clearSmartFilter()}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
      <WirelessInterfaceList
        wirelessInterfaceList={wirelessInterfaces}
        showButtons={false}
      />
      <CanEditDevice>
        <DevicePosition />
      </CanEditDevice>
    </>
  );
}

export default DeviceSummary;