import { useMemo } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { selectBuildingsByLocationId } from "../../../store/buildingStore/buildingsSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import { TableList } from "../../../app/components/TableList";
import { selectAllFloors } from "../../../store/floorStore/floorSelectors";
import useNavigator from "../../../hooks/navigator.hook";
import IdModel from "../../../models/HelperModels/IdModel";
import { selectCurrentLocationId } from "../../../store/locationStore/locationSelectors";

import classes from './index.module.scss';

const BuildingList = () => {
  const currentLocationId = useSelector(selectCurrentLocationId);
  const navigator = useNavigator();
  const buildingListWOCondition = useSelector(selectBuildingsByLocationId(currentLocationId));
  const buildingList = currentLocationId && currentLocationId !== -1 ? buildingListWOCondition : [];
  const allFloors = useSelector(selectAllFloors);

  const columns = [
    { flex: 1, field: 'name', headerName: 'Building name' },
    { flex: 1, field: 'address', headerName: 'Address name' },
    { flex: 1, field: 'floorsCount', headerName: 'Active Floors' },
  ];

  const rows = useMemo(
    () => buildingList.map((building) => {
      const currentFloors = allFloors.filter((floor) => floor.buildingId === building.id);

      return {
        name: building.name,
        address: building.address,
        floorsCount: currentFloors.length,
        id: building.id,
      }
    }),
    [ buildingList ]);

  const navigateToBuildingPage = (buildingId: IdModel) => {
    navigator.navigateToBuildingPage({ buildingId })
  }

  const onCreateBuilding = () => {
    navigateToBuildingPage(-1);
  }

  const onSelect = (buildingId: number) => {
    navigateToBuildingPage(buildingId);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='Building list'>
          <CheckPermission permissionName={PERMISSIONS.BUILDING_ACTIONS} noPermissionComponent={null}>
            <IconButton
              disabled={currentLocationId === -1}
              aria-label='add building'
              color='secondary'
              size='small'
              onClick={onCreateBuilding}
            >
              <AddCircleOutline />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} addScroll>
        <TableList
          columns={columns}
          rows={rows}
          selectRow={onSelect}
          classes={{ row: classes.buildingRow }}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default BuildingList;