import React, { useEffect } from "react";
import SearchLeftPanel from "../../containers/SearchPage/SearchLeftPanel";
import { ThreeColumnLayout } from "../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import SearchCentralPanel from "../../containers/SearchPage/SearchCentralPanel";
import { useTypedDispatch } from "../../app/state";
import { clearIdList } from "../../store/deviceStore/deviceState";
import { getAllWirelessSystems } from "../../store/wirelessSystemStore/wirelessSystemRequests";
import { requestRequestersList } from "../../store/userListStore/userListRequests";
import { requestAllCountries } from "../../store/countryStore/countryRequests";
import { requestLocationByQuery } from "../../store/locationStore/locationRequests";
import {
  requestAllTechnologyConfigurations
} from "../../store/technologyConfigurationStore/technologyConfigurationRequests";
import { getAllFloors } from "../../store/floorStore/floorRequests";
import { GeneralRequest } from "../../requests/GeneralRequest";
import { getAllDeviceTags } from "../../store/deviceTagsStore/deviceTagsRequests";

const SearchPage = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(clearIdList());
  }, []);

  return (

    <GeneralRequest requestAction={getAllWirelessSystems} loadingElement={null}>
      <GeneralRequest requestAction={requestRequestersList} loadingElement={null}>
        <GeneralRequest requestAction={requestAllCountries} loadingElement={null}>
          <GeneralRequest requestAction={requestLocationByQuery} loadingElement={null}>
            <GeneralRequest
              requestAction={requestAllTechnologyConfigurations}
              loadingElement={null}
            >
              <GeneralRequest requestAction={getAllFloors}>
                <GeneralRequest requestAction={getAllDeviceTags}>
                  <ThreeColumnLayout
                    leftSideBar={<SearchLeftPanel />}
                    centerMainPanel={<SearchCentralPanel />}
                  />
                </GeneralRequest>
              </GeneralRequest>
            </GeneralRequest>
          </GeneralRequest>
        </GeneralRequest>
      </GeneralRequest>
    </GeneralRequest>
  );
}

export default SearchPage;