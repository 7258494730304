import * as d3 from 'd3';
import React, { useLayoutEffect, useEffect, useState, useRef, useCallback } from "react";
import { getImageSize } from "../../../helpers/imageHelpers";
import ChildrenElement from "../../../models/HelperModels/ChildrenElement";

interface ZoomMapProps {
  onZoomHandlers?: ((zoom: number) => any)[];
  onImageSizeUpdateHandlers?: ((imageSize: [ number, number ]) => any)[]
  mapUrl?: string;
  cursor?: string;
  isZoomEnabled?: boolean;
  dimensions: { width: number; height: number };
  onClick?: ((x: number, y: number) => any)[];
  onContextMenu?: (() => any)[];
  onMouseMove?: (x: number, y: number) => any;
  mapChildren?: ChildrenElement;
  defs?: ChildrenElement;
}

const MIN_ZOOM = 0.01;
const MAX_ZOOM = 30;
export const ITEM_CONTAINER_SELECTOR = 'svg>g';
export const SVG_CONTAINER_SELECTOR = 'svg';

const ZoomMap = (props: ZoomMapProps) => {
  const {
    onZoomHandlers = [],
    onImageSizeUpdateHandlers = [],
    isZoomEnabled = true,
    mapUrl = '',
    cursor = 'default',
    dimensions,
    onClick = [],
    onMouseMove = () => {},
    onContextMenu = [],
    mapChildren,
    defs,
  } = props;
  const mapContainer = useRef(null);
  const [ zoomScale, setZoomScale ] = useState(0);
  const [ xTransform, setXTransform ] = useState(0);
  const [ yTransform, setYTransform ] = useState(0);
  const [ imageSize, setImageSize ] = useState([ 0, 0 ]);
  const imageSizeIsSet = !!imageSize[0] && !!imageSize[1];

  const onZoomUpdate = useCallback((scale) => {
    onZoomHandlers.forEach((fn) => {
      fn && fn(scale);
    })
  }, [ onZoomHandlers ]);

  const onClickHandler = (coordinates) => {
    const x = (coordinates[0] - xTransform) / zoomScale;
    const y = (coordinates[1] - yTransform) / zoomScale;

    if (x < 0 || x > imageSize[0]) {
      return;
    }

    if (y < 0 || y > imageSize[1]) {
      return;
    }

    onClick.forEach((handler) => {
      handler(x, y)
    })
  }

  const onMouseMoveHandler = useCallback((coordinates) => {
    const x = (coordinates[0] - xTransform) / zoomScale;
    const y = (coordinates[1] - yTransform) / zoomScale;

    if (x < 0 || x > imageSize[0]) {
      return;
    }

    if (y < 0 || y > imageSize[1]) {
      return;
    }

    onMouseMove(x, y);
  }, [ zoomScale, xTransform, yTransform, imageSize, cursor ])

  useEffect(() => {
    d3
      .select(mapContainer.current)
      .select('svg')
      .on('click', function (this: any) {
        onClickHandler(d3.mouse(this))
      })
      .on('mousemove', function (this: any) {
        onMouseMoveHandler(d3.mouse(this))
      })
      .on('contextmenu', function () {
        d3.event.preventDefault();
        onContextMenu.forEach((handler) => {
          handler();
        })
      })

    // cursor is needed to detect edit mode
  }, [ zoomScale, xTransform, yTransform, imageSize, cursor ]);

  useEffect(() => {
    d3.select(mapContainer.current)
      .select(SVG_CONTAINER_SELECTOR)
      .call(zoom);
  }, []);

  const applyTransform = useCallback((x: number, y: number, scale: number) => {
    const zoomElement = d3.select(mapContainer.current).select(ITEM_CONTAINER_SELECTOR);

    if (isFinite(scale)) {
      zoomElement.attr('transform', `translate(${x}, ${y}) scale(${scale})`);
    }
  }, []);

  const onZoom = useCallback(() => {
    const scale = d3.event.transform.k;
    setXTransform(d3.event.transform.x);
    setYTransform(d3.event.transform.y);
    setZoomScale(scale);
    onZoomUpdate(scale);
  }, [ zoomScale, onZoomHandlers ]);

  const zoom: any = d3.zoom()
    .scaleExtent([ MIN_ZOOM, MAX_ZOOM ])

  if (isZoomEnabled) {
    zoom.on('zoom', onZoom);
  }

  const getCenter = useCallback((imageSize: [ number, number ], newScale: number): [ number, number ] | [] => {
    const height = dimensions.height;
    const width = dimensions.width;
    const imageWidth = imageSize[0];
    const imageHeight = imageSize[1];
    let x = 0;
    let y = 0;

    if (imageWidth * newScale < width) {
      x = (width - imageWidth * newScale) / 2;

      if (isNaN(x)) {
        return [];
      }
    }

    if (imageHeight * newScale < height) {
      y = (height - imageHeight * newScale) / 2;

      if (isNaN(y)) {
        return [];
      }
    }

    return [ x, y ];
  }, [ dimensions ]);

  const callImageSizeHandlers = (imageSize) => {
    onImageSizeUpdateHandlers.forEach((fn) => {
      fn && fn(imageSize);
    })
  }

  const setMap = async () => {
    const imageDimensions = await getImageSize(mapUrl) || [ 1, 1 ];
    const ratio = [ dimensions.width / imageDimensions[0], dimensions.height / imageDimensions[1] ];

    console.log(dimensions, imageDimensions, ratio);
    setImageSize(imageDimensions);
    callImageSizeHandlers(imageDimensions);

    const min = Math.min(
      Math.max(
        MIN_ZOOM,
        Math.min.apply(Math, ratio)
      ),
      MAX_ZOOM
    );

    const [ x, y ] = getCenter(imageDimensions, min);

    const zoomElement = d3.select(mapContainer.current).select('.zoom');

    zoomElement.call(zoom.transform, d3.zoomIdentity.translate(x || 0, y || 0).scale(min));
    x && setXTransform(x);
    y && setYTransform(y);
    setZoomScale(min);
    onZoomUpdate(min);

    zoom
      .scaleExtent([ min, MAX_ZOOM ]);
  }

  useLayoutEffect(() => {
    if (dimensions.width && dimensions.height) {
      setMap();
    }
  }, [ mapUrl, dimensions ])

  useEffect(() => {
    if (isNaN(xTransform) || isNaN(yTransform) || isNaN(zoomScale)) {
      return;
    }

    applyTransform(xTransform, yTransform, zoomScale);
  }, [ zoomScale, xTransform, yTransform ]);

  return (
    <main
      ref={mapContainer}
      style={{
        cursor: cursor,
        display: 'block'
      }}
    >
      <svg
        height={dimensions.height}
        width={dimensions.width}
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        className="zoom"
      >
        <defs>
          {
            defs
          }
        </defs>
        <g>
          {
            imageSizeIsSet && (
              <image
                width={imageSize[0]}
                height={imageSize[1]}
                xlinkHref={mapUrl}
              />
            )
          }
          {mapChildren}
        </g>
      </svg>
    </main>
  )
}

export default ZoomMap;