import cn from 'classnames';
import { useSelector } from "react-redux";
import { selectDeviceAllLoadingState, selectSearchDeviceList } from "../../../store/deviceStore/deviceSelectors";
import { Grid, IconButton } from "@material-ui/core";
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import SearchTable from "../SearchTable";
import Loading from "../../../components/Loading/Loading";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import useNavigator from "../../../hooks/navigator.hook";

import classes from './index.module.scss';

const SearchCentralPanel = () => {
  const { navigateToBulkEditPage } = useNavigator();
  const deviceList = useSelector(selectSearchDeviceList);
  const searchLoading = useSelector(selectDeviceAllLoadingState);
  const deviceKey = deviceList.map((device) => device?.name || '').join();

  const onBulkEdit = () => {
    const deviceIdList: number[] = [];
    deviceList.forEach((device) => device && deviceIdList.push(device.id));
    navigateToBulkEditPage({ deviceIdList });
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} className={classes.container}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={`Search results (${deviceList.length})`}>
          <IconButton
            disabled={deviceList.length === 0}
            aria-label='bulk edit'
            color='secondary'
            size='small'
            onClick={onBulkEdit}
          >
            <BallotOutlinedIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.container}>
        <Grid container className={cn(classes.tableContainer, {
          [classes.width4K]: deviceList.length > 0,
        })}>
          {
            searchLoading.loading ? <Loading /> : <SearchTable key={deviceKey} />
          }
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default SearchCentralPanel;