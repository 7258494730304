import { useEffect } from "react";

const { REACT_APP_SSO_BASE_URL } = process.env;
const SSOLoginPage = () => {
  useEffect(() => {
    if (REACT_APP_SSO_BASE_URL) {
      window.location.href = decodeURIComponent(REACT_APP_SSO_BASE_URL);
    }
  }, [])

  return null;
}

export default SSOLoginPage;