import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import DeviceTemplateModel from "../../models/DeviceTemplateModel";
import { LoadingStatus, status } from "../../app/shared";
import {
  addWirelessInterfaceToTemplate,
  createTemplateFromDevice,
  deleteTemplate,
  requestTemplatesByCountryId,
  upsertTemplate,
} from "./templatesRequest";
import { deleteWirelessInterface, updateWirelessInterface } from "../deviceStore/deviceRequests";

export const templatesAdapter = createEntityAdapter<DeviceTemplateModel>({
  selectId: (device) => device.id,
  sortComparer: (a, b) => (a.name || '').localeCompare(b.name || ''),
});

type TemplatesSlice = {
  selectedTemplateId?: number | null;
  selectedWirelessInterfaceId: number | null;
  loadAllStatus: LoadingStatus;
} & EntityState<DeviceTemplateModel>

const initialState: TemplatesSlice = {
  selectedTemplateId: null,
  selectedWirelessInterfaceId: null,
  loadAllStatus: status.default,
  ...templatesAdapter.getInitialState(),
}

export const deviceTemplateSlice = createSlice({
  name: 'deviceTemplates',
  initialState,
  reducers: {
    setCurrentTemplateId: (state, action) => {
      state.selectedTemplateId = action.payload;
      state.selectedWirelessInterfaceId = null;
    },
    setCurrentTemplateWirelessInterfaceId: (state, action) => {
      state.selectedWirelessInterfaceId = action.payload;
    },
    clearTemplateList: (state) => {
      templatesAdapter.removeAll(state);
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(requestTemplatesByCountryId.fulfilled, (state, action) => {
        templatesAdapter.setAll(state, action.payload.templates);
      })
      .addCase(createTemplateFromDevice.fulfilled, (state, action) => {
        templatesAdapter.addOne(state, action.payload);
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        if (action.payload) {
          templatesAdapter.removeOne(state, action.payload);
        }
      })
      .addCase(upsertTemplate.fulfilled, (state, action) => {
        templatesAdapter.upsertOne(state, action.payload);
      })
      .addCase(addWirelessInterfaceToTemplate.fulfilled, (state, action) => {
        templatesAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateWirelessInterface.fulfilled, (state, action) => {
        if (action.payload.template) {
          templatesAdapter.upsertOne(state, action.payload.template);
        }
      })
      .addCase(deleteWirelessInterface.fulfilled, (state, action) => {
        if (action.payload.template) {
          templatesAdapter.upsertOne(state, action.payload.template);
        }
      })
});

export const { setCurrentTemplateId, setCurrentTemplateWirelessInterfaceId, clearTemplateList } = deviceTemplateSlice.actions;