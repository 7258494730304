import { useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { throttle } from "lodash";
import { selectGlobalSettings } from "../../../store/settingsStore/settingsSelectors";
import AddPropsToChildren from "../../../components/AddPropsToChildren";
import {
  getMapScale,
} from "../../../helpers/mapHelpers";
import { selectCurrentMap } from "../../../store/mapStore/mapSelectors";
import {
  filterDevicesWithMapPoint, filterDevicesWithPath, filterDeviceWithWirelessInterfaces, filterPortableDevices,
  filterStationaryDevices,
  getDeviceRadius
} from "../zoomMapHelpers";
import DeviceModel from "../../../models/DeviceModel";
import ChildrenElement from "../../../models/HelperModels/ChildrenElement";
import PortableDeviceInfluence from "./PortableDeviceInfluence";
import MapModel from "../../../models/MapModel";
import CircleInfluence from "./CircleInfluence";

export type InfluenceAreaData = {
  id: number;
  wirelessInterfaceId: number;
  isSelected: boolean;
  radiusInPx: number;
  path?: string;
  x: number;
  y: number;
}

type InfluenceAreaProps = {
  children: ChildrenElement,
  deviceList: DeviceModel[],
  currentDeviceId?: number | null,
  influenceAreaEnabled?: boolean,
  mapModel?: MapModel,
  mapChildren?: ChildrenElement,
}

function extractRenderData(device: DeviceModel, mapScale, currentDeviceId, interferenceLevel, powerLossCoefficient) {
  const data: InfluenceAreaData[] = [];
  const point = device?.configuration?.mapPoint?.mapPoint;

  (device?.configuration?.wirelessInterfaces || [])
    .forEach((wirelessInterface) => {
      const radiusInM = getDeviceRadius(wirelessInterface, interferenceLevel, powerLossCoefficient);
      const radiusInPx = radiusInM * mapScale;

      data.push({
        id: device.id,
        wirelessInterfaceId: wirelessInterface.id,
        isSelected: device.id === currentDeviceId,
        path: device?.configuration?.mapPath?.path,
        radiusInPx,
        x: point?.x || 0,
        y: point?.y || 0,
      })
    });

  return data;
}

const InfluenceArea = ({
  children,
  deviceList,
  currentDeviceId,
  influenceAreaEnabled = true,
  mapModel,
  mapChildren,
  ...props
}: InfluenceAreaProps) => {
  const [ scale, setScale ] = useState(1);
  const globalSettings = useSelector(selectGlobalSettings);
  const currentMapModel = useSelector(selectCurrentMap);
  const currentMap = mapModel || currentMapModel;
  const mapScale = getMapScale(currentMap);
  const { interferenceLevel = 0, powerLossCoefficient = 1 } = globalSettings;
  const portableDevices = deviceList
    .filter(filterPortableDevices)
    .filter(filterDevicesWithPath)
    .filter(filterDeviceWithWirelessInterfaces)
  const hasPortableDevices = portableDevices.length > 0;

  const deviceListToRender = deviceList
    .filter(filterStationaryDevices)
    .filter(filterDevicesWithMapPoint)
    .filter(filterDeviceWithWirelessInterfaces)

  let stationaryDevicesToRender: InfluenceAreaData[] = [];
  let portableDevicesToRender: InfluenceAreaData[] = [];

  const delayedSetScale = throttle((newScale) => {
    setScale(newScale);
  }, 100);

  const onZoom = (newScale) => {
    delayedSetScale(newScale);
  }

  deviceListToRender.forEach((device: DeviceModel) => {
    stationaryDevicesToRender = [...stationaryDevicesToRender, ...extractRenderData(device, mapScale, currentDeviceId, interferenceLevel, powerLossCoefficient)];
  });

  portableDevices.forEach((device: DeviceModel) => {
    portableDevicesToRender = [...portableDevicesToRender, ...extractRenderData(device, mapScale, currentDeviceId, interferenceLevel, powerLossCoefficient)];
  })

  useEffect(() => {
    console.log(`Map scale is ${mapScale}`);
    console.log(currentMap);

    deviceList.forEach((device) => {
      device.configuration?.wirelessInterfaces?.forEach((wi) => {
        const radiusInM = getDeviceRadius(wi, interferenceLevel, powerLossCoefficient);

        const radiusInPx = radiusInM * mapScale;

        if (device.configuration?.mapPointId) {
          console.log('Device id: ', device.id, 'Technology: ', wi.technologyConfiguration?.name, ' Radius in px: ', radiusInPx);
        }
      });
    });
  }, [currentMap]);

  const portableDevicesReact = useMemo(() => {
    return hasPortableDevices ? (
      portableDevicesToRender.map((influenceArea, index) => {
        return (
          <PortableDeviceInfluence
            key={influenceArea.id}
            influenceArea={influenceArea}
            visible={influenceAreaEnabled}
            index={index}
          />
        )
      })
    ) : null
  }, [hasPortableDevices, influenceAreaEnabled, currentDeviceId, deviceList]);


  const extraProps = {
    onZoomHandlers: [ onZoom ],
    mapChildren: (
      <>
        {mapChildren}
        {portableDevicesReact}
        {
          stationaryDevicesToRender.map((item, index) => {
            return (
              <CircleInfluence
                key={item.wirelessInterfaceId}
                influenceArea={item}
                scale={scale}
                visible={influenceAreaEnabled}
                index={index}
              />
            )
          })
        }
      </>
    )
  }

  return (
    <AddPropsToChildren
      extraProps={extraProps}
      {...props}
    >
      {children}
    </AddPropsToChildren>
  )
}

export default InfluenceArea;
