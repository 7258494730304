export enum PERMISSIONS {
  BUILDING_ACTIONS = 'buildingActions',
  CHANNEL_ACTIONS = 'channelsActions',
  DEVICE_CREATE = 'deviceCreate',
  DEVICE_DELETE = 'deviceDelete',
  DEVICE_WORKFLOW_UPDATE = 'deviceWorkflowUpdate',
  DEVICE_POINT_ACTIONS = 'devicePointActions',
  DEVICE_UPDATE = 'deviceUpdate',
  DEVICE_WIRELESS_SYSTEM_REMOVE = 'deviceWirelessSystemRemove',
  FLOOR_ACTIONS = 'floorActions',
  FLOOR_MAP_ACTIONS = 'floorMapActions',
  FREQUENCY_ANALYSIS_ACCESS = 'frequencyAnalysisAccess',
  LOCATION_ACTIONS = 'locationActions',
  SETTINGS_MENU_ACCESS = 'settingsMenuAccess',
  TECHNOLOGY_CONFIGURATION_ACTIONS = 'technologyConfigurationActions',
  TECHNOLOGY_CONFIGURATION_ACCESS = 'technologyConfigurationAccess',
  TECHNOLOGY_CREATE = 'technologyCreate',
  WIRELESS_SYSTEM_ACTIONS = 'wirelessSystemActions',
  ROLES_ACTIONS = 'rolesActions',
  USER_ACTIONS = 'userActions',
  WORKFLOW_ACTIONS = 'workflowActions',
  REGIONS_AND_COUNTRY_ACTIONS = 'regionsAndCountriesActions',
  IMPORT_ACCESS = 'importAccess',
  GLOBAL_SETTINGS_ACTIONS = 'globalSettingsActions',
  TEMPLATE_ACTIONS = 'templateActions',
  TEMPLATE_DELETE = 'templateDelete',
}