import queryString from 'query-string';
import { PAGES } from "./path";

interface createPathInterface {
  path: PAGES;
  queryObject: { [key: string]: string | number | boolean | null | number[] | string[] };
  useFullPath?: boolean,
}

export const createURLPath = (params: createPathInterface) => {
  const { path, queryObject, useFullPath = false } = params;

  let url = '';

  if (useFullPath) {
    url = `${window.location.protocol}//${window.location.host}`;
  }

  if (path) {
    url += path;
  }

  const query = queryString.stringify(queryObject, { arrayFormat: 'bracket-separator', arrayFormatSeparator: '|', skipNull: true, skipEmptyString: true });

  if (query) {
    url += `?${query}`;
  }

  return url;
};

export const getQueryObject = (query = window.location.search) => {
  return queryString.parse(query, { arrayFormat: 'bracket-separator', arrayFormatSeparator: '|', parseNumbers: true, parseBooleans: true });
};

export const isEmptyId = (id?: number) => {
  return !id || isNaN(id)
}

export default PAGES;
