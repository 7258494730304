import { useEffect } from "react";
import { useSelector } from "react-redux";
import HorizontalPanel from "../../../components/HorizontalPanel";
import TemplatesList from "../../Shared/TemplatesList";
import {
  selectAllTemplates,
  selectCurrentTemplate,
  selectCurrentTemplateId
} from "../../../store/templatesStore/templatesSelectors";
import useNavigator from "../../../hooks/navigator.hook";
import { setCurrentCountryId } from "../../../store/countryStore/countryState";
import { useTypedDispatch } from "../../../app/state";

const TemplatesMenu = () => {
  const dispatch = useTypedDispatch();
  const navigator = useNavigator();
  const currentTemplateId = useSelector(selectCurrentTemplateId);
  const currentTemplate = useSelector(selectCurrentTemplate);
  const allTemplates = useSelector(selectAllTemplates);

  useEffect(() => {
    if (!currentTemplateId) {
      navigator.navigateToTemplatesPage({ templateId: allTemplates[0]?.id || -1 }, true)
    }
  }, [ currentTemplateId ]);


  useEffect(() => {
    if (currentTemplate) {
      dispatch(setCurrentCountryId(currentTemplate.countryId));
    }
  }, [currentTemplate])

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <TemplatesList />
    </HorizontalPanel>
  );
}

export default TemplatesMenu;