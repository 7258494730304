import { useEffect } from "react";
import { useTypedDispatch } from "../../app/state";
import { onKeyDown, onKeyUp } from "../../store/keyboardStore/keyboardState";


const KeyboardManager = () => {
  const dispatch = useTypedDispatch();
  const onKeyDownHandler = (e) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.repeat) {
      return;
    }

    if (e.keyCode) {
      dispatch(onKeyDown(e.keyCode))
    }
  };

  const onKeyUpHandler = (e) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.keyCode) {
      dispatch(onKeyUp(e.keyCode));
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownHandler);
    document.addEventListener('keyup', onKeyUpHandler);

    return () => {
      document.removeEventListener('keydown', onKeyDownHandler);
      document.removeEventListener('keyup', onKeyUpHandler);
    }
  }, []);

  return null;
}

export default KeyboardManager;