import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentDeviceId } from "../../../store/deviceStore/deviceSelectors";
import ModalWithToggle from "../../../components/ModalWithToggle";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton } from "@material-ui/core";
import { selectCurrentLocation } from "../../../store/locationStore/locationSelectors";
import { selectCurrentCountryId } from "../../../store/countryStore/countrySelectors";
import { requestTemplatesByCountryId } from "../../../store/templatesStore/templatesRequest";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import TemplatesMenuList from "../../TemplatesPage/TemplatesMenuList";
import { useTypedDispatch } from "../../../app/state";
import { copyFromTemplate } from "../../../store/deviceStore/deviceRequests";

const CopyFromTemplateButton = ({ formDisabled = false }) => {
  const dispatch = useTypedDispatch();
  const [ selectedTemplateId, setSelected ] = useState(0);
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const currentLocation = useSelector(selectCurrentLocation);
  const currentCountryId = useSelector(selectCurrentCountryId);
  const countryId = currentCountryId || currentLocation?.countryId;
  const canApplyTemplate = !formDisabled && currentDeviceId !== -1;

  const onCopy = () => {
    if (currentDeviceId && selectedTemplateId) {
      dispatch(copyFromTemplate({ deviceId: currentDeviceId, templateId: selectedTemplateId }));
      setSelected(0);
    }
  };

  const onSelect = (templateId: number) => setSelected(templateId);

  return (
    <ModalWithToggle
      title='Copy from template'
      fixedHeight
      modalContent={
        (
          <GeneralRequest
            requestAction={requestTemplatesByCountryId}
            requestArguments={[ countryId ]}
          >
            <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
              <TemplatesMenuList
                onChoose={onSelect}
                selectedTemplateId={selectedTemplateId}
                noBorderRadius
              />
            </HorizontalPanel>
          </GeneralRequest>
        )
      }
      confirmButtonHandler={onCopy}
      confirmButtonText='Use this template'
      isConfirmButtonDisabled={!selectedTemplateId}
    >
      <IconButton
        component="span"
        color="secondary"
        size="small"
        disabled={!canApplyTemplate}
      >
        <FileCopyIcon />
      </IconButton>
    </ModalWithToggle>
  )
}

export default CopyFromTemplateButton;