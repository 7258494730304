import { useSelector } from "react-redux";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import IconButton from "@material-ui/core/IconButton";
import { AddCircleOutline } from "@material-ui/icons";
import TemplatesMenuList from "../../TemplatesPage/TemplatesMenuList";
import { selectCurrentCountryId } from "../../../store/countryStore/countrySelectors";
import useNavigator from "../../../hooks/navigator.hook";

const TemplatesList = ({ countryId, isOpened = true }: { countryId?: number | null, isOpened?: boolean }) => {
  const navigator = useNavigator();
  const currentCountryId = useSelector(selectCurrentCountryId);

  const onTemplateNavigate = (templateId: number | null, shouldRefresh = false) => {
    navigator.navigateToTemplatesPage({
      countryId: currentCountryId || countryId,
      templateId
    }, shouldRefresh);
  }

  return (
    <>
      {/*<HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>*/}
      {/*  <Toolbar title="Controls" />*/}
      {/*</HorizontalPanel>*/}
      {/*<HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>*/}
      {/*  <Grid container className={classes.filterContainer}>*/}
      {/*    <FilterChipContainer*/}
      {/*      positionAbsolute={false}*/}
      {/*      chips={[*/}
      {/*        FilterChipContainer.FILTER_CHIP_NAMES.MANUAL_FILTERS,*/}
      {/*        FilterChipContainer.FILTER_CHIP_NAMES.SMART_FILTERS,*/}
      {/*      ]}*/}
      {/*    />*/}
      {/*  </Grid>*/}
      {/*</HorizontalPanel>*/}
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Template list">
          <CheckPermission permissionName={PERMISSIONS.TEMPLATE_ACTIONS} noPermissionComponent={null}>
            <IconButton
              component="span"
              color="secondary"
              size="small"
              onClick={() => onTemplateNavigate(-1)}
            >
              <AddCircleOutline />
            </IconButton>
          </CheckPermission>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <TemplatesMenuList
          onChoose={onTemplateNavigate}
          isOpened={isOpened}
        />
      </HorizontalPanel>
    </>
  )
}

export default TemplatesList;