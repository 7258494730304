import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../app/state";
import { selectGlobalSettings } from "../../../store/settingsStore/settingsSelectors";
import * as yup from "yup";
import { useFormik } from "formik";
import { isEmpty } from "lodash/fp";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { IconButton, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { editGlobalSettings } from "../../../store/settingsStore/settingsRequests";
import Grid from "@material-ui/core/Grid";

import classes from './index.module.scss';

const SystemMessageSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const settings = useSelector(selectGlobalSettings);
  const { systemMessage } = settings;

  const validationSchema = yup.object({
    systemMessage: yup.string().max(255, 'Length exceeded'),
  });

  const initialValues = {
    systemMessage: systemMessage || '',
  };

  const systemMessageFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSave = async () => {
    const errors = await systemMessageFormik.validateForm();
    if (isEmpty(errors)) {
      dispatch(editGlobalSettings(systemMessageFormik.values));
    }
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title='System message to all users after login'>
          <IconButton
            disabled={!systemMessageFormik.dirty}
            aria-label='save changes'
            color='secondary'
            size='small'
            onClick={onSave}
          >
            <SaveIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel
        size={HorizontalPanel.SIZES.REST_SPACE}
        className={classes.systemMessageContainer}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              maxRows={3}
              multiline
              label='Message'
              variant='outlined'
              name='systemMessage'
              fullWidth
              value={systemMessageFormik.values.systemMessage}
              onChange={systemMessageFormik.handleChange}
              helperText={systemMessageFormik.errors.systemMessage}
              error={Boolean(systemMessageFormik.errors.systemMessage)}
            />
          </Grid>
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default SystemMessageSettings;