import { ThreeColumnLayout } from "../../../layouts/ThreeColumnLayout/ThreeColumnLayout";
import TemplatesMenu from "../TemplatesMenu";
import TemplateCentralPanel from "../TemplateCentralPanel";
import TemplatesRightPanel from "../TemplatesRightPanel";

const TemplatesGeneralContainer = () => {
  return (
    <ThreeColumnLayout
      leftSideBar={<TemplatesMenu />}
      centerMainPanel={<TemplateCentralPanel />}
      rightSideBar={<TemplatesRightPanel />}
    />
  );
}

export default TemplatesGeneralContainer;