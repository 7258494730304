import { DataGrid, DataGridProps, GridCellParams } from "@material-ui/data-grid";
import { createStyles, makeStyles } from "@material-ui/styles";
import StretchedDataGrid from "./StretchedDataGrid";

// todo: review these styles
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxHeight: '50vh',
      minHeight: '100%',
      fontSize: '14px',
      '& .MuiDataGrid-columnSeparator': {
        color: 'transparent',
        width: '0px',
      },
      '& .MuiDataGrid-columnHeader': {
        fontWeight: 500,
        fontSize: '14px',
        margin: 'auto',
      },
      '& .MuiDataGrid-cell': {
        wordWrap: 'break-word',
        padding: '0 12px',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer:first-child': {},
      '& .MuiDataGrid-columnHeaderTitleContainer': {},
      '& .MuiDataGrid-window': {
        overflowX: 'hidden',
      },
      '& .MuiDataGrid-checkboxInput': {
        width: '15px',
        height: '15px',
      },
    },
  })
);

export type StyledDataGridType = {
  autoWidth?: boolean;
} & DataGridProps;

const StyledDataGrid = ({ autoWidth, ...props }: StyledDataGridType) => {
  const styles = useStyles();

  if (autoWidth) {
    return (
      <StretchedDataGrid
        hideFooter={true}
        onCellClick={(params: GridCellParams) => {
          params.hasFocus = false;
        }}
        onRowClick={() => {}}
        onCellBlur={() => {}}
        scrollbarSize={3}
        classes={styles}
        {...props}
      />
    )
  }

  return (
    <DataGrid
      hideFooter={true}
      onCellClick={(params: GridCellParams) => {
        params.hasFocus = false;
      }}
      onRowClick={() => {}}
      onCellBlur={() => {}}
      scrollbarSize={3}
      classes={styles}
      {...props}
    />
  );
}

export default StyledDataGrid;