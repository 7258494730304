import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getQueryObject } from "../../../pages";
import { selectAllCountries } from "../../../store/countryStore/countrySelectors";
import { selectAllLocations } from "../../../store/locationStore/locationSelectors";
import { selectAllBuildings } from "../../../store/buildingStore/buildingsSelectors";
import { selectAllFloors } from "../../../store/floorStore/floorSelectors";
import { getRequestersList } from "../../../store/userListStore/userListSelectors";
import { selectAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import {
  selectAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import SearchQueryParams from "../../../routes/SearchQueryParams";
import {
  filterBuilding,
  filterCountry, filterDeviceStatus, filterDeviceTags,
  filterFloor,
  filterLocation, filterRequesters,
  filterTechnologies, filterWirelessSystems, isTheSameUrl
} from "../../../helpers/urlChecker";
import { convertToHz } from "../../../helpers/converters/frequency";
import { pagePathGenerator } from "../../../routes/pagePathGenerator";
import { useTypedDispatch } from "../../../app/state";
import { doSearch } from "../../../store/deviceStore/deviceRequests";
import { isEmpty } from "lodash/fp";
import SearchForm from "../SearchForm";
import useNavigator from "../../../hooks/navigator.hook";
import { selectAllDeviceTags } from "../../../store/deviceTagsStore/deviceTagsSelector";

const SearchCheckUrl = () => {
  const dispatch = useTypedDispatch();
  const { navigate } = useNavigator();
  const searchObject = getQueryObject();
  const [ props, setProps ] = useState({} as SearchQueryParams)

  const countryList = useSelector(selectAllCountries);
  const locationList = useSelector(selectAllLocations);
  const buildingList = useSelector(selectAllBuildings);
  const floorList = useSelector(selectAllFloors);
  const technologyList = useSelector(selectAllTechnologyConfigurations);
  const wirelessSystemList = useSelector(selectAllWirelessSystems);
  const requesterList = useSelector(getRequestersList) || [];
  const deviceTagList = useSelector(selectAllDeviceTags) || [];

  const checkValues = () => {
    const newProps: SearchQueryParams = {};
    const foundCountry = filterCountry(countryList, [ searchObject.countryId ]);

    if (foundCountry && foundCountry[0]) {
      newProps.countryId = foundCountry[0].id;
    }

    const locationFound = filterLocation(locationList, [ searchObject.locationId ], foundCountry ? foundCountry : []);

    if (locationFound && locationFound[0]) {
      newProps.locationId = locationFound[0].id;
    }

    const foundBuildingList = filterBuilding(buildingList, searchObject.buildings, locationFound ? locationFound : []);

    if (foundBuildingList) {
      newProps.buildings = foundBuildingList.map((building) => building.id);
    }

    const foundFloorList = filterFloor(floorList, searchObject.floors, foundBuildingList ? foundBuildingList : []);

    if (foundFloorList) {
      newProps.floors = foundFloorList.map((floor) => floor.id);
    }

    if (searchObject.frequencyFrom) {
      if (searchObject.frequencyFrom.toString().match(/^\d*\.?\d+ +(nHz|Hz|MHz|GHz|kHz|µHz|THz)$/)) {
        newProps.frequencyFrom = searchObject.frequencyFrom.toString();
      }
    }

    if (searchObject.frequencyTo) {
      if (searchObject.frequencyTo.toString().match(/^\d*\.?\d+ +(nHz|Hz|MHz|GHz|kHz|µHz|THz)$/)) {
        const valueTo = convertToHz(searchObject.frequencyTo.toString());
        if (newProps.frequencyFrom) {
          const valueFrom = convertToHz(newProps.frequencyFrom);

          if (valueFrom < valueTo) {
            newProps.frequencyTo = searchObject.frequencyTo.toString();
          }
        } else {
          newProps.frequencyTo = searchObject.frequencyTo.toString();
        }
      }
    }

    const foundTechnologies = filterTechnologies(technologyList, searchObject.technology, foundCountry)

    if (foundTechnologies) {
      newProps.technology = foundTechnologies.map((tech) => tech.id);
    }

    const foundWirelessSystems = filterWirelessSystems(wirelessSystemList, searchObject.wirelessSystems);

    if (foundWirelessSystems) {
      newProps.wirelessSystems = foundWirelessSystems.map((ws) => ws.id);
    }

    const validStatuses = filterDeviceStatus(searchObject.status);
    if (validStatuses) {
      newProps.status = validStatuses;
    }

    const validRequesters = filterRequesters(requesterList, searchObject.requesters);

    if (validRequesters) {
      newProps.requesters = validRequesters.map((user) => user.id);
    }

    const validTags = filterDeviceTags(deviceTagList, searchObject.tags);

    if (validTags) {
      newProps.tags = validTags.map((tag) => tag.id);
    }

    const searchUrl = pagePathGenerator.SEARCH_PAGE(newProps);

    if (!isTheSameUrl(searchUrl)) {
      navigate(searchUrl, true);
    }

    const prevPropsUrl = pagePathGenerator.SEARCH_PAGE(props);

    if (prevPropsUrl !== searchUrl) {
      setProps(newProps);

      if (!isEmpty(newProps)) {
        dispatch(doSearch(newProps))
      }
    }
  }

  useEffect(() => {
    checkValues();
  }, [ searchObject ]);

  return (
    <SearchForm searchObject={props} />
  )
}

export default SearchCheckUrl;