import React, { useState } from 'react';
import { useSelector } from "react-redux";
import {
  IconButton
} from '@material-ui/core';
import { AddCircleOutline } from "@material-ui/icons";
import MenuList, { MenuListItem } from "../../../../components/MenuList";
import ModalWithToggle from "../../../../components/ModalWithToggle";
import {
  selectTechnologyConfigurationListByCountryId
} from "../../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import { selectCurrentCountryId } from "../../../../store/countryStore/countrySelectors";
import WirelessInterfaceModel from "../../../../models/WirelessInterfaceModel";

interface AddWirelessInterfaceDialogProps {
  onAdd: (technologyConfigurationId: number) => any,
  disabled: boolean,
  wirelessInterfaces: WirelessInterfaceModel[],
}

export const AddWirelessInterfaceDialog = (props: AddWirelessInterfaceDialogProps) => {
  const { onAdd, disabled = false, wirelessInterfaces = [] } = props;
  const [ value, setValue ] = useState(0);

  const currentCountryId = useSelector(selectCurrentCountryId);

  const currentDeviceTechnologiesId = wirelessInterfaces.map((wirelessInterface) => wirelessInterface.technologyConfigurationId);
  const allTechnologyConfigurations = useSelector(selectTechnologyConfigurationListByCountryId(currentCountryId));
  const availableTechnologies = allTechnologyConfigurations.map((technologyConfiguration) =>
    !currentDeviceTechnologiesId.includes(technologyConfiguration.id) && technologyConfiguration).filter(Boolean);

  const isBtnDisabled = availableTechnologies.length === 0;

  const handleOk = () => {
    if (value) {
      onAdd(value);
    }

    setValue(0);
  };

  return (
    <ModalWithToggle
      title="Wireless Interfaces"
      confirmButtonText="Add"
      confirmButtonHandler={handleOk}
      fixedHeight
      modalContent={(
        <MenuList
          selectItem={(value) => setValue(value)}
          items={availableTechnologies as MenuListItem[]}
          size='small'
          selectedItemId={value}
        />
      )}
    >
      <IconButton
        component="span"
        color="secondary"
        size="small"
        disabled={isBtnDisabled || disabled}
      >
        <AddCircleOutline />
      </IconButton>
    </ModalWithToggle>
  );
};
