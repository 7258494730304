import DeviceModel from "../../models/DeviceModel";
import { extractNumber } from "../../helpers/converters/units";
import { convertToDBm } from "../../helpers/converters/power";
import { DeviceTypeEnum } from "../../models/enums/DeviceType.enum";
import { PathPoint } from "../../helpers/svg";
import {
  createPolygonCircle,
  createVectorFromPoints,
  getMultiplePolygonSum,
  getPolygonIntersection,
  ifFiguresAreTheSame,
  getVectorLength,
  createRhombusFromLine,
} from "../../helpers/geometry";

export const filterDevicesWithMapPoint = (device: DeviceModel) => device.configuration.mapPoint && device.configuration.mapPoint.mapPoint;

export const filterStationaryDevices = (device: DeviceModel) => device.configuration.type === DeviceTypeEnum.STATIONARY;

export const filterPortableDevices = (device: DeviceModel) => device.configuration.type !== DeviceTypeEnum.STATIONARY;

export const filterDevicesWithPath = (device: DeviceModel) => device.configuration.mapPath;

export const filterDeviceWithWirelessInterfaces = (device: DeviceModel) => (device.configuration.wirelessInterfaces || []).length > 0

export const getDeviceCenter = (pointList: PathPoint[]) => {
  return pointList.reduce((previousValue, currentValue) => {
    return {
      x: previousValue.x + (currentValue.x / pointList.length),
      y: previousValue.y + (currentValue.y / pointList.length),
    }
  }, { x: 0, y: 0 })
}

export const getDeviceRadius = (wirelessInterface, interferenceLevel, powerLossCoefficient) => {
  const maxChannel = wirelessInterface.channels.reduce((prev, current) => {
    return convertToDBm(prev?.EIRP) > convertToDBm(current?.EIRP) ? prev : current
  }, {});

  return Math.pow(10, (
    (convertToDBm(wirelessInterface.EIRP) - interferenceLevel) -
    (20 * Math.log10(extractNumber(maxChannel?.downlinkCF)) - 28)
  ) / powerLossCoefficient);
}

export function getBoundingBox(pointList: PathPoint[]) {
  return pointList.reduce((previousValue, currentValue) => {
    return {
      minX: currentValue.x < previousValue.minX ? currentValue.x : previousValue.minX,
      minY: currentValue.y < previousValue.minY ? currentValue.y : previousValue.minY,
      maxX: currentValue.x > previousValue.maxX ? currentValue.x : previousValue.maxX,
      maxY: currentValue.y > previousValue.maxY ? currentValue.y : previousValue.maxY,
    }
  }, {
    minX: Infinity,
    minY: Infinity,
    maxX: -Infinity,
    maxY: -Infinity,
  });
}

export function getBiggerFigure(pointList: [ PathPoint, PathPoint, PathPoint, PathPoint ] | [ PathPoint, PathPoint, PathPoint ], distanceInPx: number) {
  const center = getDeviceCenter(pointList);

  return pointList.map((point) => {
    const vector = createVectorFromPoints(center, point);
    const length = getVectorLength(vector);
    const neededLength = length + distanceInPx;
    return {
      x: vector.x / length * neededLength + center.x,
      y: vector.y / length * neededLength + center.y,
    }
  })
}

export function getInfluenceAreaFromIrregularFigure(pointList: PathPoint[], distanceInPx: number) {
  if (isNaN(distanceInPx) || Math.abs(distanceInPx) < 0.00001) {
    return [];
  }

  const result = pointList.map((point, index) => {
    const nextPoint = pointList[index + 1] || pointList[0];

    return getMultiplePolygonSum([
      createPolygonCircle(point, distanceInPx, 90),
      createRhombusFromLine({ point1: point, point2: nextPoint }, distanceInPx),
    ]);
  });

  return getMultiplePolygonSum(result);
}

export function dividePolygon(pointList: PathPoint[], tryNumber = 0) {
  let result: (PathPoint[])[] = [];
  const first = pointList[0];
  if (!first) {
    return [];
  }

  for (let i = 1; i < pointList.length - 1; i = i + 1) {
    const figure = [ first, pointList[i], pointList[i + 1] ];
    // @ts-ignore
    const intersection = getPolygonIntersection(figure, pointList);

    if (ifFiguresAreTheSame(figure, intersection)) {
      // @ts-ignore
      result.push(figure);
    } else {
      const rest = [ ...pointList.slice(i), first ];

      if (rest.length < 3) {
        return result;
      }

      if (tryNumber > pointList.length) {
        return [];
      }

      const recursionResult = dividePolygon(rest, i);

      if (recursionResult.length === 0) {
        // worst case if deep recursion ended with an error
        return dividePolygon([ ...pointList.slice(1), first ]);
      }

      return result.concat(dividePolygon(rest, i));
    }
  }

  return result;
}